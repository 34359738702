.toggle-container {
    width: 28px;
    background-color: #c4c4c4;
    cursor: pointer;
    user-select: none;
    border-radius: 1px;
    padding: 1px;
    height: 1px;
    position: relative;
  }
  
  .dialog-button {
    font-size: 14px;
    line-height: 4px;
    font-weight: bold;
    cursor: pointer;
    background-color:  #c4c4c4;
    color: white;
    padding: 4px 8px;
    border-radius: 1px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    top : -7px;
    min-width: unset;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 22px;
    transition: all 0.3s ease-in;
  }
  
  .disabled {
    background-color: #707070;
    left: -7px;
  }
  