/*
Template Name: Adminox Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Menu

*/
/* Metis Menu css */
.metismenu .arrow {
  float: right;
  line-height: 1.42857;
}
.metismenu .collapse {
  display: none;
}
.metismenu .collapse.in {
  display: block;
}
.metismenu .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}
[dir=rtl] .metismenu .arrow {
  float: left;
}
.topbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.topbar .topbar-left {
  float: left;
  text-align: center;
  height: 70px;
  position: relative;
  width: 240px;
  z-index: 1;
  background-color: #29303a;
}
.navbar-default {
  background-color: #64c5b1;
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  padding: 0 10px;
}
.navbar-default .navbar-left li a.menu-item {
  padding: 0 15px;
  line-height: 68px;
}
.logo {
  color: #313a46 !important;
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 70px;
}
.logo i {
  display: none;
}
.logo span span {
  color: #64c5b1;
}
.user-box a.user-link {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.user-box .user-img {
  position: relative;
  height: 36px;
  width: 36px;
  margin: 0 auto;
}
.navbar-default .right-menu-item {
  height: 36px;
  width: 36px;
  padding: 0;
  font-size: 22px;
  border: 2px solid transparent !important;
  line-height: 33px;
  text-align: center;
  margin: 17px 5px;
  color: #ffffff !important;
  background-color: transparent !important;
}
.navbar-default .right-menu-item .badge {
  position: absolute;
  top: -8px;
  right: -2px;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.side-menu {
  width: 240px;
  z-index: 10;
  background: #343c49;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 70px;
}
.side-menu .waves-effect .waves-ripple {
  background-color: rgba(100, 197, 177, 0.4);
}
.enlarged .side-menu {
  position: absolute;
}
.content-page {
  margin-left: 240px;
  overflow: hidden;
}
.content-page .content {
  padding: 0 5px 10px 5px;
  margin-top: 70px;
}
.button-menu-mobile {
  border: none;
  color: #ffffff;
  display: inline-block;
  height: 70px;
  width: 60px;
  background-color: #64c5b1;
  font-size: 16px;
}
#sidebar-menu > ul > li > a {
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 14px 20px;
  margin: 2px 0;
}
#sidebar-menu > ul > li > a:hover,
#sidebar-menu > ul > li > a:focus,
#sidebar-menu > ul > li > a:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #2f3642;
}
#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}
#sidebar-menu {
  padding-top: 10px;
}
#sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform .15s;
  position: absolute;
  right: 20px;
  display: inline-block;
  font-family: 'Material Design Icons';
  text-rendering: auto;
  line-height: 22px;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
#sidebar-menu .menu-arrow:before {
  content: "\F142";
}
#sidebar-menu .badge,
#sidebar-menu .label {
  margin-top: 3px;
}
#sidebar-menu li.active .menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin: 0 10px 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}
#sidebar-menu ul li a .drop-arrow {
  float: right;
}
#sidebar-menu ul li a .drop-arrow i {
  margin-right: 0;
}
#sidebar-menu > ul > li > a.active {
  color: #ffffff !important;
  background-color: #2f3642;
}
.nav-second-level.nav li a,
.nav-thrid-level.nav li a {
  padding: 8px 20px 8px 10px;
  color: rgba(255, 255, 255, 0.5);
}
.nav-second-level.nav li a:focus,
.nav-thrid-level.nav li a:focus {
  background-color: #343c49;
}
.nav-second-level.nav li a:hover,
.nav-thrid-level.nav li a:hover {
  background-color: #343c49;
  color: #ffffff;
}
.nav-second-level.nav > li > a {
  padding-left: 58px;
}
.nav-second-level.nav li.active > a {
  color: #ffffff;
  background-color: #343c49;
}
.nav-third-level.nav > li > a {
  padding-left: 68px;
}
.nav-third-level.nav li.active > a {
  color: #ffffff;
}
.menu-title {
  padding: 12px 20px !important;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #98a6ad;
  font-family: 'Nunito Sans', sans-serif;
}
.enlarged .slimScrollDiv,
.enlarged .slimscroll-menu {
  overflow: inherit !important;
}
.enlarged .slimScrollBar {
  visibility: hidden;
}
.enlarged #wrapper #sidebar-menu .menu-title,
.enlarged #wrapper #sidebar-menu .menu-arrow,
.enlarged #wrapper #sidebar-menu .label,
.enlarged #wrapper #sidebar-menu .badge {
  display: none !important;
}
.enlarged #wrapper #sidebar-menu .collapse.in {
  display: none !important;
}
.enlarged #wrapper #sidebar-menu .nav.collapse {
  height: inherit !important;
}
.enlarged #wrapper #sidebar-menu ul ul {
  margin-top: -2px;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 9999;
  background-color: #343c49;
}
.enlarged #wrapper .left.side-menu {
  width: 70px;
  z-index: 5;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  min-height: 55px;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:hover {
  color: #ffffff !important;
  background-color: #2f3642;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:active {
  color: #ffffff !important;
  background-color: #2f3642;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a:focus {
  color: #ffffff !important;
  background-color: #2f3642;
}
.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li > a i {
  font-size: 18px;
  margin-right: 20px !important;
}
.enlarged #wrapper .left.side-menu .label {
  position: absolute;
  top: 5px;
  left: 35px;
  text-indent: 0;
  display: block !important;
  padding: .2em .6em .3em !important;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li {
  position: relative;
  white-space: nowrap;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > a {
  position: relative;
  width: 260px;
  color: #ffffff !important;
  background-color: #2f3642;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul a {
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover > ul a:hover {
  color: #ffffff;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li:hover a span {
  display: inline;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li:hover > ul {
  display: block;
  left: 190px;
  margin-top: -36px;
  position: absolute;
  width: 190px;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li > a span.pull-right {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul ul li.active a {
  color: #ffffff;
}
.enlarged #wrapper .left.side-menu #sidebar-menu ul > li > a span {
  display: none;
  padding-left: 10px;
}
.enlarged #wrapper .left.side-menu .user-details {
  display: none;
}
.enlarged #wrapper .content-page {
  margin-left: 70px;
}
.enlarged #wrapper .footer {
  left: 70px;
}
.enlarged #wrapper .topbar .topbar-left {
  width: 70px !important;
}
.enlarged #wrapper .topbar .topbar-left .logo span {
  display: none;
  opacity: 0;
}
.enlarged #wrapper .topbar .topbar-left .logo i {
  display: block;
  line-height: 70px;
  color: #64c5b1 !important;
}
.enlarged #wrapper #sidebar-menu > ul > li:hover > a.open :after {
  display: none;
}
.enlarged #wrapper #sidebar-menu > ul > li:hover > a.active :after {
  display: none;
}
#wrapper.right-bar-enabled .right-bar {
  right: 0;
}
#wrapper.right-bar-enabled .left-layout {
  left: 0;
}
/* Seach */
.app-search {
  position: relative;
}
.app-search a {
  position: absolute;
  top: 7px;
  right: 26px;
  color: rgba(255, 255, 255, 0.7);
}
.app-search a:hover {
  color: #ffffff;
}
.app-search .form-control,
.app-search .form-control:focus {
  border: 1px solid #43b39c;
  font-size: 13px;
  height: 34px;
  color: #ffffff;
  padding: 7px 40px 7px 20px;
  margin: 19px 15px 0 5px;
  background: #43b39c;
  box-shadow: none;
  border-radius: 30px;
  width: 190px;
}
.app-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.app-search input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.app-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.app-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
/* Page titles */
.page-title {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 2px;
  font-weight: 600;
}
.header-title {
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 14px;
  color: #313a46;
}
.page-title-box {
  padding: 15px 20px;
  margin: 0 -20px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e2e2;
}
.page-title-box .page-title {
  float: left;
}
.page-title-box .breadcrumb {
  float: right;
  background-color: transparent !important;
}
/* Body min-height set */
body.enlarged {
  min-height: 1450px;
}
body.enlarged.bg-transparent {
  min-height: 500px;
}
.lang-option .bootstrap-select {
  margin: 18px 10px 0 10px !important;
}
.lang-option .bootstrap-select .btn-default {
  background-color: #ffffff !important;
}
/* Footer */
.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  text-align: left !important;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0;
  left: 240px;
}
.notify-list {
  padding: 0 !important;
}
.notification-list {
  height: 240px;
}
.notification-list .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0 !important;
}
.notification-list .list-group-item:first-of-type {
  border-top: 0;
}
.notification-list em {
  width: 36px;
  color: #ffffff;
  text-align: center;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  margin-top: 2px;
  font-size: 16px;
}
.notification-list .media-left {
  float: left;
  display: inherit;
}
.notification-list .media-body {
  display: inherit;
  width: auto;
  overflow: hidden;
  margin-left: 50px;
}
.notification-list .media-body h5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 20px;
  font-size: 13px;
}
.notification-list .media-body p {
  line-height: 20px;
}
.notifi-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 11px 20px 15px;
  color: #313a46;
}
.nav-menu-left > li > a {
  color: #ffffff !important;
  line-height: 40px;
}
/*-------------------------
    Mega Menu css
-------------------------*/
.megamenu-list li a {
  color: #797979;
  padding: 10px 0;
  display: block;
}
.megamenu-list li a:hover {
  color: #64c5b1;
}
.megamenu-list li a i {
  width: 20px;
  font-size: 16px;
}
.mega-menu.dropdown {
  position: static;
}
.mega-menu .container {
  position: relative;
}
.mega-menu .dropdown-menu-yamm {
  left: auto;
  padding: 0;
  width: 70%;
}
.mega-menu .yamm-content {
  padding: 20px;
}
