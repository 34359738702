.navbar .sidebar-toggle {
    float: left;
    margin-left: -4px;
}

.navbar .sidebar-toggle+.navbar-brand {
    margin-left: 0;
}

.sidebar {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    /*width: 250px;*/
    width:180px;
    /*background: #343957;*/
    background-color: rgb(50, 50, 50);
    /* font-size: 14px; */
    z-index: 100;
    overflow: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
     /*box-shadow: 0 5px 20px rgba(0, 0, 0, 0.50);*/
}

.sidebar.sidebar-static {
    position: absolute;
}

.sidebar i {
    font-size: 1.12em;
    margin-right: 5px;
    vertical-align: middle;
    width: 1.4em;
    display: inline-block;
}

.sidebar i.fa {
    vertical-align: baseline;
}

.sidebar ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

.sidebar .nano-content>ul {
    width: 100%;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.sidebar .nano-content>ul li.label {
    font-size: 14px;
    /* height: 30px; */
    line-height: 34px;
    color: rgba(160, 180, 200, 0.55);
    text-transform: capitalize;
    font-weight: normal;
}
li a i svg {
    opacity: 0.64;
}
.sidebar .nano-content>ul li.content {
    padding: 10px;
    color: #D8D8D8;
}

.sidebar .nano-content>ul li.content .progress {
    margin-top: 0.7em;
}

.sidebar .nano-content>ul li>ul {
    display: none;
    background: rgba(52,57,87,0.2)!important;
}

.sidebar .nano-content>ul li.open>ul {
    display: block;
}

.sidebar .nano-content>ul li>a {
    display: block;
    cursor: pointer;
    color: #ffffff;
    /*padding: 10px;*/
    padding:2px 10px;
    line-height: 30px;
    border-bottom: none;
    /* background: rgba(255, 255, 255, 0.067); */
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/*.sidebar .nano-content>ul li>a:hover {
  background: linear-gradient(to right, #5772FE , #6D85FB);
}*/

.sidebar .nano-content>ul li>a>.sidebar-collapse-icon {
    float: right;
    margin-right: 5px;
    font-size: 10px;
    margin-top: 10px;
    transition: -webkit-transform 0.3s ease;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.sidebar .nano-content>ul li>a>.badge {
    font-weight: normal;
    position: absolute;
    right: 45px;
    top: 15px;
}

.sidebar .nano-content>ul li:hover>a, .sidebar .nano-content>ul li.active>a, .sidebar .nano-content>ul li.open>a {
    color: rgb(181, 181, 181);
    position: relative;
   /* color:#fff;*/
}

/*.sidebar .nano-content>ul li.active>a::before {
    background: #03A9F5;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
}*/

.sidebar .nano-content>ul li.open>a>.sidebar-collapse-icon {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.sidebar .nano-content>ul>li.label, .sidebar .nano-content>ul>li.content, .sidebar .nano-content>ul>li>a {
    padding-left: 20px;
    padding-right: 20px;
}

html.rtl .sidebar .nano-content>ul>li.label, html.rtl .sidebar .nano-content>ul>li.content, html.rtl .sidebar .nano-content>ul>li>a {
    padding-left: 15px;
    padding-right: 15px;
}

/*.sidebar .nano-content>ul>li.active>a, .sidebar .nano-content>ul>li.open>a, .sidebar .nano-content>ul>li>ul {
    background: linear-gradient(to right, #5772FE , #6D85FB);
}*/
.sidebar .nano-content>ul>li.active>a, .sidebar .nano-content>ul>li.open>a, .sidebar .nano-content>ul>li>ul {
    background-color: rgb(73, 73, 73);
}


.sidebar .nano-content>ul>li>ul>li.label, .sidebar .nano-content>ul>li>ul>li.content, .sidebar .nano-content>ul>li>ul>li>a {
    /*padding-left: 50px;*/
    padding-left:20px;
    padding-right: 15px;
}

html.rtl .sidebar .nano-content>ul>li>ul>li.label, html.rtl .sidebar .nano-content>ul>li>ul>li.content, html.rtl .sidebar .nano-content>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 45px;
}

.sidebar .nano-content>ul>li>ul>li.active>a, .sidebar .nano-content>ul>li>ul>li.open>a, .sidebar .nano-content>ul>li>ul>li>ul {
    background: #242634;
}

.sidebar .nano-content>ul>li>ul>li>ul>li.label, .sidebar .nano-content>ul>li>ul>li>ul>li.content, .sidebar .nano-content>ul>li>ul>li>ul>li>a {
    padding-left: 67.5px;
    padding-right: 15px;
}

html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li.label, html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li.content, html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 67.5px;
}

.sidebar .nano-content>ul>li>ul>li>ul>li.active>a, .sidebar .nano-content>ul>li>ul>li>ul>li.open>a, .sidebar .nano-content>ul>li>ul>li>ul>li>ul {
    background: #1c1d28;
}

.sidebar .nano-content>ul>li>ul>li>ul>li>ul>li.label, .sidebar .nano-content>ul>li>ul>li>ul>li>ul>li.content, .sidebar .nano-content>ul>li>ul>li>ul>li>ul>li>a {
    padding-left: 90px;
    padding-right: 15px;
}

html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li>ul>li.label, html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li>ul>li.content, html.rtl .sidebar .nano-content>ul>li>ul>li>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 90px;
}

/* Small Sidebar styles */

body {
    position: relative;
}

.sidebar-hide .sidebar {
    left: -250px;
}

.sidebar {
    -webkit-transition: left 300ms ease-in-out, width 300ms ease-in-out;
    transition: left 300ms ease-in-out, width 300ms ease-in-out;
}

/* Hide to Small bar */

.sidebar-hide .sidebar.sidebar-hide-to-small {
    position: absolute;
    width: 60px;
    left: 0;
    top: 0px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small, .sidebar-hide .sidebar.sidebar-hide-to-small .nano, .sidebar-hide .sidebar.sidebar-hide-to-small .nano-content {
    overflow: visible;
    /*background: #fff;*/
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul {
    width: 60px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul .label, .sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul .content {
    display: none;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul .badge {
    display: none;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>a {
    padding: 0;
    text-align: center;
    padding: 12px 0;
    line-height: 1;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>a>i {
    width: auto;
    font-size: 1.2rem;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>a, .sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>a>.sidebar-collapse-icon {
    font-size: 0;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>a>.badge {
    position: absolute;
    right: 3px;
    top: 3px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li {
    position: relative;
}

/*.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>ul {
    visibility: hidden;
    width: 200px;
    position: absolute;
    top: 0;
    left: 100%;
}*/

/*.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul li:hover>ul {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    -webkit-box-shadow: 0 2px 2px 0 #e7e7e7;
    box-shadow: 0 2px 2px 0 #e7e7e7;
    display: block !important;
    visibility: visible;
}*/

.sidebar-hide .sidebar.sidebar-hide-to-small~.content-wrap {
    margin-left: 60px;
}

.sidebar.sidebar-overlay.sidebar-hide-to-small~.content-wrap {
    margin-left: 60px;
}

/* Show with Push content */

.sidebar.sidebar-push~.content-wrap {
    -webkit-transform: translateX(250px) translateZ(0);
    transform: translateX(250px) translateZ(0);
    transition: -webkit-transform 300ms ease-in-out, margin-left 300ms ease-in-out;
    -webkit-transition: margin-left 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: margin-left 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, margin-left 300ms ease-in-out;
    transition: transform 300ms ease-in-out, margin-left 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-push~.content-wrap {
    -webkit-transform: translateX(0) translateZ(0);
    transform: translateX(0) translateZ(0);
}

/* Show with Shrink content */

.sidebar.sidebar-shrink~.content-wrap {
    /*margin-left: 250px;*/
    margin-left: 180px;
    -webkit-transition: margin-left 300ms ease-in-out;
    transition: margin-left 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-shrink~.content-wrap {
    margin-left: 0;
}

.sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small~.content-wrap {
    margin-left: 60px;
}

.sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small~.header {
    margin-left: 60px;
}

/* Hide on Content Click */

.sidebar.sidebar-overlap-content~.content-wrap:after {
    content: '';
    display: block;
    position: absolute;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;
    -webkit-transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-overlap-content~.content-wrap:after {
    visibility: hidden;
    opacity: 0;
}

/* Nano Scroller */

.sidebar .nano {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sidebar .nano>.nano-content {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.sidebar .nano>.nano-content:focus {
    outline: thin dotted;
}

.sidebar .nano>.nano-content::-webkit-scrollbar {
    display: none;
}

.sidebar .nano.has-scrollbar>.nano-content::-webkit-scrollbar {
    display: block;
}

.sidebar .nano>.nano-pane {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    /*width: 5px;*/
    width:2px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.01;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 5px;
}

/*.sidebar .nano>.nano-pane>.nano-slider {
        background: #ff5a5e;
    position: relative;
}*/
.sidebar .nano>.nano-pane>.nano-slider {
     background:rgb(226, 5, 5);
    position: relative;
}


.sidebar .nano:hover>.nano-pane, .sidebar .nano>.nano-pane.active, .sidebar .nano>.nano-pane.flashed {
    visibility: visible\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
}

/* Light Scheme */

.sidebar.sidebar-light {
    background: #F8F8F8;
}

.sidebar.sidebar-light .nano>.nano-pane {
    background: rgba(0, 0, 0, 0.15);
}

.sidebar.sidebar-light .nano-content>ul>li.content, .sidebar.sidebar-light .nano-content>ul>li>a {
    color: #919191;
}

.sidebar.sidebar-light .nano-content>ul>li:hover>a {
    color: #474747;
}

.sidebar.sidebar-light .nano-content>ul>li.active>a, .sidebar.sidebar-light .nano-content>ul>li.open>a {
    color: #fff;
}

.sidebar.sidebar-light .nano-content>ul>li.label, .sidebar.sidebar-light .nano-content>ul>li.content, .sidebar.sidebar-light .nano-content>ul>li>a {
    padding-left: 15px;
    padding-right: 15px;
}

html.rtl .sidebar.sidebar-light .nano-content>ul>li.label, html.rtl .sidebar.sidebar-light .nano-content>ul>li.content, html.rtl .sidebar.sidebar-light .nano-content>ul>li>a {
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar.sidebar-light .nano-content>ul>li.active>a, .sidebar.sidebar-light .nano-content>ul>li.open>a, .sidebar.sidebar-light .nano-content>ul>li>ul {
    background: #206da9;
}

.sidebar.sidebar-light .nano-content>ul>li>ul>li.label, .sidebar.sidebar-light .nano-content>ul>li>ul>li.content, .sidebar.sidebar-light .nano-content>ul>li>ul>li>a {
    padding-left: 45px;
    padding-right: 15px;
}

html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li.label, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li.content, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 45px;
}

.sidebar.sidebar-light .nano-content>ul>li>ul>li.active>a, .sidebar.sidebar-light .nano-content>ul>li>ul>li.open>a, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul {
    background: #1d6298;
}

.sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.label, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.content, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>a {
    padding-left: 67.5px;
    padding-right: 15px;
}

html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.label, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.content, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 67.5px;
}

.sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.active>a, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li.open>a, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul {
    background: #195786;
}

.sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li.label, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li.content, .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li>a {
    padding-left: 90px;
    padding-right: 15px;
}

html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li.label, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li.content, html.rtl .sidebar.sidebar-light .nano-content>ul>li>ul>li>ul>li>ul>li>a {
    padding-left: 15px;
    padding-right: 90px;
}

/*
 * RTL sidebar bar
 */

html.rtl {
    direction: rtl;
    /* Show with Push content */
    /* Show with Shrink content */
}

html.rtl .sidebar {
    left: auto;
    right: 0;
    -webkit-transition: right 300ms ease, width 300ms ease;
    transition: right 300ms ease, width 300ms ease;
}

html.rtl .sidebar i {
    margin-right: 0;
    margin-left: 5px;
}

html.rtl .sidebar .nano-content>ul li>a>.sidebar-collapse-icon, html.rtl .sidebar .nano-content>ul li>a>.badge {
    float: left;
}

html.rtl .sidebar-hide .sidebar {
    right: -250px;
}

html.rtl .sidebar-hide .sidebar.sidebar-hide-to-small {
    right: 0;
    left: auto;
}

html.rtl .sidebar-hide .sidebar.sidebar-hide-to-small .nano-content>ul>li>ul {
    left: auto;
    right: 100%;
}

html.rtl .sidebar.sidebar-overlay~.content-wrap {
    margin-right: 0;
}

html.rtl .sidebar-hide .sidebar.sidebar-hide-to-small~.content-wrap, html.rtl .sidebar.sidebar-overlay.sidebar-hide-to-small~.content-wrap {
    margin-left: 0;
    margin-right: 60px;
}

html.rtl .sidebar.sidebar-push~.content-wrap {
    margin-left: 0;
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
    transition: -webkit-transform 300ms ease-in-out, margin-right 300ms ease-in-out;
    -webkit-transition: margin-right 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: margin-right 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, margin-right 300ms ease-in-out;
    transition: transform 300ms ease-in-out, margin-right 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

html.rtl .sidebar-hide .sidebar.sidebar-push~.content-wrap {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

html.rtl .sidebar.sidebar-shrink~.content-wrap {
    margin-left: 0;
    margin-right: 250px;
    -webkit-transition: margin-right 300ms ease-in-out;
    transition: margin-right 300ms ease-in-out;
}

html.rtl .sidebar-hide .sidebar.sidebar-shrink~.content-wrap {
    margin-left: 0;
    margin-right: 0;
}

html.rtl .sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small~.content-wrap {
    margin-left: 0;
    margin-right: 60px;
}

/*    Humberger icon
--------------------------*/

.hamburger {
    display: inline-block;
    left: 0px;
    position: relative;
    top: 10px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 37px;
    z-index: 999;
}

.hamburger .line {
    background-color: #252525;
    display: block;
    height: 3px;
    margin: 4px auto;
    width: 22px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger:hover {
    cursor: pointer;
}

/*
.hamburger.is-active {
  left: 65px;
}
*/

.hamburger.is-active .line:nth-child(1), .hamburger.is-active .line:nth-child(3) {
    width: 10px;
    height: 3px;
}

.hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    width: 20px;
    height: 3px;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(3px) rotate(45deg);
    transform: translateY(3px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-3px) rotate(-45deg);
    transform: translateY(-3px) rotate(-45deg);
}
