@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

/*    Font Variable
----------------------*/
/*    Color Variable
-----------------------*/
/*    Solid Color
------------------*/
/*    Brand color
----------------------*/
/*----------------font-------------------*/
.icon-name {
  color: #000;
}
/*  Opensans
--------------- */
.card-body {
  padding: 0;
}
.card {
  background: #ffffff;
  margin: 7.5px 0;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.card-subtitle {
  font-size: 12px;
  margin: 10px 0;
}
.card-title {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}
.card-title h4 {
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
}
.card-title p {
  font-family: "Roboto", sans-serif;
  margin-bottom: 12px;
}
.card-header-right-icon {
  display: inline-block;
  float: right;
}
.card-header-right-icon li {
  float: right;
  padding-left: 14px;
  color: #868e96;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
  display: inline-block;
}
.card-header-right-icon li .ti-close {
  font-size: 12px;
}
.card-option {
  position: relative;
}
.card-option-dropdown {
  display: none;
  left: auto;
  right: 0;
}
.card-option-dropdown li {
  display: block;
  float: left;
  width: 100%;
  padding: 0;
}
.card-option-dropdown li a {
  line-height: 25px;
  font-size: 12px;
}
.card-option-dropdown li a i {
  margin-right: 10px;
}
.doc-link {
  float: right;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.doc-link:focus:after,
.doc-link:hover:after {
  opacity: 1;
  visibility: visible;
}
.doc-link:after {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  content: "Documentation";
  font-size: 12px;
  padding: 5px 10px;
  position: absolute;
  right: -30px;
  top: -30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.badge {
  padding: 5px 10px;
  font-weight: 400;
  border-radius: 4px;
}
.badge-primary {
  background: #5873fe;
  color: #ffffff;
}
.badge-success {
  background: #28a745;
  color: #ffffff;
}
.badge-info {
  background: #03a9f4;
  color: #ffffff;
}
.badge-warning {
  background: #e7b63a;
  color: #ffffff;
}
.badge-danger {
  background: #dc3545;
  color: #ffffff;
}
.badge-pink {
  background: #e6a1f2;
  color: #ffffff;
}
.badge-dark {
  background: #545454;
  color: #ffffff;
}
span.badge {
  color: #ffffff;
  font-size: 14px;
}
.vtabs {
  display: table;
}
.vtabs .tabs-vertical {
  border-bottom: 0 none;
  border-right: 1px solid rgba(120, 130, 140, 0.13);
  display: table-cell;
  vertical-align: top;
  width: 150px;
}
.vtabs .tabs-vertical li .nav-link {
  border: 0 none;
  border-radius: 4px 0 0 4px;
  color: #263238;
  margin-bottom: 10px;
}
.vtabs .tab-content {
  display: table-cell;
  padding: 20px;
  vertical-align: top;
}
.tabs-vertical li .nav-link.active,
.tabs-vertical li .nav-link.active:focus,
.tabs-vertical li .nav-link:hover {
  background: #1976d2 none repeat scroll 0 0;
  border: 0 none;
  color: #ffffff;
}
.customvtab .tabs-vertical li .nav-link.active,
.customvtab .tabs-vertical li .nav-link:focus,
.customvtab .tabs-vertical li .nav-link:hover {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #ffffff none repeat scroll 0 0;
  border-color: currentcolor #1976d2 currentcolor currentcolor;
  border-image: none;
  border-style: none solid none none;
  border-width: 0 2px 0 0;
  color: #1976d2;
  margin-right: -1px;
}
.tabcontent-border {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: currentcolor #ddd #ddd;
  border-image: none;
  border-style: none solid solid;
  border-width: 0 1px 1px;
}
.customtab2 li a.nav-link {
  border: 0 none;
  color: #67757c;
  margin-right: 3px;
}
.customtab2 li a.nav-link.active {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.customtab2 li a.nav-link:hover {
  background: #1976d2 none repeat scroll 0 0;
  color: #ffffff;
}
.modal-dialog {
  margin: 30px auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%) !important;
  width: 70%;
}
.modal-header .close {
  font-size: 14px;
  margin-right: 15px;
  margin-top: 5px;
}
.modal-content {
  border-radius: 3px;
}
.timeline {
  list-style: none;
  padding: 0 0 8px;
  position: relative;
}
.timeline:before {
  top: 7px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #e7e7e7;
  left: 25px;
  margin-right: -1.5px;
}
.timeline-title {
  margin: 5px 0 !important;
  font-size: 14px;
}
.timeline > li {
  margin-bottom: 15px;
  position: relative;
}
.timeline > li:after,
.timeline > li:before {
  content: " ";
  display: table;
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-panel {
  width: calc(100% - 70px);
  float: right;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  padding: 5px 20px;
  position: relative;
}
.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  left: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-right: 15px solid #e7e7e7;
  border-left: 0 solid #e7e7e7;
  border-bottom: 15px solid transparent;
  content: " ";
}
.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  left: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-right: 14px solid #ffffff;
  border-left: 0 solid #ffffff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline > li > .timeline-badge {
  color: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 25px;
  left: 8px;
  margin-right: -25px;
  background-color: #e6a1f2;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline-body > p {
  font-size: 12px;
  margin-bottom: 10px;
}
.timeline-badge.primary {
  background-color: #5873fe !important;
}
.timeline-badge.success {
  background-color: #28a745 !important;
}
.timeline-badge.warning {
  background-color: #e7b63a !important;
}
.timeline-badge.danger {
  background-color: #dc3545 !important;
}
.timeline-badge.info {
  background-color: #03a9f4 !important;
}
.bootstrap-data-table-panel .dataTables_filter {
  text-align: right;
}
.bootstrap-data-table-panel .dataTables_filter .form-control {
  margin-left: 10px;
}
.bootstrap-data-table-panel .dataTables_filter .form-control:hover,
.bootstrap-data-table-panel .dataTables_filter .form-control:focus {
  background: transparent;
}
.dataTables_length {
  display: inline-block;
  float: left;
  margin-right: 30px;
}
.dt-buttons {
  margin-bottom: 15px;
}
.dt-buttons .dt-button {
  background-image: none;
  background: #ffffff;
  color: #373757;
  border-color: #e7e7e7;
  margin-right: 10px;
  padding: 7px 20px;
  border-radius: 0px;
}
.dt-buttons .dt-button:hover:not(.disabled),
.dt-buttons .dt-button:focus,
.dt-buttons .dt-button.active {
  background-image: none;
  background: #5873fe;
  color: #ffffff;
}
button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
  background-color: #5873fe;
  background-image: none;
  border: 1px solid #5873fe;
  box-shadow: none;
  color: #ffffff;
}
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled) {
  background-color: #5873fe;
  background-image: none;
  border: 1px solid #5873fe;
  box-shadow: none;
  color: #ffffff;
}
button.dt-button:active:hover:not(.disabled):not(.disabled),
button.dt-button.active:hover:not(.disabled):not(.disabled),
div.dt-button:active:hover:not(.disabled):not(.disabled),
div.dt-button.active:hover:not(.disabled):not(.disabled),
a.dt-button:active:hover:not(.disabled):not(.disabled),
a.dt-button.active:hover:not(.disabled):not(.disabled) {
  background-color: #5873fe;
  background-image: none;
  border: 1px solid #5873fe;
  box-shadow: none;
  color: #ffffff;
}
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button.active:not(.disabled) {
  background-color: #5873fe;
  background-image: none;
  border: 1px solid #5873fe;
  box-shadow: none;
  color: #ffffff;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #ffffff;
}

::-webkit-scrollbar-track {
  background: rgb(50, 50, 50);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(226, 5, 5);
  outline: 1px solid #ffffff;
  height: 180px;
}
@media (max-width: 767px) {
  .dataTables_length {
    margin-left: 10px;
  }
  select.input-sm {
    width: 167px;
  }
  .bootstrap-data-table-panel .dataTables_filter {
    margin-left: 12px;
    text-align: left;
    padding-right: 10px;
  }
  .bootstrap-data-table-panel .dataTables_filter .form-control {
    margin-left: 0px;
  }
}
.panel {
  border-radius: 0;
  margin: 15px 0;
}
.panel-body {
  font-family: "Roboto", sans-serif;
}
.panel-primary {
  border-color: #5873fe;
}
.panel-primary .panel-heading {
  background: #5873fe;
  border-color: #5873fe;
  color: #ffffff;
}
.panel-success {
  border-color: #28a745;
}
.panel-success .panel-heading {
  background: #28a745;
  border-color: #28a745;
  color: #ffffff;
}
.panel-info {
  border-color: #03a9f4;
}
.panel-info .panel-heading {
  background: #03a9f4;
  border-color: #03a9f4;
  color: #ffffff;
}
.panel-danger {
  border-color: #dc3545;
}
.panel-danger .panel-heading {
  background: #dc3545;
  border-color: #dc3545;
  color: #ffffff;
}
.panel-warning {
  border-color: #e7b63a;
}
.panel-warning .panel-heading {
  background: #e7b63a;
  border-color: #e7b63a;
  color: #ffffff;
}
.panel-pink {
  border-color: #e6a1f2;
}
.panel-pink .panel-heading {
  background: #e6a1f2;
  border-color: #e6a1f2;
  color: #ffffff;
}
.panel-dark {
  border-color: #545454;
}
.panel-dark .panel-heading {
  background: #545454;
  border-color: #545454;
  color: #ffffff;
}
.panel-white {
  border-color: #252525;
}
.panel-white .panel-heading {
  background: #ffffff;
  border-color: #252525;
  color: #252525;
}
.btn-default {
  background: #878787;
  border-color: #878787;
  color: #ffffff;
}
.btn-default.active,
.btn-default:focus,
.btn-default:hover {
  background: #6e6e6e;
  border-color: #878787;
  color: #ffffff;
  box-shadow: none;
}
.btn-primary {
  background: #5873fe;
  border-color: #5873fe;
  color: #ffffff;
}
.btn-primary.active,
.btn-primary:focus,
.btn-primary:hover {
  background: #2549fe;
  border-color: #5873fe;
  color: #ffffff;
  box-shadow: none;
}
.btn-success {
  background: #28a745;
  border-color: #28a745;
  color: #ffffff;
}
.btn-success.active,
.btn-success:focus,
.btn-success:hover {
  background: #1e7e34;
  border-color: #28a745;
  color: #ffffff;
  box-shadow: none;
}
.btn-info {
  background: #03a9f4;
  border-color: #03a9f4;
  color: #ffffff;
}
.btn-info.active,
.btn-info:focus,
.btn-info:hover {
  background: #0286c2;
  border-color: #03a9f4;
  color: #ffffff;
  box-shadow: none;
}
.btn-warning {
  background: #e7b63a;
  border-color: #e7b63a;
  color: #ffffff;
}
.btn-warning.active,
.btn-warning:focus,
.btn-warning:hover {
  background: #d49f1a;
  border-color: #e7b63a;
  color: #ffffff;
  box-shadow: none;
}
.btn-danger {
  background: #dc3545;
  border-color: #dc3545;
  color: #ffffff;
}
.btn-danger.active,
.btn-danger:focus,
.btn-danger:hover {
  background: #bd2130;
  border-color: #dc3545;
  color: #ffffff;
  box-shadow: none;
}
.btn-pink {
  background: #e6a1f2;
  border-color: #e6a1f2;
  color: #ffffff;
}
.btn-pink.active,
.btn-pink:focus,
.btn-pink:hover {
  background: #da74ec;
  border-color: #e6a1f2;
  color: #ffffff;
  box-shadow: none;
}
.btn-dark {
  background: #545454;
  border-color: #545454;
  color: #ffffff;
}
.btn-dark.active,
.btn-dark:focus,
.btn-dark:hover {
  background: #3b3b3b;
  border-color: #545454;
  color: #ffffff;
  box-shadow: none;
}
.btn-addon {
  position: relative;
  padding-left: 40px;
}
.btn-addon i {
  background: rgba(0, 0, 0, 0.1);
  left: -1px;
  margin-right: 20px;
  padding: 10px;
  position: absolute;
  top: -1px;
}
.btn-addon.btn-lg {
  padding-left: 60px;
}
.btn-addon.btn-lg i {
  padding: 14px;
}
.btn-addon.btn-md {
  padding-left: 45px;
}
.btn-addon.btn-md i {
  padding: 10px;
}
.btn-addon.btn-sm {
  padding-left: 40px;
}
.btn-addon.btn-sm i {
  padding: 9px;
}
.btn-addon.btn-xs {
  padding-left: 25px;
}
.btn-addon.btn-xs i {
  padding: 5px;
}
.btn-rounded {
  border-radius: 100px;
}
.btn-outline {
  background: transparent;
  color: #373757;
}
.btn-flat {
  border-radius: 0;
}
.dropdown-menu li {
  padding: 0 10px;
  font-size: 14px;
}
/*    Header
---------------*/
.header {
  z-index: 990;
  margin-left: 180px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  margin-bottom: 20px;
  transition: margin-left 300ms ease-in-out 0s;
}
.logo {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  text-align: center;
  width: 180px;
  transition: width 300ms ease-in-out;
}
.logo span {
  color: rgba(160, 180, 200, 0.85);
  font-size: 20px;
  font-weight: 700;
}
.sidebar-hide .logo {
  width: 0;
}
.sidebar-hide .logo span {
  display: none;
}
.header-icon {
  font-size: 18px;
  padding: 12px 15px;
  color: #252525;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  float: left;
}
.header-icon i,
.header-icon img,
.header-icon span {
  cursor: pointer;
}
.header-icon.active,
.header-icon:focus,
.header-icon:hover {
  background: rgba(255, 255, 255, 0.3);
}
.header-icon.active .drop-down {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
.drop-down {
  background: #ffffff;
  color: #252525;
  visibility: hidden;
  opacity: 0;
  width: 320px;
  position: absolute;
  right: 0;
  top: 55px;
  transform: translateY(50px);
  transition: all 0.4s ease-in-out;
  border-top: 0;
  border: 1px solid #e7e7e7;
  z-index: 999;
}
.header-left .drop-down {
  left: 0;
}
.dropdown-content-heading {
  padding: 10px 15px;
}
.dropdown-content-heading span {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  color: #373757;
}
.dropdown-content-heading i {
  position: relative;
  top: 5px;
  opacity: 1 !important;
  color: #5873fe;
}
.dropdown-content-body li {
  padding: 15px;
  border-top: 1px solid #eef5f9;
}
.dropdown-content-body li.active,
.dropdown-content-body li:focus,
.dropdown-content-body li:hover {
  background: #eef5f9;
  border-top: 1px solid #eef5f9;
}
.dropdown-content-body li a.active,
.dropdown-content-body li a:focus,
.dropdown-content-body li a:hover {
  color: #868e96;
}
.dropdown-content-body li:last-child {
  padding: 5px 15px;
}
.notification-heading {
  font-size: 13px;
  font-weight: 700;
  color: #373757;
}
.avatar-img {
  border-radius: 100px;
  width: 25px;
  position: relative;
  top: -3px;
}
.user-avatar {
  font-size: 14px;
  font-weight: 700;
}
.notification-text {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
}
.notification-timestamp {
  font-size: 11px;
}
.notification-percentage {
  font-size: 12px;
  position: relative;
  top: 12px;
}
.notification-unread {
  background: #eef5f9;
}
.notification-unread .notification-heading {
  color: #555;
}
.more-link {
  font-size: 12px;
  color: #5873fe;
  display: inline-block;
}
.dropdown-profile {
  width: 200px;
}
.dropdown-profile .trial-day {
  font-size: 11px;
  padding-top: 2px;
  color: #5873fe;
}
.dropdown-profile li {
  padding: 7px 15px;
}
.dropdown-profile li a {
  display: block;
  color: #373757;
}
.dropdown-profile li a.active,
.dropdown-profile li a:focus,
.dropdown-profile li a:hover {
  color: #373757;
}
.dropdown-profile li a span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.dropdown-profile li a i {
  margin-right: 5px;
  font-size: 14px;
}
.dropdown-profile li:last-child {
  padding: 7px 15px;
}
.header-link {
  position: relative;
  top: -3px;
  font-size: 14px;
}
/*    Search box
----------------------*/
.main .page-header {
  min-height: 50px;
  margin: 15px 0 5px;
  padding: 0 15px;
  border-bottom: 0;
  border-radius: 3px;
}
.main .page-header h1 {
  font-size: 18px;
  padding: 14px 0;
  margin: 0;
}
.main .page-header h1 span {
  font-size: 14px;
}
.main .page-header .breadcrumb {
  margin: 0;
  padding: 15px;
  background: transparent;
  float: right;
}
.main .page-header .breadcrumb li.active {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 14px;
}
.main .page-header .breadcrumb li a {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  display: block;
}
.dropdown-task .progress {
  height: 5px;
  margin-bottom: 0;
  margin-top: 20px;
  box-shadow: none;
}
.dropdown-task .progress-bar {
  box-shadow: none;
}
/*  Responsive*/
@media (min-width: 320px) and (max-width: 679px) {
  .sidebar {
    top: 50px;
  }
}
.map {
  width: 100%;
  height: 400px;
}
.chat-sidebar {
  background-color: #eef5f9;
  border-left: 1px solid #e7e7e7;
  position: fixed;
  right: -240px;
  bottom: 0;
  top: 55px;
  width: 240px;
  z-index: 2;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.chat-sidebar .user-name {
  font-family: "Roboto", sans-serif;
}
.chat-sidebar .content {
  font-family: "Roboto", sans-serif;
}
.chat-sidebar .textarea {
  font-family: "Roboto", sans-serif;
}
.chat-sidebar .seen {
  font-family: "Roboto", sans-serif;
}
.chat-sidebar.is-active {
  right: 0;
}
.chat-user-search .input-group-addon {
  background: #ffffff;
  border-radius: 0px;
  border: 0px;
}
.chat-user-search .form-control {
  border: 0px;
}
.hidden {
  display: none;
}
/*    Home Chat Widget
---------------------------------*/
.chat-widget .chat_window {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  background-color: #f8f8f8;
  overflow: hidden;
}
.chat-widget .messages {
  position: relative;
  list-style: none;
  padding: 20px 10px 0 10px;
  margin: 0;
  min-height: 350px;
  overflow: scroll;
}
.chat-widget .messages .message {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s linear;
  opacity: 0;
}
.chat-widget .messages .message .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: inline-block;
}
.chat-widget .messages .message .text_wrapper {
  display: inline-block;
  padding: 20px;
  border-radius: 6px;
  width: calc(100% - 100px);
  min-width: 100px;
  position: relative;
}
.chat-widget .messages .message .text_wrapper .text {
  font-size: 18px;
  font-weight: 300;
}
.chat-widget .messages .message .text_wrapper::after {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 13px;
  margin-top: 0px;
}
.chat-widget .messages .message .text_wrapper:before {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.chat-widget .messages .message .text_wrapper::before {
  border-width: 15px;
  margin-top: -2px;
}
.chat-widget .messages .message.left .text_wrapper::after,
.chat-widget .messages .message.left .text_wrapper::before {
  right: 100%;
  border-right-color: #ffe6cb;
}
.chat-widget .messages .message.left .avatar {
  background-color: #f5886e;
  float: left;
}
.chat-widget .messages .message.left .text_wrapper {
  background-color: #ffe6cb;
  margin-left: 20px;
}
.chat-widget .messages .message.left .text {
  color: #c48843;
}
.chat-widget .messages .message.right .text_wrapper::after,
.chat-widget .messages .message.right .text_wrapper::before {
  left: 100%;
  border-left-color: #c7eafc;
}
.chat-widget .messages .message.right .avatar {
  background-color: #fdbf68;
  float: right;
}
.chat-widget .messages .message.right .text_wrapper {
  background-color: #c7eafc;
  margin-right: 20px;
  float: right;
}
.chat-widget .messages .message.right .text {
  color: #45829b;
}
.chat-widget .messages .message.appeared {
  opacity: 1;
}
.chat-widget .bottom_wrapper {
  position: relative;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  padding: 20px 20px;
  bottom: 0;
}
.chat-widget .bottom_wrapper .message_input_wrapper {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #bcbdc0;
  width: calc(100% - 160px);
  position: relative;
  padding: 0 20px;
}
.chat-widget .bottom_wrapper .message_input_wrapper .message_input {
  border: none;
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 45px);
  position: absolute;
  outline-width: 0;
  color: gray;
}
.chat-widget .bottom_wrapper .send_message {
  width: 140px;
  height: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #a3d063;
  border: 2px solid #a3d063;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s linear;
  text-align: center;
  float: right;
}
.chat-widget .bottom_wrapper .send_message .text {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
  line-height: 48px;
}
.chat-widget .bottom_wrapper .send_message:hover {
  color: #a3d063;
  background-color: #ffffff;
}
.chat-widget .message_template {
  display: none;
}
.testimonial-widget-one .testimonial-content {
  text-align: right;
}
.testimonial-widget-one .testimonial-text {
  margin-bottom: 15px;
}
.testimonial-widget-one .testimonial-author-position {
  font-family: "Roboto", sans-serif;
  margin-right: 75px;
  position: relative;
  top: -5px;
  font-size: 12px;
}
.testimonial-widget-one .testimonial-author {
  padding-top: 15px;
  margin-right: 75px;
  position: relative;
  top: -5px;
  font-weight: 600;
  color: #373757;
}
.testimonial-widget-one .testimonial-author-img {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.weather-one i {
  font-size: 70px;
  position: relative;
  top: 5px;
  color: #ddd;
}
.weather-one h2 {
  display: inline-block;
  float: right;
  font-size: 48px;
  color: #ffffff;
}
.weather-one .city {
  position: relative;
  text-align: right;
  top: -25px;
}
.weather-one .currently {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  top: 25px;
}
.weather-one .celcious {
  text-align: right;
  font-size: 20px;
}
[contenteditable]:hover,
[contenteditable]:focus {
  background: #a4b3fe;
}
.control-bar {
  position: relative;
  z-index: 100;
  background: #5873fe;
  color: #ffffff;
  padding: 15px;
  margin-bottom: 30px;
}
.control-bar .slogan {
  font-weight: bold;
  font-size: 1.2rem;
  display: inline-block;
  margin-right: 2rem;
}
.control-bar label {
  margin: 0px;
  color: #ffffff;
}
.control-bar a {
  margin: 0;
  padding: 0.5em 1em;
  background: #ffffff;
  color: #373757;
}
.control-bar a:hover {
  background: #a4b3fe;
}
.control-bar input {
  border: none;
  background: #a4b3fe;
  max-width: 30px;
  text-align: center;
  color: #373757;
}
.control-bar input:hover {
  background: #a4b3fe;
}
.hidetax .taxrelated {
  display: none;
}
.showtax .notaxrelated {
  display: none;
}
.hidedate .daterelated {
  display: none;
}
.showdate .notdaterelated {
  display: none;
}
.details input {
  display: inline;
  margin: 0 0 0 0.5rem;
  border: none;
  width: 55px;
  min-width: 0;
  background: transparent;
  text-align: left;
}
.invoice-edit .rate:before,
.invoice-edit .price:before,
.invoice-edit .sum:before,
.invoice-edit .tax:before,
.invoice-edit #total_price:before,
.invoice-edit #total_tax:before {
  content: "€";
}
.invoice-edit .me,
.invoice-edit .info,
.invoice-edit .bank,
.invoice-edit .smallme,
.invoice-edit .client,
.invoice-edit .bill,
.invoice-edit .details {
  padding: 15px;
}
.invoice-logo img {
  display: block;
  vertical-align: top;
  width: 50px;
}
/**
 * INVOICELIST BODY
 */
.invoicelist-body {
  margin: 1rem;
}
.invoicelist-body table {
  width: 100%;
}
.invoicelist-body thead {
  text-align: left;
  border-bottom: 2pt solid #666;
}
.invoicelist-body td,
.invoicelist-body th {
  position: relative;
  padding: 1rem;
}
.invoicelist-body tr:nth-child(even) {
  background: #eef5f9;
}
.invoicelist-body tr:hover .removeRow {
  display: block;
}
.invoicelist-body input {
  display: inline;
  margin: 0;
  border: none;
  width: 80%;
  min-width: 0;
  background: transparent;
  text-align: left;
}
.invoicelist-body .control {
  display: inline-block;
  color: white;
  background: #5873fe;
  padding: 3px 7px;
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
}
.invoicelist-body .control:hover {
  background: #7188fe;
}
.invoicelist-body .newRow {
  margin: 0.5rem 0;
  float: left;
}
.invoicelist-body .removeRow {
  display: none;
  position: absolute;
  top: 0.1rem;
  bottom: 0.1rem;
  left: -1.3rem;
  font-size: 0.7rem;
  border-radius: 3px 0 0 3px;
  padding: 0.5rem;
}
/**
 * INVOICE LIST FOOTER
 */
.invoicelist-footer {
  margin: 1rem;
}
.invoicelist-footer table {
  float: right;
  width: 25%;
}
.invoicelist-footer table td {
  padding: 1rem 2rem 0 1rem;
  text-align: right;
}
.invoicelist-footer table tr:nth-child(2) td {
  padding-top: 0;
}
.invoicelist-footer table #total_price {
  font-size: 2rem;
  color: #5873fe;
}
/**
 * NOTE
 */
.note {
  margin: 75px 15px;
}
.hidenote .note {
  display: none;
}
.note h2 {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}
.note p {
  font-size: 12px;
  padding: 0px 5px;
}
/**
 * FOOTER
 */
footer {
  display: block;
  margin: 1rem 0;
  padding: 1rem 0 0;
}
footer p {
  font-size: 12px;
}
/**
 * PRINT STYLE
 */
@media print {
  .header,
  .sidebar,
  .chat-sidebar,
  .control,
  .control-bar {
    display: none !important;
  }
  [contenteditable]:hover,
  [contenteditable]:focus {
    outline: none;
  }
}
#invoice {
  position: relative;
  /*  top: -290px;*/
  margin-bottom: 120px;
  /*  width: 700px;*/
  background: #ffffff;
  padding: 30px;
}
#invoice-table {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #e7e7e7;
  padding: 30px 0px;
}
#invoice-top {
  min-height: 120px;
}
#invoice-mid {
  min-height: 120px;
}
#invoice-bot {
  min-height: 250px;
}
.invoice-logo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/logo1.png) no-repeat;
  background-size: 60px 60px;
}
.clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
.invoice-info {
  display: block;
  float: left;
  margin-left: 20px;
}
.invoice-info h2 {
  color: #373757;
  font-size: 14px;
}
.invoice-info p {
  font-size: 12px;
}
.title {
  float: right;
}
.title h4 {
  color: #373757;
  text-align: right;
}
.title p {
  text-align: right;
  font-size: 12px;
}
#project {
  margin-left: 52%;
}
#project p {
  font-size: 12px;
}
#invoice-table h2 {
  font-size: 18px;
}
.tabletitle {
  padding: 5px;
  background: #e7e7e7;
}
.service {
  border: 1px solid #e7e7e7;
}
.table-item {
  width: 50%;
}
.itemtext {
  font-size: 0.9em;
}
#legalcopy {
  margin-top: 30px;
}
#legalcopy p {
  font-size: 12px;
}
.effect2 {
  position: relative;
}
.effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.legal {
  width: 70%;
}
/* All Invoice Page Responsive
--------------------------- */
@media (max-width: 480px) {
  .control-bar {
    padding: 15px 15px 40px;
  }
}
@media (max-width: 360px) {
  .notaxrelated {
    margin-top: 15px;
  }
}
/*    Widget One
---------------------------*/
.stat-widget-one .stat-icon {
  vertical-align: top;
}
.stat-widget-one .stat-icon i {
  font-size: 30px;
  border-width: 3px;
  border-style: solid;
  border-radius: 100px;
  padding: 15px;
  font-weight: 900;
  display: inline-block;
}
.stat-widget-one .stat-content {
  margin-left: 30px;
  margin-top: 7px;
}
.stat-widget-one .stat-text {
  font-size: 14px;
  color: #868e96;
}
.stat-widget-one .stat-digit {
  font-size: 24px;
  color: #373757;
}
/*    Widget Two
---------------------------*/
.stat-widget-two {
  text-align: center;
}
.stat-widget-two .stat-digit {
  font-size: 1.75rem;
  font-weight: 500;
  color: #373757;
}
.stat-widget-two .stat-digit i {
  font-size: 18px;
  margin-right: 5px;
}
.stat-widget-two .stat-text {
  font-size: 16px;
  margin-bottom: 5px;
  color: #868e96;
}
.stat-widget-two .progress {
  height: 8px;
  margin-bottom: 0;
  margin-top: 20px;
  box-shadow: none;
}
.stat-widget-two .progress-bar {
  box-shadow: none;
}
/*    Widget Three
---------------------------*/
.stat-widget-three .stat-icon {
  display: inline-block;
  padding: 33px;
  position: absolute;
  line-height: 21px;
}
.stat-widget-three .stat-icon i {
  font-size: 30px;
  color: #ffffff;
}
.stat-widget-three .stat-content {
  text-align: center;
  padding: 15px;
  margin-left: 90px;
}
.stat-widget-three .stat-digit {
  font-size: 30px;
}
.stat-widget-three .stat-text {
  padding-top: 4px;
}
.home-widget-three .stat-icon {
  line-height: 19px;
  padding: 27px;
}
.home-widget-three .stat-digit {
  font-size: 24px;
  font-weight: 300;
  color: #373757;
}
.home-widget-three .stat-content {
  text-align: center;
  margin-left: 60px;
  padding: 13px;
}
.stat-widget-four {
  position: relative;
}
.stat-widget-four .stat-icon {
  display: inline-block;
  position: absolute;
  top: 5px;
}
.stat-widget-four i {
  display: block;
  font-size: 36px;
}
.stat-widget-four .stat-content {
  margin-left: 40px;
  text-align: center;
}
.stat-widget-four .stat-heading {
  font-size: 20px;
}
.stat-widget-five .stat-icon {
  border-radius: 100px;
  display: inline-block;
  position: absolute;
}
.stat-widget-five i {
  border-radius: 100px;
  display: block;
  font-size: 36px;
  padding: 30px;
}
.stat-widget-five .stat-content {
  margin-left: 100px;
  padding: 24px 0;
  position: relative;
  text-align: right;
  vertical-align: middle;
}
.stat-widget-five .stat-heading {
  text-align: right;
  padding-left: 80px;
  font-size: 20px;
  font-weight: 200;
}
.stat-widget-six {
  position: relative;
}
.stat-widget-six .stat-icon {
  display: inline-block;
  position: absolute;
  top: 5px;
}
.stat-widget-six i {
  display: block;
  font-size: 36px;
}
.stat-widget-six .stat-content {
  margin-left: 40px;
  text-align: center;
}
.stat-widget-six .stat-heading {
  font-size: 16px;
  font-weight: 300;
}
.stat-widget-six .stat-text {
  font-size: 12px;
  padding-top: 4px;
}
.stat-widget-seven .stat-heading {
  text-align: center;
}
.stat-widget-seven .gradient-circle {
  text-align: center;
  position: relative;
  margin: 30px auto;
  display: inline-block;
  width: 100%;
}
.stat-widget-seven .gradient-circle i {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 35px;
  font-size: 30px;
}
.stat-widget-seven .stat-footer {
  text-align: center;
  margin-top: 30px;
}
.stat-widget-seven .stat-footer .stat-count {
  padding-left: 5px;
}
.stat-widget-seven .count-header {
  color: #252525;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
}
.stat-widget-seven .stat-count {
  font-size: 18px;
  font-weight: 400;
  color: #252525;
}
.stat-widget-seven .analytic-arrow {
  position: relative;
}
.stat-widget-seven .analytic-arrow i {
  font-size: 12px;
}
/* Stat widget Eight
--------------------------- */
.stat-widget-eight {
  padding: 15px;
}
.stat-widget-eight .header-title {
  font-size: 20px;
  font-weight: 300;
}
.stat-widget-eight .ti-more-alt {
  color: #878787;
  cursor: pointer;
  left: -5px;
  position: absolute;
  transform: rotate(90deg);
}
.stat-widget-eight .stat-content {
  margin-top: 50px;
}
.stat-widget-eight .stat-content .ti-arrow-up {
  font-size: 30px;
  color: #28a745;
}
.stat-widget-eight .stat-content .stat-digit {
  font-size: 24px;
  font-weight: 300;
  margin-left: 15px;
}
.stat-widget-eight .stat-content .progress-stats {
  color: #aaadb2;
  font-weight: 400;
  position: relative;
  top: 10px;
}
.stat-widget-eight .progress {
  margin-bottom: 0;
  margin-top: 30px;
  height: 7px;
  background: #eaeaea;
  box-shadow: none;
}
.stat-widget-nine .all-like {
  float: right;
}
.stat-widget-nine .stat-icon i {
  font-size: 22px;
}
.stat-widget-nine .stat-text {
  font-size: 14px;
}
.stat-widget-nine .stat-digit {
  font-size: 14px;
}
.stat-widget-nine .like-count {
  font-size: 30px;
}
.horizontal {
  position: relative;
}
.horizontal:before {
  background: #ffffff;
  bottom: 0;
  content: "";
  height: 38px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 1px;
}
.widget-ten span i {
  color: #ffffff;
  opacity: 0.5;
}
.widget-ten h5 {
  color: #ffffff;
}
.widget-ten p {
  color: #ffffff !important;
  opacity: 0.75;
}
/*
=================================================
    Responsive
=================================================
*/
@media (max-width: 768px) {
  .card {
    display: inline-block;
    width: 100%;
  }
}
@media (max-width: 360px) {
  .stat-widget-five .stat-heading {
    padding-left: 0;
  }
  .stat-widget-two .stat-digit {
    font-size: 16px;
  }
  .stat-widget-two .stat-text {
    font-size: 14px;
  }
  .stat-widget-three .stat-digit {
    font-size: 20px;
  }
  .stat-widget-four .stat-heading {
    font-size: 18px;
  }
  .stat-widget-three .stat-icon {
    padding: 26px;
  }
}
.round-widget {
  border: 1px solid red;
  border-radius: 100px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 60px;
}
.recent-comment .media {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  padding-top: 10px;
}
.recent-comment .media-left {
  padding-right: 25px;
}
.recent-comment .media-left img {
  border-radius: 100px;
  width: 40px;
}
.recent-comment .media-body {
  position: relative;
}
.recent-comment .media-body h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.recent-comment .media-body p {
  margin-bottom: 5px;
  line-height: 16px;
  color: #868e96;
}
.recent-comment .comment-date {
  position: absolute;
  right: 0;
  top: 0;
  color: #373757;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
.comment-action {
  float: left;
}
.comment-action .badge {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}
.comment-action i {
  padding: 0 5px;
}
.recent-meaasge {
  margin-top: 15px;
}
.recent-meaasge .media {
  border-bottom: 1px solid #e7e7e7;
  padding-top: 10px;
  padding-bottom: 10px;
}
.recent-meaasge .media-left {
  padding-right: 25px;
}
.recent-meaasge .media-left img {
  border-radius: 100px;
}
.recent-meaasge .media-body {
  position: relative;
}
.recent-meaasge .media-body h4 {
  font-size: 16px;
}
.recent-meaasge .media-body p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.meaasge-date {
  float: right;
  color: #373757;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
}
/*    Input Style
------------------------*/
.form-group {
  margin-bottom: 20px;
}
.form-control {
  height: 42px;
  border-radius: 0px;
  box-shadow: none;
  border-color: #e7e7e7;
  font-family: "Roboto", sans-serif;
}
.form-control:hover {
  box-shadow: none;
  border-color: #e7e7e7;
}
.form-control:focus,
.form-control.active {
  box-shadow: none;
  border-color: #878787;
}
.input-default {
  border-radius: 4px;
}
.input-flat {
  border-radius: 0px;
}
.input-rounded {
  border-radius: 100px;
}
.input-focus {
  border-color: #5873fe;
}
.input-focus:focus {
  border-color: #5873fe;
}
/*    Search Box Input Button
--------------------------------*/
.input-group-btn .btn {
  padding: 10px 12px;
}
.input-group-default .form-control {
  border-radius: 4px;
}
.input-group-flat .form-control {
  border-radius: 4px;
}
.input-group-flat .btn {
  border-radius: 0px;
}
.input-group-rounded .form-control {
  border-radius: 100px;
}
.input-group-rounded .btn-group-left {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.input-group-rounded .btn-group-right {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.input-group-close-icon {
  background: none;
  color: #252525;
  border-color: #e7e7e7;
}
.input-group-close-icon:hover,
.input-group-close-icon:focus,
.input-group-close-icon.active {
  background: none;
  border-color: #e7e7e7;
  color: #252525;
}
/*    Input States
-----------------------*/
.has-default .form-control:hover,
.has-success .form-control:hover,
.has-warning .form-control:hover,
.has-error .form-control:hover,
.has-default .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus,
.has-default .form-control.active,
.has-success .form-control.active,
.has-warning .form-control.active,
.has-error .form-control.active {
  box-shadow: none;
}
.has-default .control-label {
  color: #878787;
}
.has-default .form-control {
  border-color: #878787;
}
.has-default .form-control:hover,
.has-default .form-control:focus,
.has-default .form-control.active {
  border-color: #878787;
}
.has-success .control-label {
  color: #28a745;
}
.has-success .form-control {
  border-color: #28a745;
}
.has-success .form-control:hover,
.has-success .form-control:focus,
.has-success .form-control.active {
  border-color: #28a745;
}
.has-warning .control-label {
  color: #e7b63a;
}
.has-warning .form-control {
  border-color: #e7b63a;
}
.has-warning .form-control:hover,
.has-warning .form-control:focus,
.has-warning .form-control.active {
  border-color: #e7b63a;
}
.has-error .control-label {
  color: #dc3545;
}
.has-error .form-control {
  border-color: #dc3545;
}
.has-error .form-control:hover,
.has-error .form-control:focus,
.has-error .form-control.active {
  border-color: #dc3545;
}
.has-feedback label ~ .form-control-feedback {
  top: 35px;
}
.form-horizontal .has-feedback .form-control-feedback {
  top: 5px;
}
.has-success .form-control-feedback {
  color: #28a745;
}
.has-warning .form-control-feedback {
  color: #e7b63a;
}
.has-error .form-control-feedback {
  color: #dc3545;
}
.has-success .input-group-addon {
  background-color: #71dd8a;
  border-color: #28a745;
  color: #28a745;
}
.has-warning .input-group-addon {
  background-color: #f5e0ac;
  border-color: #e7b63a;
  color: #e7b63a;
}
.has-error .input-group-addon {
  background-color: #efa2a9;
  border-color: #dc3545;
  color: #dc3545;
}
/*    Input Size
--------------------*/
.input-sm {
  font-size: 12px;
  height: 30px;
  line-height: 1.5;
}
.input-lg {
  font-size: 18px;
  height: 46px;
  line-height: 1.33333;
}
/*    Basic form
----------------------*/
label {
  font-weight: 400;
  margin-bottom: 10px;
}
/*    Form Horizontal
----------------------*/
.form-horizontal .control-label {
  padding-top: 12px;
}
.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}
.is-invalid .form-control {
  border-color: #dc3545;
}
.invalid-feedback {
  color: #ef5350;
  display: none;
  margin-top: 0.25rem;
}
.is-invalid .invalid-feedback,
.is-invalid .invalid-tooltip {
  display: block;
}
.mail-box {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.mail-box aside {
  display: table-cell;
  float: none;
  height: 100%;
  padding: 0;
  vertical-align: top;
}
.mail-box .sm-side {
  background: #ffffff;
  border-radius: 4px 0 0 4px;
  width: 25%;
}
.mail-box .sm-side .user-head {
  background: #ffffff;
  border-radius: 4px 0 0;
  color: #373757;
  min-height: 80px;
  padding: 10px;
}
.mail-box .lg-side {
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 0 4px 4px 0;
  width: 75%;
}
.user-head .inbox-avatar {
  float: left;
  width: 65px;
}
.user-head .inbox-avatar img {
  border-radius: 100px;
  height: 65px;
  width: 65px;
}
.user-head .user-name {
  display: inline-block;
  margin: 0 0 0 10px;
}
.user-head .user-name h5 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 15px;
}
.user-head .user-name h5 a {
  color: #373757;
}
.user-head .user-name span a {
  color: #373757;
  font-size: 12px;
}
a.mail-dropdown {
  background: none repeat scroll 0 0 #80d3d9;
  border-radius: 2px;
  color: #01a7b3;
  font-size: 10px;
  margin-top: 20px;
  padding: 3px 5px;
}
.inbox-body {
  padding: 20px 0px;
}
.inbox-body .modal .modal-body input {
  border: 1px solid #e6e6e6;
  box-shadow: none;
}
.inbox-body .modal .modal-body textarea {
  border: 1px solid #e6e6e6;
  box-shadow: none;
}
.btn-compose {
  background: #5873fe;
  color: #ffffff;
  padding: 12px 0;
  text-align: center;
  width: 70%;
}
.btn-compose:hover,
.btn-compose:focus,
.btn-compose.active {
  background: #5873fe;
  color: #ffffff;
}
ul.inbox-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
}
ul.inbox-nav li {
  display: inline-block;
  line-height: 45px;
  width: 100%;
}
ul.inbox-nav li a {
  color: #6a6a6a;
  display: inline-block;
  line-height: 45px;
  padding: 0 20px;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
ul.inbox-nav li a:hover {
  background: #eef5f9;
  color: #6a6a6a;
}
ul.inbox-nav li a:focus {
  background: #eef5f9;
  color: #6a6a6a;
}
ul.inbox-nav li a i {
  color: #6a6a6a;
  font-size: 16px;
  padding-right: 10px;
}
ul.inbox-nav li a span.label {
  margin-top: 13px;
}
ul.inbox-nav li.active a {
  background: #eef5f9;
  color: #6a6a6a;
}
.inbox-divider {
  border-bottom: 1px solid #e7e7e7;
}
ul.labels-info li {
  margin: 0;
}
ul.labels-info li h4 {
  color: #5c5c5e;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  text-transform: uppercase;
}
ul.labels-info li a {
  border-radius: 0;
  color: #6a6a6a;
  font-family: "Roboto", sans-serif;
}
ul.labels-info li a:hover {
  background: #eef5f9;
  color: #6a6a6a;
}
ul.labels-info li a:focus {
  background: #eef5f9;
  color: #6a6a6a;
}
ul.labels-info li a i {
  padding-right: 10px;
}
.nav.nav-pills.nav-stacked.labels-info p {
  color: #9d9f9e;
  font-size: 11px;
  margin-bottom: 0;
  padding: 0 22px;
}
.inbox-head {
  background: #eef5f9;
  border-radius: 4px 4px 0 0;
  color: #373757;
  min-height: 80px;
  padding: 20px;
}
.inbox-head h3 {
  display: inline-block;
  font-weight: 300;
  margin: 0;
  padding-top: 6px;
}
.inbox-head .sr-input {
  border: medium none;
  border-radius: 4px 0 0 4px;
  box-shadow: none;
  color: #8a8a8a;
  float: left;
  height: 40px;
  padding: 0 10px;
}
.inbox-head .sr-btn {
  background: #ffffff;
  border: medium none;
  border-radius: 0 4px 4px 0;
  color: #373757;
  height: 40px;
  padding: 0 20px;
}
.table-inbox {
  border: 1px solid #e7e7e7;
  margin-bottom: 0;
}
.table-inbox tr td {
  padding: 12px !important;
}
.table-inbox tr td:hover {
  cursor: pointer;
}
.table-inbox tr td .ti-star.inbox-started {
  color: #e7b63a;
}
.table-inbox tr td .ti-star {
  color: #d5d5d5;
}
.table-inbox tr td .ti-star:hover {
  color: #e7b63a;
}
.table-inbox tr.unread td {
  background: #eef5f9;
}
ul.inbox-pagination {
  float: right;
}
ul.inbox-pagination li {
  float: left;
}
.mail-option {
  display: inline-block;
  margin: 26px 0;
  width: 100%;
  font-size: 12px;
}
.mail-option .chk-all {
  margin-right: 5px;
  background: none repeat scroll 0 0 #fcfcfc;
  border: 1px solid #e7e7e7;
  border-radius: 3px !important;
  color: #afafaf;
  display: inline-block;
  padding: 5px 16px;
}
.mail-option .chk-all input[type="checkbox"] {
  margin-top: 0;
}
.mail-option .btn-group {
  margin-right: 5px;
}
.mail-option .btn-group a.btn {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 3px !important;
  font-family: "Roboto", sans-serif;
  color: #373757;
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
}
.mail-option .btn-group a.all {
  border: medium none;
  padding: 0;
  margin-left: 5px;
}
.mail-option .btn-group i {
  margin-left: 5px;
}
.mail-option .card-option-dropdown {
  left: 0;
  right: auto;
}
.inbox-pagination a.np-btn {
  background: none repeat scroll 0 0 #fcfcfc;
  border: 1px solid #e7e7e7;
  border-radius: 3px !important;
  color: #afafaf;
  display: inline-block;
  padding: 5px 15px;
  margin-left: 5px;
}
.inbox-pagination li span {
  display: inline-block;
  margin-right: 5px;
  margin-top: 7px;
}
.fileinput-button {
  background: none repeat scroll 0 0 #eeeeee;
  border: 1px solid #e6e6e6;
  float: left;
  margin-right: 4px;
  overflow: hidden;
  position: relative;
}
.fileinput-button input {
  cursor: pointer;
  direction: ltr;
  font-size: 23px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-300px, 0px) scale(4);
}
.modal-header h4.modal-title {
  font-weight: 300;
}
.modal-body label {
  font-weight: 400;
}
.heading-inbox h4 {
  border-bottom: 1px solid #ddd;
  color: #444444;
  font-size: 18px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.sender-info {
  margin-bottom: 20px;
}
.sender-info img {
  height: 30px;
  width: 30px;
}
.sender-dropdown {
  background: none repeat scroll 0 0 #eaeaea;
  color: #777777;
  font-size: 10px;
  padding: 0 3px;
}
.view-mail a {
  color: #dc3545;
}
.attachment-mail {
  margin-top: 30px;
}
.attachment-mail ul {
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
}
.attachment-mail ul li {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 150px;
}
.attachment-mail ul li img {
  width: 100%;
}
.attachment-mail ul li span {
  float: right;
}
.attachment-mail .file-name {
  float: left;
}
.attachment-mail .links {
  display: inline-block;
  width: 100%;
}
.fileupload-buttonbar .btn {
  margin-bottom: 5px;
}
.fileupload-buttonbar .toggle {
  margin-bottom: 5px;
}
.files .progress {
  width: 200px;
}
.fileupload-processing .fileupload-loading {
  display: block;
}
* html .fileinput-button {
  line-height: 24px;
  margin: 1px -3px 0 0;
}
* + html .fileinput-button {
  margin: 1px 0 0;
  padding: 2px 15px;
}
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.inbox-small-cells {
  text-align: center;
}
.mail-group-checkbox {
  position: relative;
  top: 2px;
}
.mail-checkbox.mail-group-checkbox {
  position: relative;
  top: 2px;
  left: 2px;
}
.table-inbox > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #e7e7e7;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}
@media (max-width: 767px) {
  .files .btn span {
    display: none;
  }
  .files .preview * {
    width: 40px;
  }
  .files .name * {
    display: inline-block;
    width: 80px;
    word-wrap: break-word;
  }
  .files .progress {
    width: 20px;
  }
  .files .delete {
    width: 60px;
  }
}
.progress-bar {
  background-color: #5873fe;
}
.progress-bar-primary {
  background-color: #5873fe;
}
.progress-bar-success {
  background-color: #28a745;
}
.progress-bar-info {
  background-color: #03a9f4;
}
.progress-bar-danger {
  background-color: #dc3545;
}
.progress-bar-warning {
  background-color: #e7b63a;
}
.progress-bar-pink {
  background-color: #e6a1f2;
}
.progress-sm {
  height: 8px;
}
.progress-bar.active,
.progress.active .progress-bar {
  animation: 2s linear 0s normal none infinite running progress-bar-stripes;
}
.progress-vertical {
  display: inline-block;
  height: 250px;
  margin-bottom: 0;
  margin-right: 20px;
  min-height: 250px;
  position: relative;
}
.progress-vertical-bottom {
  display: inline-block;
  height: 250px;
  margin-bottom: 0;
  margin-right: 20px;
  min-height: 250px;
  position: relative;
  transform: rotate(180deg);
}
.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}
@keyframes myanimation {
  0% {
    width: 0;
  }
}
@keyframes myanimation {
  0% {
    width: 0;
  }
}
/* TO DO LIST
================================================== */
/* WebKit browsers */
/* Mozilla Firefox 4 to 18 */
/* Mozilla Firefox 19+ */
/* Internet Explorer 10+ */
.tdl-holder {
  margin: 0 auto;
}
.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tdl-holder li {
  background-color: transparent;
  border-bottom: 1px solid #e7e7e7;
  color: #868e96;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.tdl-holder li span {
  margin-left: 30px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 51px;
  padding: 0 15px;
  position: relative;
  margin: 0 !important;
}
.tdl-holder label:hover {
  background-color: #eef5f9;
  color: #868e96;
}
.tdl-holder label:hover a {
  display: block;
}
.tdl-holder label a {
  border-radius: 50%;
  color: #868e96;
  display: none;
  float: right;
  font-weight: bold;
  line-height: normal;
  height: 16px;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  width: 16px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.tdl-holder input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.tdl-holder input[type="checkbox"] + i {
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  display: block;
  height: 18px;
  position: absolute;
  top: 15px;
  width: 18px;
  z-index: 1;
}
.tdl-holder input[type="checkbox"]:checked + i::after {
  content: "\e64c";
  font-family: "themify";
  display: block;
  left: 0;
  position: absolute;
  top: -17px;
  z-index: 2;
}
.tdl-holder input[type="checkbox"]:checked ~ span {
  color: #868e96;
  text-decoration: line-through;
}
.tdl-holder input[type="text"] {
  background-color: #eef5f9;
  height: 40px;
  margin-top: 20px;
  font-size: 14px;
}
.datamap-sales-hover-tooltip {
  background: #545454;
  font-family: "Roboto", sans-serif;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  border-radius: 3px;
}
thead tr th {
  color: #373757;
  font-weight: 500;
}
thead tr th:last-child {
  text-align: right;
}
tbody tr th {
  color: #373757;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}
tbody tr td {
  font-family: "Roboto", sans-serif;
  color: #868e96;
}
tbody tr td:last-child {
  text-align: right;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 32px;
  vertical-align: top;
}
.table > thead > tr > th {
  border-bottom: 1px solid #e7e7e7;
  font-weight: 600;
  border-top: 0;
}
.table {
  margin-bottom: 0;
}
.table .badge {
  text-transform: uppercase;
}
.student-data-table label {
  margin-right: 7px;
}
.student-data-table td span a {
  padding: 3px;
}
.search-action {
  bottom: 0;
  display: inline-block;
  position: absolute;
  right: 92px;
  text-align: right;
}
.search-type .form-control {
  height: 30px;
}

@media (max-width: 1199px) {
  .search-action {
    text-align: center;
    position: relative;
    right: 0;
  }
  .search-type .form-control {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
.table td,
.table th {
  padding: 0.55rem;
}
.table .round-img img {
  width: 38px;
}
.current-progress {
  margin-top: 15px;
}
.progress-content:last-child {
  margin-bottom: 0;
}
.current-progressbar {
  margin-top: 3px;
}
.current-progressbar .progress {
  height: 15px;
  margin: 0;
  box-shadow: none;
}
.current-progressbar .progress-bar {
  box-shadow: 0;
  line-height: 14px;
  font-size: 11px;
  box-shadow: none;
}
.login-logo {
  text-align: center;
  margin-bottom: 15px;
}
.login-logo span {
  color: #ffffff;
  font-size: 24px;
}
.login-logo img {
  height: 75px;
}
.login-content {
  margin: 100px 0;
}
.login-form {
  background: #ffffff;
  padding: 30px 30px 20px;
  border-radius: 2px;
}
.login-form h4 {
  color: #373757;
  text-align: center;
  margin-bottom: 50px;
}
.login-form .checkbox {
  color: #373757;
}
.login-form .checkbox label {
  text-transform: none;
}
.login-form .btn {
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px;
  border: 0px;
}
.login-form label {
  color: #373757;
  text-transform: uppercase;
}
.login-form label a {
  color: #5873fe;
}
.social-login-content {
  margin: 0px -30px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 30px 0px;
  background: #fcfcfc;
}
.social-button {
  padding: 0 30px;
}
.social-button i {
  padding: 19px;
}
.register-link a {
  color: #5873fe;
}
.cpu-load {
  width: 100%;
  height: 272px;
  font-size: 14px;
  line-height: 1.2em;
}
.cpu-load-data-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}
.cpu-load-data {
  margin-bottom: 30px;
}
.cpu-load-data li {
  display: inline-block;
  width: 32.5%;
  text-align: center;
  border-right: 1px solid #e7e7e7;
}
.cpu-load-data li:last-child {
  border-right: 0px;
}
#barChart {
  height: 400px !important;
}
.nestable-cart {
  overflow: hidden;
}
.dd-handle,
.dd3-content {
  color: #000 !important;
}
.user-work h4,
.user-skill h4 {
  font-size: 14px;
  position: relative;
  margin-bottom: 15px;
  padding: 5px 0px;
  border-bottom: 1px solid #e7e7e7;
  font-family: "Roboto", sans-serif;
}
.work-content {
  margin-bottom: 15px;
}
.work-content h3 {
  font-size: 15px;
  margin-bottom: 5px;
}
.user-skill li a {
  line-height: 25px;
}
.user-profile-name {
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  padding: 0px 15px;
}
.user-Location {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
  font-family: "Roboto", sans-serif;
}
.user-Location i {
  font-size: 14px;
}
.user-job-title {
  font-size: 14px;
  padding-bottom: 5px;
  padding-left: 15px;
  color: #5873fe;
}
.ratings h4 {
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 2px;
  padding-left: 15px;
}
.ratings span {
  margin-right: 10px;
}
.rating-star {
  margin-bottom: 10px;
  padding-left: 15px;
}
.user-send-message {
  margin-top: 15px;
  padding-left: 15px;
}
.user-profile-tab {
  margin-top: 15px;
  padding: 0px 15px;
}
.user-profile-tab li a {
  padding: 7px 40px 7px 0px;
}
.contact-information,
.basic-information {
  margin: 10px 0px;
}
.contact-information h4,
.basic-information h4 {
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 15px;
  font-family: "Roboto", sans-serif;
}
.contact-title {
  display: inline-block;
  padding-bottom: 15px;
  width: 170px;
  font-size: 16px;
  color: #868e96;
}
.phone-number,
.mail-address,
.contact-email,
.contact-website,
.contact-skype,
.birth-date,
.gender {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #373757;
}
.profile-widget {
  margin: 8px 0;
  text-align: center;
}
.profile-widget .stat-text {
  padding-bottom: 6px;
}
.profile-widget-one .profile-one-bg {
  position: relative;
}
.profile-widget-one .profile-one-user-photo {
  position: relative;
}
.profile-widget-one .profile-one-user-photo .bg-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.profile-widget-one .profile-one-user-photo .user-photo {
  bottom: 0;
  height: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.profile-widget-one .profile-one-user-photo .user-photo img {
  border-radius: 100px;
  height: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100px;
}
.profile-widget-one .profile-one-user-content ul li {
  background: #ffffff;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  display: block;
  float: left;
  padding: 10px 0;
  text-align: center;
  width: 32%;
}
.profile-widget-one .profile-one-user-content ul li:last-child {
  border-right: 0px;
}
.profile-widget-one .profile-one-user-content h4 {
  line-height: 30px;
  font-size: 14px;
  margin: 0px;
}
.profile-widget-one .profile-one-user-content .earning-amount,
.profile-widget-one .profile-one-user-content .sold-amount {
  color: #28a745;
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
}
.profile-widget-one .profile-one-user-content .sold-amount {
  color: #5873fe;
  font-size: 24px;
  font-weight: 400;
  margin-top: 10px;
}
.profile-widget-one .profile-one-user-button {
  text-align: center;
  padding: 26px 0px;
}
.profile-widget-one .profile-btn-one {
  font-size: 18px;
  text-transform: uppercase;
  padding: 8px 15px;
  font-weight: 400;
  color: #5873fe;
}
/*Aleart
-------------*/
.alert-primary {
  background-color: #b3c0ff;
  border-color: #b3c0ff;
  color: #5873fe;
}
.alert-success {
  background-color: #71dd8a;
  border-color: #71dd8a;
  color: #28a745;
}
.alert-warning {
  background-color: #f5e0ac;
  border-color: #f5e0ac;
  color: #e7b63a;
}
.alert-danger {
  background-color: #efa2a9;
  border-color: #efa2a9;
  color: #dc3545;
}
.alert-pink {
  background-color: #f8e4fb;
  border-color: #f8e4fb;
  color: #e6a1f2;
}
.alert-dismissable .close,
.alert-dismissible .close {
  color: rgba(0, 0, 0, 0.8);
}
/*    Labels
------------------*/
.label-default {
  background-color: #878787;
}
.label-primary {
  background-color: #5873fe;
}
.label-success {
  background-color: #28a745;
}
.label-info {
  background-color: #03a9f4;
}
.label-danger {
  background-color: #dc3545;
}
.label-warning {
  background-color: #e7b63a;
}
/* Calendar
================================================== */
/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0px;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day {
  background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc-widget-header {
  border: 1px solid #e7e7e7;
}
.fc-widget-content {
  border: 1px solid #e7e7e7;
}
.fc th.fc-widget-header {
  background: #e7e7e7;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}
.fc-button {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  color: #373757;
  text-transform: capitalize;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #eef5f9 !important;
}
.fc-state-highlight {
  background: #eef5f9 !important;
}
.fc-cell-overlay {
  background: #eef5f9 !important;
}
.fc-unthemed .fc-today {
  background: #ffffff !important;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}
#drop-remove {
  margin: 0px;
  top: 3px;
}
#event-modal .modal-dialog,
#add-category .modal-dialog {
  max-width: 600px;
}
.flotTip {
  background: #252525;
  border: 1px solid #252525;
  padding: 5px 15px;
  color: #ffffff;
}
.flot-container {
  box-sizing: border-box;
  width: 100%;
  height: 275px;
  padding: 20px 15px 15px;
  margin: 15px auto 30px;
  background: transparent;
}
.flot-pie-container {
  height: 275px;
}
.flotBar-container {
  height: 275px;
}
.flot-line {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
}
.legend table {
  border-spacing: 5px;
}
#chart1,
#flotBar,
#flotCurve {
  width: 100%;
  height: 275px;
}
.morris-hover {
  position: absolute;
  z-index: 1;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.morris-hover.morris-default-style {
  border-radius: 2px;
  padding: 10px 12px;
  color: #666;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #fff !important;
}
.morris-hover-point {
  color: rgba(255, 255, 255, 0.8) !important;
}
#morris-bar-chart {
  height: 285px;
}
.products_1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.products_1 .pr_img_price {
  position: relative;
}
.products_1 .pr_img_price .product_price {
  min-width: 50px;
  min-height: 50px;
  background: #28a745;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.products_1 .pr_img_price .product_price p {
  padding-top: 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.products_1 .product_details .product_name {
  padding-top: 30px;
}
.products_1 .product_details .prdt_add_to_cart {
  padding-top: 10px;
}
.products_1 .product_details .prdt_add_to_cart button {
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.product-2-details .table > tbody > tr > td {
  border: none;
}
.product-2-details .product-2-des {
  margin-top: 25px;
}
.product-2-details .product-2-des .product_name h4 {
  font-size: 15px;
  font-weight: 600;
}
.product-2-details .product-2-des .product_des p {
  font-size: 13px;
  font-style: italic;
}
.product-2-details .product-2-button {
  border-left: 1px solid #e7e7e7;
  margin-top: 25px;
}
.product-2-details .product-2-button .prdt_add_to_curt {
  padding-top: 10px;
}
.product-2-details .product-2-button .prdt_add_to_curt button {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.product-3-img img {
  width: 100%;
}
.product_details_3 {
  padding: 15px 0px;
}
.product_details_3 .product_name h4 {
  font-size: 15px;
  font-weight: 600;
}
.product_details_3 .product_des {
  padding-bottom: 5px;
}
.product_details_3 .prdt_add_to_curt {
  padding-top: 10px;
}
.product_details_3 .prdt_add_to_curt button {
  text-transform: uppercase;
  font-weight: 600;
}
.favourite-menu-details .table > tbody > tr > td {
  border-top: none;
  border-bottom: 1px solid #e7e7e7;
}
.favourite-menu-details .favourite-menu-img {
  border-right: 1px solid #e7e7e7;
  margin-bottom: 25px;
  width: 120px;
}
.favourite-menu-details .favourite-menu-des {
  margin-top: 40px;
  margin-right: 465px;
}
.favourite-menu-details .favourite-menu-des .product_name h4 {
  font-weight: 600;
  text-align: left;
}
.favourite-menu-details .favourite-menu-button {
  margin-top: 40px;
}
.favourite-menu-details .favourite-menu-button .prdt_add_to_curt {
  padding-top: 10px;
}
.favourite-menu-details .favourite-menu-button .prdt_add_to_curt button {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.order-list-item table tbody > tr > td {
  padding-top: 8px;
  border-top: 1px solid #e7e7e7;
}
.order-list-item table thead > tr > th {
  border-bottom: 1px solid #e7e7e7;
}
.order-list-item thead {
  background: #5873fe;
  text-align: left;
}
.order-list-item thead th {
  color: #ffffff;
  font-weight: bold;
}
.order-list-item tbody {
  background: #ffffff;
  text-align: left;
}
.order-list-item tbody td {
  color: #444444;
}
.booking-system-feedback {
  top: 5px !important;
  right: 15px;
}
.booking-system-top {
  padding-top: 15px;
}
.media-body {
  vertical-align: middle;
}
.media-body span {
  font-size: 10px;
  color: #5873fe;
}
.media-body p {
  color: #868e96;
  line-height: 15px;
}
.example {
  overflow: hidden;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 0.3);
  -moz-box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 0.3);
  box-shadow: 1px 1px 2px 0px rgba(200, 200, 200, 0.3);
  background-color: #eef5f9;
  text-align: justify;
}
.example p {
  padding: 20px 20px 0px 20px;
  font-size: 12px;
}
.box,
.simple {
  height: 300px;
}
.scrollable-auto-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.scrollable-auto-y {
  overflow-y: auto;
  overflow-x: hidden;
}
.scrollable-auto {
  overflow: auto;
}
.vmap {
  width: 100%;
  height: 400px;
}
.dark-browse-input-box {
  border-radius: 0;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 12px;
  color: #000000;
  border: 1px solid #e7e7e7;
}
.dark-browse-input-box .dark-input-button {
  border-radius: 0;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  background: #ffffff;
  border: none !important;
  color: #5873fe;
}
.dark-browse-input-box .dark-input-button i {
  font-weight: bold;
  font-size: 17px;
}
.dark-browse-input-box .dark-input-button:hover {
  background: #ffffff;
  color: #5873fe;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border: none !important;
}
.dark-browse-input-box .dark-input-button:focus {
  outline: none;
  border: none !important;
  background: none !important;
}
.file-input {
  position: relative;
  font-size: 14px;
}
.file-input label {
  position: absolute;
  top: -2px;
  right: 0;
  bottom: 0;
  margin: 0;
}
.file-input label:focus {
  outline: none;
  border: none !important;
  background: none !important;
}
.file-input .btn {
  position: absolute;
  right: 6px;
  top: 7px;
  bottom: 6px;
  max-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 32px;
}
.file-input .btn input {
  width: 0;
  height: 0;
}
.file-input .file-name {
  float: left;
  width: 100%;
  border: 0;
  background: transparent;
}
.media-stats-content .stats-content {
  padding: 30px 0px;
}
.media-stats-content .stats-content .stats-digit {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}
.media-stats-content .stats-content .stats-text {
  font-size: 14px;
}
.media-stats-content .stats-content .table td {
  line-height: 40px !important;
}
.carousel.vertical .carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.carousel.vertical .carousel-inner > .item {
  display: none;
  position: relative;
  transition: top 0.6s ease-in-out;
}
.carousel.vertical .carousel-inner > .item > img,
.carousel.vertical .carousel-inner > .item > a > img {
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel.vertical .carousel-inner > .item {
    transition: transform 0.6s ease-in-out;
    backface-visibility: hidden;
    perspective: 1000;
  }
  .carousel.vertical .carousel-inner > .item.next,
  .carousel.vertical .carousel-inner > .item.active.right {
    transform: translate3d(0, 100%, 0);
    top: 0;
  }
  .carousel.vertical .carousel-inner > .item.prev,
  .carousel.vertical .carousel-inner > .item.active.left {
    transform: translate3d(0, -100%, 0);
    top: 0;
  }
  .carousel.vertical .carousel-inner > .item.next.left,
  .carousel.vertical .carousel-inner > .item.prev.right,
  .carousel.vertical .carousel-inner > .item.active {
    transform: translate3d(0, 0, 0);
    top: 0;
    width: 100%;
  }
}
.carousel.vertical .carousel-inner > .active,
.carousel.vertical .carousel-inner > .next,
.carousel.vertical .carousel-inner > .prev {
  display: block;
}
.carousel.vertical .carousel-inner > .active {
  top: 0;
}
.carousel.vertical .carousel-inner > .next,
.carousel.vertical .carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel.vertical .carousel-inner > .next {
  top: 100%;
}
.carousel.vertical .carousel-inner > .prev {
  top: -100%;
}
.carousel.vertical .carousel-inner > .next.left,
.carousel.vertical .carousel-inner > .prev.right {
  top: 0;
}
.carousel.vertical .carousel-inner > .active.left {
  top: -100%;
}
.carousel.vertical .carousel-inner > .active.right {
  top: 100%;
}
.ct-chart {
  height: 265px;
}
.ct-pie-chart {
  height: 328px;
}
.ct-svg-chart {
  height: 270px;
}
.ct-bar-chart {
  height: 250px;
}
.ct-label {
  color: rgba(0, 0, 0, 0.8);
  fill: rgba(0, 0, 0, 0.8);
  font-size: 10px;
}
.ct-chart-pie .ct-label {
  color: rgba(0, 0, 0, 0.8);
  fill: rgba(0, 0, 0, 0.8);
  font-size: 12px;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #28a745;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #007bff;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #e6a1f2;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #e7b63a;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
  fill: #28a745;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie {
  fill: #007bff;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie {
  fill: #e6a1f2;
}
body {
  font-family: "Roboto", sans-serif;
  /*background: rgba(88, 115, 254, 0.04);*/
  background: #f5f5f5;
  color: #868e96;
  font-size: 14px;
}
a,
button {
  outline: none !important;
  text-decoration: none !important;
  color: #868e96;
  transition: all 0.2s ease 0s;
}
a.active,
button.active,
a:focus,
button:focus,
a:hover,
button:hover {
  color: #252525;
  outline: none !important;
  text-decoration: none !important;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
p {
  font-family: "Roboto", sans-serif;
  color: #868e96;
}
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6.h1 {
  color: #373757;
}
.dib {
  display: inline-block;
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.alert h4 {
  color: #373757;
}
.border-none {
  border: 1px solid transparent;
}
.footer > p {
  margin-top: 15px;
  padding: 15px;
  text-align: left;
}
.footer > p a {
  color: #5873fe;
}
.bar-hidden {
  overflow-x: hidden;
}
.color-white {
  color: #ffffff;
}
.btn-btn {
  padding: 15px 25px;
  border: 0;
}
.btn-btn:hover {
  color: #ffffff;
}
.letter-space {
  letter-spacing: 1px;
}
.solid-btn {
  padding: 15px 42px;
}
.box-shadow {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
/*    Color Mixins
-------------------*/
.color-primary,
.text-primary {
  color: #5873fe;
}
.color-success,
.text-success {
  color: #28a745;
}
.color-info,
.text-info {
  color: #03a9f4;
}
.color-danger,
.text-danger {
  color: #dc3545;
}
.color-warning,
.text-warning {
  color: #e7b63a;
}
.color-pink,
.text-pink {
  color: #e6a1f2;
}
.color-dark,
.text-dark {
  color: #545454;
}
.color-grey,
.text-grey {
  color: #ddd;
}
/*    Mixins
--------------------------*/
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
/*    Background Mixins
--------------------------*/
.bg-primary {
  background: #5873fe;
  color: #ffffff;
  fill: #5873fe;
}
.bg-success {
  background: #28a745;
  color: #ffffff;
  fill: #28a745;
}
.bg-info {
  background: #03a9f4;
  color: #ffffff;
  fill: #03a9f4;
}
.bg-danger {
  background: #dc3545;
  color: #ffffff;
  fill: #dc3545;
}
.bg-warning {
  background: #e7b63a;
  color: #ffffff;
  fill: #e7b63a;
}
.bg-pink {
  background: #e6a1f2;
  color: #ffffff;
  fill: #e6a1f2;
}
.bg-dark {
  background: #545454;
  color: #ffffff;
  fill: #545454;
}
.bg-transparent {
  background: transparent;
  color: #252525;
}
.no-select-arrow {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  border: 1px solid #e7e7e7;
}
.bg-ash {
  background: #f5f5f5;
}
.bg-white {
  background: #ffffff;
}
/*    Border Mixins
--------------------------*/
.border-primary {
  border-color: #5873fe;
}
.border-success {
  border-color: #28a745;
}
.border-info {
  border-color: #03a9f4;
}
.border-danger {
  border-color: #dc3545;
}
.border-warning {
  border-color: #e7b63a;
}
.border-pink {
  border-color: #e6a1f2;
}
.border-dark {
  border-color: #545454;
}
.no-border {
  border: 0px !important;
}
.border-top {
  border-top: 1px solid #e7e7e7;
}
.border-white {
  border: 1px solid #ffffff;
}
.border-bottom {
  border-bottom: 1px solid #e7e7e7;
}
.border-left {
  border-left: 1px solid #e7e7e7;
}
.border-right {
  border-right: 1px solid #e7e7e7;
}
.white-bottom {
  border-bottom: 1px solid #ffffff;
}
.radius-0 {
  border-radius: 0;
}
/*    Brand Background
-----------------------------*/
.bg-facebook {
  background: #3b5998;
  fill: #3b5998;
}
.bg-twitter {
  background: #1da1f2;
  fill: #1da1f2;
}
.bg-youtube {
  background: #cd201f;
  fill: #cd201f;
}
.bg-google-plus {
  background: #dd4b39;
  fill: #dd4b39;
}
.bg-linkedin {
  background: #007bb6;
}
/*    Gradient
-----------------------------*/
.liner-gradient-primary {
  background: linear-gradient(to right, rgba(253, 77, 54, 0.7), #fd4d36);
}
/*    width
-----------------------------*/
.w10pr {
  width: 10%;
}
.w12pr {
  width: 12%;
}
.p-28 {
  padding: 28px;
}
.p-10 {
  padding: 10px;
}
/*    Chart Spanrkline
-------------------------*/
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/*    Bootstrap class
---------------------------*/
@media (min-width: 1500px) {
  .container {
    width: 1400px;
  }
}
.row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
@media (max-width: 667px) {
  .dt-buttons {
    margin-left: 10px;
  }
}
@media (max-width: 480px) {
  .dt-buttons {
    display: inline-block;
  }
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #28a745;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #28a745;
  border-left-color: #28a745;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes pace-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.superpose {
  color: #eee;
  height: 350px;
  width: 100%;
}
.superclock {
  position: relative;
  width: 300px;
  margin: auto;
}
.superclock1 {
  position: absolute;
  left: 10px;
  top: 10px;
}
.superclock2 {
  position: absolute;
  left: 60px;
  top: 60px;
}
.superclock3 {
  position: absolute;
  left: 110px;
  top: 110px;
}
#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  z-index: 99999;
  opacity: 0;
}
#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
}
#search input[type="search"] {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
  font-size: 60px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
}
#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
}
#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  opacity: 1;
  padding: 10px 17px;
  font-size: 27px;
}
.media-text-right {
  text-align: right;
}
.media-text-left {
  text-align: left;
}
.boxshadow-none {
  box-shadow: none;
}
.progress-sm {
  height: 8px;
}
.bg-warning-dark {
  background: #e7b63a;
}
.bg-info-dark {
  background: #8b67c9;
}
.bg-danger-dark {
  background: #e63327;
}
.bg-success-dark {
  background: #2ed3aa;
}
.bg-primary-dark {
  background: #0095e1;
}
.widget-card-circle i {
  font-size: 30px;
  left: 0;
  line-height: 97px;
  right: 0;
  text-align: center;
}
.widget-line-list li {
  display: inline-block;
  font-size: 1.2em;
  line-height: 27px;
  padding: 5px 20px 0 15px;
}
.widget-line-list li span {
  font-size: 12px;
}
.height-150 {
  height: 150px;
}
.social-connect ul li {
  display: inline-block;
}
.social-connect ul li a {
  display: inline-block;
  margin: 0 5px;
  padding: 12px 15px;
  border-radius: 4px;
}
.user-card-absolute {
  top: 115px;
  left: 0;
  right: 0;
}
.social-pad {
  padding: 40px 30px 110px;
}
.round-img img {
  border-radius: 100px;
}
.blockquote-box {
  border-right: 5px solid #e6e6e6;
  margin-bottom: 25px;
}
.blockquote-box .square {
  width: 100px;
  min-height: 50px;
  margin-right: 22px;
  text-align: center !important;
  background-color: #e6e6e6;
  padding: 20px 0;
}
.blockquote-box .blockquote-primary {
  border-color: #5873fe;
}
.blockquote-box .blockquote-primary .square {
  background-color: #5873fe;
  color: #ffffff;
}
.blockquote-box .blockquote-success {
  border-color: #28a745;
}
.blockquote-box .blockquote-success .square {
  background-color: #28a745;
  color: #ffffff;
}
.blockquote-box .blockquote-info {
  border-color: #03a9f4;
}
.blockquote-box .blockquote-info .square {
  background-color: #03a9f4;
  color: #ffffff;
}
.blockquote-box .blockquote-warning {
  border-color: #e7b63a;
}
.blockquote-box .blockquote-warning .square {
  background-color: #e7b63a;
  color: #ffffff;
}
.blockquote-box .blockquote-danger {
  border-color: #d43f3a;
}
.blockquote-box .blockquote-danger .square {
  background-color: #dc3545;
  color: #ffffff;
}
input {
  outline: none;
}
/*
/* Version: 1.0
*/
/*-------- css code for responsive layout  --------*/
/*  To make Responsive
---------------------------------------------------------------------- /
*	1 - media screen and (max-width: 1750px)
*   2 - media screen and (max-width: 1680px)
*   3 - media screen and (max-width: 1280px)
*   4 - media screen and (max-width: 1199px)
*   5 - media screen and (max-width: 1024px)
*   6 - media screen and (max-width: 991px)
*   7 - media screen and (max-width: 767px)
*   8 - media screen and (max-width: 680px)
*   9 - media screen and (max-width: 480px)
*   10 - media screen and (max-width: 320px)
*
---------------------------------------------------------------------- */
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  1 - media screen and (max-width: 1750px)
---------------------------------------------------------------------- */
/*  1 - media screen and (max-width: 1750px) End
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px)
---------------------------------------------------------------------- */
/*  2 - media screen and (max-width: 1680px) End
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px)
---------------------------------------------------------------------- */
/*  3 - media screen and (max-width: 1280px) End
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px)
---------------------------------------------------------------------- */
/*  4 - media screen and (max-width: 1199px) End
---------------------------------------------------------------------- */
/*  5 - media screen and (max-width: 1024px)
---------------------------------------------------------------------- */
.hide-logo-desktop {
  display: block;
}
.hide-logo-on-mobile {
  display: none;
}
.notification-in-sidebar {
  display: none;
}
.notification-in-sidebar svg {
  opacity: 0.6;
}
.notification-in-sidebar svg:hover {
  opacity: 1;
  cursor: pointer;
}
.sidebar .nano-content > ul > li.label {
  font-size: 11px;
  color: rgb(123, 123, 123);
}
.what-are-you-woring form input[type="text"] {
  color: rgb(0, 0, 0) !important;
  font-family: Roboto, Helvetica, sans-serif !important;
  box-shadow: none !important;
  height: 66px !important;
  font-size: 18px !important;
  background: transparent !important;
  padding: 10px 20px 11px !important;
  border: 0px;
  display: block;
  width: 100%;
  margin: 0px;
  outline: none;
}
.topbar-timer-header-contain .what-are-you-woring form {
  height: 100%;
}
.topbar-timer-header-contain .what-are-you-woring {
  width: 100%;
}
.topbar-timer-header-contain {
  width: 100%;
  display: flex;
  align-items: center;
}
.header-folder span.folder-icon {
  width: 30px;
  height: 30px;
  padding: 10px;
  /*    background-image: url(../images/folder.svg);*/
  background-repeat: no-repeat;
  background-size: 50%;
}
.header-tag-icon span.inner-tag-icon {
  width: 30px;
  height: 30px;
  padding: 10px;
  /*   background-image: url(../images/tag.svg);*/
  background-repeat: no-repeat;
  background-size: 50%;
}
.header-top-start-timer-box {
  display: flex;
  position: relative;
  height: 66px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 0 0 auto;
}
.header-top-start-timer-box .time-dur input {
  width: 95px;
  max-width: 200px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  color: rgb(123, 123, 123) !important;
  background: transparent;
  border-radius: 0;
  border: 0;
}
.topbar-timer-header-contain .top-header-last-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  margin-right: 4px;
  margin-left: 12px;
}
.below-time-clock-opt {
  display: flex;
  width: 28px;
  height: 28px;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.time-clock-box {
  width: 28px;
  height: 28px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;
}
.header-tag-icon {
  position: relative;
  flex: 0 0 auto;
  width: 45px;
}
.header-dollar-icon span.dollar-inner {
  padding: 7px;
  display: flex;
}
.header-dollar-icon span.dollar-inner svg > path {
  stroke: rgb(206, 206, 206);
}
.header-folder {
  flex: 0 0 auto;
  position: relative;
}
.header-folder span.folder-icon {
  width: 30px;
  height: 30px;
  padding: 7px;
  /*    background-image: url(../images/folder.svg);*/
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
}
.header-tag-icon span.inner-tag-icon {
  width: 30px;
  height: 30px;
  padding: 7px;
  /*   background-image: url(../images/tag.svg);*/
  background-repeat: no-repeat;
  background-size: 50%;
  display: flex;
}
.header-folder span.folder-icon:hover svg > path,
.header-tag-icon span.inner-tag-icon:hover svg path {
  fill: #444;
  cursor: pointer;
}
.header-dollar-icon span.dollar-inner:hover svg > path {
  stroke: #444;
  cursor: pointer;
}
.header-folder span.folder-icon:active,
.header-tag-icon span.inner-tag-icon:active,
.header-dollar-icon span.dollar-inner:active {
  background-color: hsla(0, 0%, 69.4%, 0.135);
  border-radius: 8px;
}
.topbar-timer-header-contain .header-folder {
  min-width: 45px;
  justify-content: center;
}
.topbar-timer-header-contain .header-folder {
  min-width: 45px;
  justify-content: center;
}
.topbar-timer-header-contain .header-folder span.folder-icon svg {
  opacity: 0.6;
}
.topbar-timer-header-contain .header-folder:hover svg {
  opacity: 1;
  cursor: pointer;
}
.topbar-timer-header-contain .header-folder:hover svg path {
  fill: #444;
}

.topbar-timer-header-contain .what-are-you-woring {
  position: relative;
  min-width: 161px;
  height: 100%;
  font-size: 16px;
  flex: 1 1 auto;
}
.header-top-start-timer-box .start-time-btn {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  user-select: none;
}
.drop-down.user-projects-serch-tp-fld,
.dropdown .user-projects-serch-tp-fld {
  width: 340px;
  max-height: 500px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
.drop-down.user-projects-serch-tp-fld .folder-inner-serchbox input,
.dropdown .user-projects-serch-tp-fld .folder-inner-serchbox input {
  position: relative;
  width: 100%;
  height: 30px;
  padding: 0 0 0 32px;
  margin: 0;
  border: 1px solid #ececec;
  border-radius: 8px;
  box-shadow: none;
  background: #fff url(../images/search-icon.svg) 10px no-repeat;
  outline: none;
  line-height: 30px !important;
}
.drop-down.user-projects-serch-tp-fld h5,
.dropdown .user-projects-serch-tp-fld h5 {
  color: #7b7b7b;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  padding: 20px 10px 0;
}
.user-projects-serch-tp-fld .create-project-in-serchbox,
.user-folder-about-inner-content .create-project-in-serchbox {
  border-top: 1px solid #ececec;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.user-folder-about-inner-content .create-project-in-serchbox {
  margin-top: 10px;
  height: 30px;
}

.user-projects-serch-tp-fld .create-project-in-serchbox a > i,
.user-folder-about-inner-content .create-project-in-serchbox a > i {
  color: #56cb10;
  font-weight: 100 !important;
  margin-right: 5px;
}
.in-timer-title > i {
  font-size: 14px;
  font-weight: 600;
  color: #d6d6d7;
  padding-left: 3px;
}
.in-timer-project-panel {
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 3px 0px;
}
.in-timer-project-panel .in-timer-project-panel-action-row {
  background-color: rgb(255, 255, 255);
  height: 50px;
  padding: 0px 20px;
}
.in-timer-project-panel-action-row .inner-project-action-contain {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  height: 50px;
}
.intimer-project-panel-checkbox-box {
  text-align: center;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  left: -16px;
  width: 46px;
  opacity: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 36%;
}
.intimer-project-panel-checkbox-box .intimer-check-parent {
  position: relative;
  display: inline-block;
  width: 14px;
  min-width: 14px;
  height: 14px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  border-image: initial;
  outline: none !important;
}
.intimer-project-panel-checkbox-box
  .intimer-check-parent
  > input[type="checkbox"] {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: inherit;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.intimer-project-panel-day {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: auto;
}

.intimer-project-panel-day .day-contain {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  white-space: pre-wrap;
}
.intime-project-panel-actionBtn-box {
  margin-left: 10px;
}
.intime-project-panel-actionBtn-box button {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: rgb(123, 123, 123);
  cursor: default;
  padding: 4px 10px;
  background: none;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}
.timer-action-row-top-timedur {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 0px 37.5px;
}
.intimer-action-right-opt-btn-show {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: rgba(157, 197, 132, 0.12);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
  border-image: initial;
}
.project-full-description-row .description-field input {
  border: 0px;
  outline: none;
  width: 98px;
}
.hide-element {
  display: none;
}
.description-field {
  max-width: 27%;
  padding-right: 10px;
  flex: 0 1 auto;
}
.inertimer-row_description-folder {
  position: relative;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(163, 163, 163);
  flex: 0 0 auto;
  /*overflow: hidden;*/
}
.inertimer-row_description-tag {
  position: relative;
  min-width: 80px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
  flex: 0 2 auto;
  overflow: hidden;
}

.intimer-field-descript-time-dur-cont {
  height: 100%;
  min-width: unset;
  flex: 0 0 auto;
  padding: 0px 18px 0px 10px;
}
.intimer-field-descript-time-dur-cont .time-dur-inner-contain {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
  height: 100%;
  flex-flow: column nowrap;
}
.time-dur-inner-contain span.start-end-time-dur {
  display: none;
  color: rgb(123, 123, 123);
  height: 20px;
  line-height: 20px;
  padding-left: 10px;
  text-align: right;
}
.intimer-project-panel-continue-btn-box {
  width: 40px;
  text-align: center;
  opacity: 1;
  flex: 0 0 auto;
}
.intimer-project-panel-option-btn-contain {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
}
.in-timer-project-panel-action-row.project-full-description-row {
  padding-right: 0px;
}
.intimer-field-descript-time-dur-cont
  .time-dur-inner-contain
  span.time-format-utils__duration {
  height: 20px;
  line-height: 20px;
  padding-left: 10px;
  text-align: right;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
}
.hover-show {
  opacity: 0;
}
.in-timer-project-panel-action-row.project-full-description-row:hover
  .hover-show {
  opacity: 1;
}
li.left-sidebar-username {
  padding: 0;
  margin-top: 23px;
  text-align: left;
}
li.left-sidebar-username a {
  line-height: 14px !important;
  text-align: left !important;
}
li.label.user-mail-add {
  line-height: 13px !important;
  font-style: 12px !important;
}
li.label.user-mail-add:hover {
  color: #fff !important;
  cursor: pointer;
}

span.user-shortname-left-sidebar {
  background: red;
  padding: 6px 6px;
  color: #fff;
  text-align: center;
  border-radius: 100px;
  font-size: 13px;
}

li.label.user-workspace-folder {
  margin-top: 50px;
}

.drop-down.user-projects-serch-tp-fld .dropdown-content-body ul,
.dropdown .user-projects-serch-tp-fld .dropdown-content-body ul {
  padding: 15px 25px;
}

.drop-down.user-projects-serch-tp-fld .dropdown-content-body ul li,
.dropdown .user-projects-serch-tp-fld .dropdown-content-body ul li {
  padding: 6px 35px;
  line-height: 20px;
  border: 0px;
  border-radius: 18px;
  margin-bottom: 10px;
}
.drop-down.user-projects-serch-tp-fld .dropdown-content-body ul li a,
.dropdown .user-projects-serch-tp-fld .dropdown-content-body ul li a {
  color: #717171 !important;
  font-weight: 500;
}

.drop-down.user-projects-serch-tp-fld
  .dropdown-content-body
  ul
  li.active-project,
.dropdown
  .user-projects-serch-tp-fld
  .dropdown-content-body
  ul
  li.active-project {
  background: #dcffb6;
}

.drop-down.user-projects-serch-tp-fld .dropdown-content-body ul li:before,
.dropdown .user-projects-serch-tp-fld .dropdown-content-body ul li:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #000;
  left: 45px;
  border-radius: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.drop-down.user-projects-serch-tp-fld
  .dropdown-content-body
  ul
  li.active-project:before,
.dropdown
  .user-projects-serch-tp-fld
  .dropdown-content-body
  ul
  li.active-project:before {
  background: #e8e8e8 !important;
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  left: 45px;
  border-radius: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 55;
  box-shadow: 0px 0px 5px rgba(0, 64, 144, 0.37);
}
.reports-header-contain {
  width: 100%;
  display: flex;
  align-items: center;
}
.reports-header-contain {
  box-shadow: rgb(232, 232, 232) 0px 1px 0px 0px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 66px;
  padding: 0px 20px;
}
.reports-header-contain h3 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 30px;
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  margin-right: 20px;
  margin-bottom: 0px;
}
.reports-header-contain a {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 30px;
  padding: 0px 10px;
  color: rgb(163, 163, 163);
}
.reports-filter-row .report-filter-item-box:hover {
  background-color: rgba(177, 177, 177, 0.133) !important;
  color: rgb(0, 0, 0);
}
.reports-header-contain a:hover {
  color: rgb(0, 0, 0);
}
.reports-header-contain .reports-right-item-cont {
  display: flex;
  margin-left: auto;
  -webkit-box-align: center;
  align-items: center;
}
.reports-right-item-cont .save-btn {
  margin-right: 20px;
}
.reports-right-item-cont .save-btn button {
  background-color: transparent;
  text-align: inherit;
  width: 36px;
  height: 36px;
  cursor: default;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  font: inherit;
  padding: 10px;
}
.reports-header-contain .reports-right-item-cont .report-download-btn {
  display: inline-block;
}
.reports-header-contain
  .reports-right-item-cont
  .report-download-btn
  > button.dropdown-toggle {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  width: 36px;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0) !important;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
  padding: 0px;
}
.reports-filter-row {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 50px;
  padding: 0px 20px;
}
.reports-filter-row .filter-label {
  margin-right: 10px !important;
  color: #7b7b7b;
}
.reports-filter-row .report-filter-item-box {
  display: inline-block;
  height: 30px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: rgb(123, 123, 123);
  padding: 6px;
  border-radius: 8px;
}
.reports-filter-row .report-filter-item-box .report-filter-inner-item {
  position: relative;
  display: flex;
}
.reports-filter-row .report-filter-item-box .report-filter-inner-item span {
  text-align: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 7px;
}
.reports-filter-row
  .report-filter-item-box
  .report-filter-inner-item.fill-remove
  svg
  g {
  fill: none;
}
.reports-filter-row
  .report-filter-item-box
  .report-filter-inner-item.fill-remove
  svg
  g
  rect {
  fill: rgb(206, 206, 206);
}

.reports-filter-row
  .report-filter-item-box
  .report-filter-inner-item.fill-remove
  svg
  g
  path {
  fill: rgb(255, 255, 255);
}
.reports-right-item-cont .save-btn button svg path {
  fill: rgb(177, 177, 181);
  fill-opacity: 0.3;
}

.reports-right-item-cont .save-btn button:hover svg path {
  fill: rgb(206, 206, 206);
  fill-opacity: 0.3;
}
.reports-header-contain
  .reports-right-item-cont
  .report-download-btn
  button:hover {
  cursor: pointer;
  opacity: 0.7;
}
.in_report-chart-day-btngroup-contain {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 50px;
  flex-flow: row nowrap;
  margin: 0px 13px;
}
.day-three_btngroup {
  margin-bottom: 8px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-flow: row nowrap;
}
.in_report-chart-day-btngroup-contain .day-three_btngroup button.btn-left,
.in_report-chart-day-btngroup-contain .day-three_btngroup button.right_btn {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: rgb(0, 0, 0);
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  width: 36px;
  height: 36px;
  background-color: rgb(255, 255, 255);
  left: 0px;
  position: relative;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  margin: 0px;
  border-radius: 10px 0px 0px 10px;
}
.in_report-chart-day-btngroup-contain .day-three_btngroup button.right_btn {
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  width: 36px;
  height: 36px;
  background-color: rgb(255, 255, 255);
  right: 0px;
  position: relative;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  margin: 0px;
  border-radius: 0px 10px 10px 0px;
}
.in_report-chart-day-btngroup-contain .day-three_btngroup button.right_btn svg {
  transform: rotate(180deg);
}
.in_report-chart-day-btngroup-contain .day-three_btngroup .select-day-box {
  height: 36px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 5px;
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
}
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  .select-day-box
  button {
  background-color: transparent;
  border: 0px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: normal;
  cursor: pointer;
}
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  button.btn-left
  svg
  path,
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  button.right_btn
  svg
  path {
  fill: rgb(123, 123, 123);
}
.in_report-chart-day-btngroup-contain .day-three_btngroup button.btn-left:focus,
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  button.right_btn:focus {
  background-color: rgba(177, 177, 177, 0.07);
}

.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  .select-day-box
  button
  svg
  path {
  fill: rgb(206, 206, 206);
  cursor: pointer;
}
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  .select-day-box
  button:hover
  svg
  path {
  fill: rgb(123, 123, 123);
}
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  button.btn-left:hover
  svg
  path,
.in_report-chart-day-btngroup-contain
  .day-three_btngroup
  button.right_btn:hover
  svg
  path {
  fill: rgb(0, 0, 0);
}
.report-work-chart-contain {
  position: relative;
  margin-bottom: 40px;
  min-height: 246px;
}
.report-work-chart-contain .chart-graph-box-contain {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  display: flex;
  width: 100%;
  flex-flow: column-reverse nowrap;
}
.report-work-chart-contain .chart-graph-box-contain .report-chart-items {
  width: 100%;
  height: 41px;
  text-align: right;
  font-size: 12px;
  line-height: 1;
  color: rgb(123, 123, 123);
  padding: 24px 20px 4px;
  border-bottom: 1px solid rgb(243, 243, 243);
}
.report-work-chart-contain .report-chart-lavel-contain {
  /* background-color: white; */
  position: relative;
  z-index: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-flow: row nowrap;
  padding: 0px 65px 0px 20px;
}
.report-work-chart-contain
  .report-chart-lavel-contain
  .report-chart-lavel-poll {
  position: relative;
  min-width: 30px;
  max-width: 120px;
  flex: 1 1 0%;
}
.report-work-chart-contain .report-chart-lavel-poll .lavel-poll-inner {
  min-width: 30px;
  max-width: 80px;
  padding: 0px 5px;
  margin: 0px auto;
}
.report-work-chart-contain
  .report-chart-lavel-poll
  .lavel-poll-inner
  .lavel-mapper {
  position: relative;
  height: 246px;
}
.report-work-chart-contain
  .report-chart-lavel-poll
  .lavel-poll-inner
  .lavel-mapper
  .lavel-poll {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 1px;
  background-color: rgb(206, 206, 206);
  transform-origin: center bottom;
  animation: 0.33s ease 0s 1 normal none running animation-1cm0ieu;
}
.report-work-chart-contain
  .report-chart-lavel-poll:nth-child(1)
  .lavel-poll-inner
  .lavel-mapper
  .lavel-poll {
  background-color: rgb(91, 180, 220) !important;
}
.report-work-chart-contain .lavel-poll-inner .lavel-title-desc {
  opacity: 1;
  height: 41px;
  padding-top: 8px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  color: rgb(123, 123, 123);
  margin: 0px -20px;
  transition: opacity 0.33s ease 0s;
}
.report-work-chart-contain
  .lavel-poll-inner
  .lavel-title-desc
  > div:first-child {
  margin-bottom: 3px;
  line-height: 16px;
  color: rgb(0, 0, 0);
}
.inner-project-action-contain .action_btns.intimer-dot-option-box,
.tags-button-box .action_btns.intimer-dot-option-box,
.inner-team-active-info-row .action_btns.intimer-dot-option-box,
.filter-by-button-dropdown-wrap .popdropdown-inner-text .dropdown-menu,
.action_btns.intimer-dot-option-box {
  position: absolute;
  top: 34px;
  right: 0px;
  min-width: 130px;
  z-index: 201;
  margin-bottom: 30px;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5px;
  padding: 5px;
  text-transform: capitalize !important;
}
.inner-team-active-info-row .action_btns.intimer-dot-option-box,
.action_btns.intimer-dot-option-box {
  transform: translate3d(-122px, 19px, 0px) !important;
  min-width: fit-content;
}
.inner-project-action-contain .action_btns.intimer-dot-option-box .option-items,
.tags-button-box .action_btns.intimer-dot-option-box .option-items,
.inner-team-active-info-row .action_btns.intimer-dot-option-box .option-items,
.filter-by-button-dropdown-wrap .popdropdown-inner-text .dropdown-menu ul li,
.action_btns.intimer-dot-option-box .option-items {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  color: rgb(0, 0, 0);
  padding: 0px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}
.inner-project-action-contain
  .action_btns.intimer-dot-option-box
  .option-items:hover,
.inner-project-action-contain
  .action_btns.intimer-dot-option-box
  .option-items:focus,
.tags-button-box .action_btns.intimer-dot-option-box .option-items:hover,
.tags-button-box .action_btns.intimer-dot-option-box .option-items:focus,
.inner-team-active-info-row
  .action_btns.intimer-dot-option-box
  .option-items:hover,
.inner-team-active-info-row
  .action_btns.intimer-dot-option-box
  .option-items:focus,
.filter-by-button-dropdown-wrap
  .popdropdown-inner-text
  .dropdown-menu
  ul
  li:hover,
.filter-by-button-dropdown-wrap
  .popdropdown-inner-text
  .dropdown-menu
  ul
  li:focus,
.action_btns.intimer-dot-option-box .option-items:hover,
.action_btns.intimer-dot-option-box .option-items:focus {
  outline: 0px;
  background: rgba(177, 177, 177, 0.133);
}
.header-tag-icon .dropdown-menu {
  position: absolute;
  z-index: 201;
  margin-bottom: 30px;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5px;
  left: 50%;
  top: 51px;
  right: auto;
  margin-left: -170px;
  width: 309px;
  height: 318px;
  max-height: none;
}
.header-tag-icon .dropdown-menu .dropdown-content-heading input[type="text"] {
  display: block;
  position: relative;
  width: 100%;
  height: 28px;
  padding: 0 0 0 32px;
  margin: 0;
  border: 1px solid #ececec;
  border-radius: 8px;
  box-shadow: none;
  background: #fff url(../images/search-icon.svg) 10px no-repeat;
  outline: none;
  line-height: 28px !important;
  font-size: 15px;
}

.header-tag-icon .dropdown-menu .dropdown-content-body {
  padding: 5px 15px;
}
.header-tag-icon .dropdown-menu .dropdown-content-body .find-tags-field-item {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 15px;
}
.header-tag-icon
  .dropdown-menu
  .dropdown-content-body
  .find-tags-field-item
  input[type="checkbox"] {
  margin-right: 8px;
}
.header-tag-icon
  .dropdown-menu
  .dropdown-content-body
  .find-tags-field-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  outline: 0;
}
.header-dollar-icon .dropdown-menu {
  background-color: #fff;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  max-width: 400px;
  right: -99% !important;
  will-change: transform;
  width: 291px;
}

.header-dollar-icon .dropdown-menu .dropdown-content-body {
  font-size: 12px;
  line-height: 1.58;
  color: #000;
  text-align: left;
}

.header-dollar-icon .dropdown-menu .dropdown-content-body span a {
  color: #4cc600;
  font-weight: 500;
  text-decoration: underline !important;
}
.main-wrapper-container {
  background: rgb(136, 207, 143);
  background: linear-gradient(
    180deg,
    rgba(136, 207, 143, 1) 83%,
    rgba(255, 255, 255, 0) 83%
  );
  padding: 100px;
  padding-bottom: 0px;
}
nav.navbar.header-navbar {
  position: absolute;
  width: 100%;
}
.user-folder-about-inner-content {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  position: absolute !important;
  display: flex;
  overflow-y: hidden;
  max-height: calc(100vh - 62px);
  width: 280px;
  left: 185px;
  bottom: 46px;
  transform: translateX(0%);
  font-weight: normal;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;
  flex-flow: column nowrap;
  padding: 15px;
}

.user-folder-about-inner-content.user-profile-modal {
  position: fixed !important;
  bottom: 16px;
}
.user-folder-about-inner-content a.sidebar-sub-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-folder-about-inner-content a.sidebar-sub-toggle span {
  margin-right: auto;
  padding-left: 10px;
  display: flex;
}
.user-folder-about-inner-content a.sidebar-sub-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-folder-about-inner-content a.sidebar-sub-toggle span:nth-child(2) {
  margin-right: auto;
  padding-left: 10px;
  display: flex;
  color: #4bc800;
}
.user-folder-about-inner-content a.sidebar-sub-toggle span:nth-child(3) {
  margin-left: auto;
  font-weight: 400;
  font-size: 12px;
  color: rgb(123, 123, 123);
  line-height: 1.5;
  background-color: rgb(232, 232, 232);
  margin-left: 10px;
  border-radius: 6px;
  padding: 1px 5px;
}
.show-box {
  display: block !important;
}
ul.in-timer-select-workday ul.dropdown-menu {
  position: absolute;
  z-index: 201;
  min-width: 130px;
  margin-bottom: 30px;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5px;
  top: 45px;
  right: 15px;
  padding: 10px;
}
.report-download-btn.dropdown button.dropdown-toggle::after {
  display: none;
}

ul.in-timer-select-workday ul.dropdown-menu li {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 2px;
  display: block;
  color: #222;
}
ul.in-timer-select-workday ul.dropdown-menu li:hover,
.report-download-btn .dropdown-menu .dropdown-item:hover {
  background: hsla(0, 0%, 69.4%, 0.135);
}
ul.in-timer-select-workday ul.dropdown-menu li.days-selected {
  background-color: rgba(74, 199, 0, 0.12);
}
ul.in-timer-select-workday ul.dropdown-menu li.days-selected a {
  color: #4bc800 !important;
}
ul.in-timer-select-workday ul.dropdown-menu li a {
  color: #222;
}
ol.days-project-list-cont {
  display: flex;
  flex-wrap: nowrap;
  height: 26px;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 26px;
  list-style: none;
  padding: 0px;
}
ol.days-project-list-cont li.days-project-list {
  display: block;
  position: relative;
  align-self: stretch;
  height: 100%;
  margin: 0;
  min-width: 50px;
  transition: flex 0.2s ease-in-out;
}
ol.days-project-list-cont li.days-project-list .days-project-list-bottom-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
}
.timeline .timeline-section-container {
  position: relative;
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.timeline .timeline-section-container .timeline-left-img {
  display: block;
  background-image: url(../images/timeline.png);
  background-size: 150px 150px;
  width: 150px;
  height: 150px;
}
.timeline .timeline-section-container .timeline-right-info {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.timeline .timeline-section-container .timeline-info-content {
  width: 278px;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6f6f6f;
  margin-bottom: 20px;
}
.timeline .timeline-section-container span.timeline-self-text {
  color: #000;
}
.timeline .timeline-section-container a.timeline-intro-button-wrap {
  width: 200px;
  height: 40px;
  border-radius: 3px;
  background-color: #4bc800;
  padding: 10px;
  text-align: center;
  color: #fff;
}
.timeline
  .timeline-section-container
  a.timeline-intro-button-wrap
  .timeline-btn-content {
  display: inline-flex;
  width: 114px;
  height: 19px;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.timelines-contain:before {
  display: none;
}
.report-download-btn .dropdown-menu {
  width: 160px;
  margin-bottom: 30px;
  padding: 10px 5px;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 5px;
  right: 0px !important;
}
.report-download-btn .dropdown-menu .dropdown-item {
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 14px;
  margin-bottom: 2px;
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  border-radius: 8px;
  cursor: pointer;
}
.report-download-btn > button.dropdown-toggle:focus svg path {
  fill: rgb(75, 200, 0);
}
.report-download-btn > button.dropdown-toggle:focus {
  border-radius: 8px;
  box-shadow: rgba(157, 197, 132, 0.12) 0px 0px 0px 1px inset;
  background-color: rgba(157, 197, 132, 0.12) !important;
}
.dropdown-toggle::after {
  display: none;
}
.inertimer-row_description-tag .header-tag-icon {
  overflow: visible !important;
}
.inertimer-row_description-tag .header-tag-icon .dropdown-menu {
  transform: unset !important;
  top: 35px !important;
}
.inertimer-row_description-tag {
  overflow: unset;
}
/*.header-top-start-timer-box .start-time-btn svg.start-pause-btn {
    display: none;
}*/
.datepicker-dropdown,
.datepicker-inline {
  top: 66px;
  left: 913.5px;
  right: 0px !important;
  display: block;
  position: absolute;
  z-index: 10;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;
  min-width: 320px;
  width: 340px;
  margin-top: 5px;
  transition: opacity 0.3s ease 0s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  padding: 15px;
}
.datepicker-inline {
  left: 0;
  top: 0;
}

.datepicker table {
  width: 100%;
}
.datepicker table tbody tr {
  /* border-top: 1px solid #ddd; */
  height: 42px;
  border-right-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-style: solid none none;
  border-image: initial;
  border-top: 1px solid rgb(243, 243, 243);
}
.datepicker table tbody tr td {
  border-radius: 8px;
  font-size: 14px;
  height: 25px !important;
  width: 25px !important;
  margin: 15px;
  text-align: center;
}
.datepicker table tbody tr td:hover {
  /* background: #efefef; */
  background-color: rgba(177, 177, 177, 0.07) !important;
  border-radius: 8px;
}
.datepicker table tbody tr td.active {
  color: #fff !important;
  background-color: rgb(75, 200, 0) !important;
}

nav.navbar.header-navbar a.navbar-brand svg {
  height: 3.6rem;
  width: 7.1rem;
}
.page-header-content-wrap {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}
.hero .page-header-content-wrap h1 {
  font-size: 35px;
  font-weight: 800;
  color: #fff;
  padding-bottom: 12px;
}
.hero .page-header-content-wrap h2 {
  font-size: 18px;
  color: #fff;
  padding-bottom: 25px;
}

.header-top-calander-timer-box.stop-timer-btn-cont {
  display: flex;
  height: 66px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 12.5px;
  flex: 0 0 auto;
}

.header-top-calander-timer-box.stop-timer-btn-cont .time-dur {
  width: 128px;
}

.header-top-calander-timer-box.stop-timer-btn-cont
  .time-dur
  input[type="text"] {
  width: 128px;
  padding: 10px 12px;
  outline: none;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  transition: color 0.5s;
  background-color: initial;
  box-shadow: none;
  height: 34px !important;
}
.header-top-calander-timer-box.stop-timer-btn-cont .timer-btween-arrow {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 40px;
  height: 36px;
}
section.form-login-wrap form.form-login,
section.login__form-wrap form.login__form {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s, transform 0.8s;
  transition-delay: 0.45s;
  transition-timing-function: cubic-bezier(0.12, 0.845, 0.305, 1);
  padding: 65px;
  background-color: #fff;
  border-radius: 0.4rem;
}
section.form-login-wrap form.form-login .spinner__overlay,
section.login__form-wrap form.login__form .spinner__overlay {
  transition: all 0.5s;
  opacity: 0;
  z-index: -1;
}
section.form-login-wrap form.form-login .form-field,
section.login__form-wrap form.login__form .form-field {
  transition-delay: 1.05s;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.8s, transform 0.8s;
  transition-timing-function: cubic-bezier(0.12, 0.845, 0.305, 1);
  margin-bottom: 32px;
}
section.form-login-wrap form.form-login .form-field.form-btn-submit > div,
section.login__form-wrap form.login__form .form-field.form-btn-submit > div {
  margin-bottom: 1.8rem;
  width: 100%;
}
section.form-login-wrap form.form-login .form-field.form-btn-submit button,
section.login__form-wrap form.login__form .form-field.form-btn-submit button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  min-width: 21.7rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-osx-font-smoothing: grayscale;
  border: 3px solid transparent;
  background-color: #fff;
  border-color: #f1f4f6;
  color: #282a2d;
  min-width: 100%;
  padding: 0 2rem;
  width: 100%;
  height: 50px;
  border-radius: 32px;
  white-space: nowrap;
  overflow: hidden;
}
section.form-login-wrap form.form-login .form-field.form-btn-submit button span,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button
  span {
  font-size: 14px;
  display: inline-block;
  transform: translateX(0) translateY(-0.1rem);
  transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  user-select: none;
  white-space: nowrap;
  will-change: transform;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:before {
  position: absolute;
  top: 50%;
  transform: translateX(-1.3rem) translateY(-50%) translateY(-0.1rem) scaleY(0);
  transition: transform 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  background-position: 50%;
  /*background-image: url(../images/button-arrow.svg);*/
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  display: inline-block;
  content: "";
  fill: #fff;
  height: 0.8rem;
  transform-origin: center center;
  width: 0.6rem;
  will-change: transform;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:hover,
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:focus,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:hover,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:focus {
  border-color: #e3e8eb !important;
  transition: border-color 0.3s !important;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:hover:before,
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:focus:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:hover:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:focus:before {
  transform: translateX(0) translateY(-50%) translateY(-0.1rem) scaleY(1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0.4s;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:after,
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:after,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:before {
  /*background-image: url(../images/button-arrow.svg);*/
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:after,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:after {
  margin-left: 0.7rem;
  position: relative;
  top: -0.1rem;
  transform: translateX(0) translateY(-0.1rem) scaleY(1);
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0.4s;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  display: inline-block;
  content: "";
  fill: #fff;
  height: 0.8rem;
  transform-origin: center center;
  width: 0.6rem;
  will-change: transform;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button:hover:after,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button:hover:after {
  transform: translateX(1.3rem) translateY(-0.1rem) scaleY(0);
  transition: transform 0.4s cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button
  span.google-logo:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button
  span.google-logo:before {
  background-image: url(../images/google-plus.svg);
  content: "";
  background-repeat: no-repeat;
  background-size: 20px 16px;
  padding-left: 20px;
  background-position: center;
}
section.form-login-wrap
  form.form-login
  .form-field.form-btn-submit
  button
  span.apple-logo:before,
section.login__form-wrap
  form.login__form
  .form-field.form-btn-submit
  button
  span.apple-logo:before {
  background-image: url(../images/apple-logo.svg);
  content: "";
  background-repeat: no-repeat;
  background-size: 20px 16px;
  padding-left: 20px;
  background-position: center;
}
section.form-login-wrap form.form-login .form-field--divider,
section.login__form-wrap form.login__form .form-field--divider {
  margin: 2em 0 1.5em !important;
  line-height: 0;
  text-align: center;
  color: #a2a2a2;
}
section.form-login-wrap form.form-login .form-field--divider span,
section.login__form-wrap form.login__form .form-field--divider span {
  background-color: #fff;
  padding: 5px;
  text-transform: uppercase;
  font-size: 14px;
  vertical-align: top;
  color: #a2a2a2;
}
section.form-login-wrap form.form-login .form-field--divider:before,
section.login__form-wrap form.login__form .form-field--divider:before {
  content: " ";
  display: block;
  border-top: 3px solid #f1f4f6;
}
section.form-login-wrap form.form-login .form-field.form-input-field,
section.login__form-wrap form.login__form .form-field.form-field--text {
  margin-bottom: 36.2px;
  position: relative;
}
section.form-login-wrap form.form-login .form-field.form-input-field label,
section.login__form-wrap form.login__form .form-field.form-field--text label {
  display: block;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.45;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #282a2d;
}
section.form-login-wrap form.form-login .form-field.form-input-field input,
section.login__form-wrap form.login__form .form-field.form-field--text input {
  height: 50px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 97.2px;
  background-color: #e3e8eb;
  border: 3.2px solid #e3e8eb;
  border-radius: 6.4px;
  box-shadow: none;
  color: #282a2d;
  display: block;
  resize: none;
  transition: border-color 0.3s;
  width: 100%;
  outline: none;
}
section.form-login-wrap
  form.form-login
  .form-field.form-input-field
  input:focus,
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  input:focus {
  border-color: #88cf8f;
}
section.form-login-wrap
  form.form-login
  .form-field.form-input-field
  label.error,
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  label.error {
  font-size: 13px;
  transform: translateY(calc(100% + 0.5rem));
  color: #e24f54 !important;
  bottom: -6px;
  text-transform: lowercase;
  display: block;
  left: 8px;
  pointer-events: none;
  position: absolute;
  right: 0;
  transform: translateY(calc(100% + 0.4rem));
  transition: opacity 0.3s;
}
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  span.login-form-validation-error {
  display: none;
}

section.form-login-wrap
  form.form-login
  .form-field.form-input-field
  input.error,
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  input.error {
  border-color: #e56468 !important;
}

section.form-login-wrap
  form.form-login
  .form-field.form-input-field
  span.form-icon,
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  span.form-icon {
  height: 27px;
  right: 1.4rem;
  top: 2.3rem;
  display: block;
  opacity: 0.4;
  position: absolute;
  transform: translateX(50%);
  transition: opacity 0.4s;
  width: auto;
  will-change: opacity;
  z-index: 4;
}
section.form-login-wrap
  form.form-login
  .form-field.form-input-field
  span.form-icon
  svg,
section.login__form-wrap
  form.login__form
  .form-field.form-field--text
  span.form-icon
  svg {
  height: 100%;
  width: auto;
}
section.form-login-wrap form.form-login p.form-field,
section.login__form-wrap form.login__form p.form-field {
  margin-bottom: 24px;
  text-align: right;
  font-size: 16px;
}
section.form-login-wrap form.form-login p.form-field a,
section.login__form-wrap form.login__form p.form-field a {
  text-decoration-color: #e24f54 !important;
  text-decoration: underline !important;
  color: #282a2d;
}
section.form-login-wrap form.form-login button#login-button,
section.login__form-wrap form.login__form button#login-button {
  background-color: #e24f54;
  border: 0px;
  color: #fff;
  font-weight: 600;
}
section.form-login-wrap form.form-login button#login-button span,
section.login__form-wrap form.login__form button#login-button span {
  padding-top: 5px;
}
section.form-login-wrap form.form-login .form-field.form-btn-submit button,
section.login__form-wrap form.login__form .form-field.form-btn-submit button {
  padding-top: 5px;
}
section.signup-free__link .link-banner.link-banner--medium,
section.signup-free__link .link-banner.link-banner--medium {
  padding-bottom: 16rem;
  padding-top: 10rem;
  text-align: center;
}
section.signup-free__link .link-banner.link-banner--medium p {
  font-size: 21px;
  max-width: 70rem;
  color: #282a2d;
  font-weight: 500;
}
section.signup-free__link .link-banner.link-banner--medium a {
  background-color: #e24f54;
  border: 0;
  border-radius: 2.4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 44px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px 43px;
}
section.signup-free__link .link-banner.link-banner--medium a:before {
  position: absolute;
  content: "\f0da";
  right: 25px;
  top: 48%;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  transform: translate(-50%, -50%);
}
nav.navbar.header-navbar .navbar-nav a {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding-right: 15px;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 2px;
}
nav.navbar.header-navbar .navbar-nav a:after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background: #fff;
  left: 50%;
  bottom: 0;
  transform: translate(-54%, -50%);
  transform-origin: center;
}
nav.navbar.header-navbar .navbar-nav a:hover:after {
  width: 75%;
  transition: all 300ms ease-out;
}
.hero .page-header-content-wrap.text-dark h1,
.hero .page-header-content-wrap.text-dark h2,
.hero .hero__content.wrap.wrap--narrow.text-dark h1,
.hero .hero__content.wrap.wrap--narrow.text-dark h2 {
  color: #000;
}

.hero .page-header-content-wrap.text-dark h1,
.hero .page-header-content-wrap.text-dark h2,
.hero .hero__content.wrap.wrap--narrow.text-dark h1,
.hero .hero__content.wrap.wrap--narrow.text-dark h2 {
  color: #000;
}
.form-login-wrap .signup-left-animation,
.login__form-wrap .signup-left-animation {
  background: #cd9bda;
  padding: 60px;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
}
section.signup-wrap form.form-login,
section.signup-wrap form.login__form {
  border-radius: 0px 5px 5px 0px;
}
.form-login-wrap .signup-left-animation img,
.login__form-wrap .signup-left-animation img {
  width: 100%;
}
section.form-login-wrap .col-6-sign,
section.login__form-wrap .col-6-sign {
  flex: 1;
}
.hero__signup-overlay {
  background-image: url(../images/signup-pattern.png);
  background-position: 50%;
  background-repeat: repeat;
  background-size: 32rem;
  left: 0;
  opacity: 0;
  height: calc(100% + 14rem);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.main-wrapper-container.wrapper-in-signup {
  background: linear-gradient(180deg, #feeede 83%, rgba(255, 255, 255, 0) 83%);
  padding-bottom: 60px;
}
/*====in signup custom select location field start from here======*/

.chosen-value,
.value-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-family: "Raleway", sans-serif;
}

.chosen-value {
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  letter-spacing: 4px;
  height: 4rem;
  font-size: 1.1rem;
  padding: 1rem;
  background-color: #fafcfd;
  border: 3px solid transparent;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.chosen-value::-webkit-input-placeholder {
  color: #333;
}
.chosen-value:hover {
  background-color: #ff908b;
  cursor: pointer;
}
.chosen-value:hover::-webkit-input-placeholder {
  color: #333;
}
.chosen-value:focus,
.chosen-value.open {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  outline: 0;
  background-color: #ff908b;
  color: #000;
}
.chosen-value:focus::-webkit-input-placeholder,
.chosen-value.open::-webkit-input-placeholder {
  color: #000;
}

.value-list {
  font-family: "Raleway", sans-serif;
  list-style: none;
  margin-top: 3rem;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-height: 0;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.value-list.open {
  max-height: 320px;
  overflow: auto;
}
.value-list li {
  position: relative;
  height: 4rem;
  font-family: "Raleway", sans-serif;
  background-color: #fafcfd;
  padding: 1rem;
  font-size: 1.1rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  opacity: 1;
}
.value-list li:hover {
  color: #ff908b;
}
.value-list li.closed {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
}
.form-field.form-input-field.select-location-cont,
.form-field.form-field--text.form-field--email.select-location-cont {
  top: 0;
  z-index: 99999;
}
.form-field.form-input-field.select-location-cont .form-group label,
.form-field.form-field--text.form-field--email.select-location-cont
  .form-group
  label {
  margin-top: 50px;
}

.form-field.form-input-field.select-location-cont ul.value-list,
.form-field.form-field--text.form-field--email.select-location-cont
  ul.value-list {
  z-index: 9999999;
}
/*=======in signup custom select location field end here======*/

.project-group-work-section {
  display: flex;
  align-items: flex-start;
  margin: 0px 20px;
}
.project-group-work-section .working-group-area-contain {
  min-width: 0px;
  margin-right: 30px;
  flex: 1 1 auto;
}
.project-group-work-section .group-main-inner-box {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  border-radius: 8px;
}
.project-group-work-section .group-main-inner-box .group-inner-box-first-row {
  padding: 0px 20px;
}
.project-group-work-section .group-main-inner-box .first-row-inner-box {
  height: 66px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.project-group-work-section
  .group-main-inner-box
  .first-row-inner-box
  .first-group-btn-wrap {
  display: flex;
  margin-right: 20px;
}
.project-group-work-section
  .group-main-inner-box
  .first-row-inner-box
  .first-group-btn-wrap
  button.group-by-btn,
.filter-by-team-btn button.group-by-btn {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: transparent;
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
}
.project-group-work-section
  .group-main-inner-box
  .first-row-inner-box
  .first-group-btn-wrap
  button.group-by-btn:first-child,
.filter-by-team-btn button.group-by-btn {
  margin-right: 10px;
}
.project-group-work-section
  .first-row-inner-box
  .first-group-btn-wrap
  button.group-by-btn
  span.btn-first-text,
.filter-by-team-btn button.group-by-btn span.btn-first-text {
  color: rgb(123, 123, 123);
  margin-right: 3px;
}
.project-group-work-section
  .first-row-inner-box
  .first-group-btn-wrap
  button.group-by-btn
  svg,
.filter-by-team-btn button.group-by-btn svg {
  margin-left: 12px;
  font-weight: 600;
}
.project-group-work-section
  .first-row-inner-box
  .first-group-btn-wrap
  button.group-by-btn
  svg
  path,
.filter-by-team-btn button.group-by-btn svg path {
  stroke: rgb(123, 123, 123);
}
.working-group-area-contain .group-rounding-btn-wrap {
  display: flex;
}
.working-group-area-contain .group-rounding-btn-wrap button.rounding-btn {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
  height: 36px;
  color: rgb(206, 206, 206);
  background-color: rgba(177, 177, 177, 0.07);
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  vertical-align: top;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
}

.working-group-area-contain .group-rounding-btn-wrap button svg {
  margin-right: 8px;
}
.working-group-area-contain .group-rounding-btn-wrap button svg path {
  stroke: rgb(206, 206, 206);
}
.working-group-area-contain
  .group-rounding-btn-wrap
  button
  svg
  path:nth-child(2) {
  fill: rgb(206, 206, 206);
}
.working-group-area-contain .group-inner-box-second-row {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(123, 123, 123);
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.working-group-area-contain
  .group-inner-box-second-row
  .group-box-second-inner {
  box-shadow: rgb(232, 232, 232) 0px -1px 0px 0px inset;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 50px;
  padding: 0px 20px;
}
.group-inner-box-second-row .group-box-second-inner .group-inner-main-wrap {
  width: calc(75% - 20px);
}
.group-inner-box-second-row .group-box-second-inner button.collape-data-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  cursor: pointer;
  margin-right: 18px;
  font: inherit;
  padding: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
  border-image: initial;
  border-radius: 8px;
}
.group-inner-box-second-row
  .group-box-second-inner
  .group-inner-main-wrap
  button.collape-data-btn
  svg
  path {
  stroke: rgb(206, 206, 206);
}
.group-inner-box-second-row
  .group-box-second-inner
  .group-inner-main-wrap
  .group-by-title-wrap {
  height: 30px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.group-inner-box-second-row .group-box-second-inner .filter-arrow-cont {
  display: inline-flex;
  flex-direction: column;
  width: 8px;
  height: 15px;
  margin-left: 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: -7px;
}
.group-box-second-inner .filter-arrow-cont .arrow-top-dur,
.group-box-second-inner .filter-arrow-cont .arrow-down-dur {
  width: 8px;
  height: 7px;
}
.working-group-area-contain
  .group-inner-box-second-row
  .group-box-second-inner
  .filter-by-duration-wrap {
  width: 25%;
}
.working-group-area-contain
  .group-inner-box-second-row
  .filter-by-duration-wrap
  .duration-inner {
  height: 30px;
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}
.work-hour-meter-container {
  width: 240px;
  flex: 0 0 auto;
  padding: 10px;
}
.work-hour-meter-container .activity-clock-meter-wrap {
  min-width: 120px;
  display: block;
  margin-bottom: 30px;
}
.work-hour-meter-container .activity-clock-meter-wrap .meter-title-wrap {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(123, 123, 123);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  line-height: 1;
  margin-bottom: 9px;
}
.work-hour-meter-container .activity-clock-meter-wrap .meter-count-cont {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
}
.work-hour-meter-container svg.activity-meter {
  margin-top: 10px;
  width: 180px;
  height: 180px;
  overflow: visible;
}
.filter-by-project button.collape-data-btn {
  font-size: 14px !important;
  color: #7b7b7b;
}
.filter-by-project .group-by-title-wrap {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: normal;
}
.filter-by-project .duration-inner.timer-count-project-filter {
  font-size: 14px;
  font-weight: normal;
}
.filter-by-project span.project-disabel,
.filter-by-project span.project-enable {
  position: relative;
}
.filter-by-project span.project-enable {
  color: #00db00;
  font-weight: 500;
}
.filter-by-project span.project-disabel:before,
.filter-by-project span.project-enable:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #b1b1b1;
  left: -8px;
  top: 5px;
  border-radius: 50px;
  z-index: 2;
}
.filter-by-project span.project-enable:before {
  background: #00db00 !important;
}
.filter-by-button-dropdown-wrap .team-list-contain-wrap {
  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;
}
.team-name-filter-by span.team-user-short {
  background-color: rgb(160, 26, 165);
  margin-right: 10px;
  min-width: 21px;
  width: 21px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  padding: 3px;
}
.filter-by-button-dropdown-wrap
  .team-list-contain-wrap::-webkit-scrollbar-track,
.in-profile-select-country-contain
  .filter-by-button-dropdown-wrap
  .Teamdropdown_container::-webkit-scrollbar-track {
  background-color: #ffffff !important;
}
.enableproject-options {
  cursor: pointer;
  color: #222222 !important;
}
.group-by-client-name .project-client-name {
  color: #222222;
}
.group-by-client-name span.user-short-name {
  /*background: #f00;*/
  padding: 5px;
  border-radius: 100px;
  color: #fff;
  font-weight: 500;
  margin-right: 10px;
  font-size: 13px;
}
.group-inner-box-second-row:hover {
  background: #f5f5f5;
  transition: 0.3s;
}
.project-group-work-section .first-row-inner-box ul.dropdown-menu,
.filter-by-team-btn ul.dropdown-menu {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  top: 100%;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
  min-width: 130px;
  white-space: nowrap;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;
  padding: 5px;
  overflow: visible;
}

.project-group-work-section .first-row-inner-box ul.dropdown-menu li,
.filter-by-team-btn ul.dropdown-menu li {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-bottom: 2px;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  padding: 0px 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
}
.project-group-work-section .first-row-inner-box ul.dropdown-menu li:hover,
.filter-by-team-btn ul.dropdown-menu li:hover,
.filter-by-team-btn ul.dropdown-menu li:focus a,
.project-group-work-section .first-row-inner-box ul.dropdown-menu li:focus a {
  background-color: rgba(157, 197, 132, 0.12);
  color: rgb(75, 200, 0);
}

.filter-by-button-dropdown-wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  font-size: 14px;
}

.filter-by-button-dropdown-wrap .filter-search-field-wrap {
  padding: 15px 15px 10px;
}

.filter-by-button-dropdown-wrap .filter-search-field-wrap .team-search-contain {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 310px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
  border-image: initial;
  border-radius: 8px;
}

.filter-by-button-dropdown-wrap
  .filter-search-field-wrap
  .team-search-contain
  svg {
  flex: 0 0 auto;
  margin: 0px 8px;
}

.filter-by-button-dropdown-wrap .team-search-contain input.team-search-field {
  line-height: normal !important;
  border: 0;
  height: 100%;
  background-color: transparent !important;
  font-size: 14px !important;
  width: 100% !important;
  color: rgb(0, 0, 0) !important;
  flex: 1 1 auto;
  outline: none !important;
  padding: 0px !important;
}
.filter-by-button-dropdown-wrap .team-list-contain-wrap {
  background: rgb(255, 255, 255);
  border-radius: 8px;
}
.filter-by-button-dropdown-wrap .team-list-contain-wrap .team-list-inner-box {
  position: relative;
}
.filter-by-button-dropdown-wrap .team-list-contain-wrap .top-lebel-wrap {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  user-select: none;
  padding: 5px calc(15px) 10px;
}
.filter-by-button-dropdown-wrap
  .team-list-contain-wrap
  .top-lebel-wrap
  span.label-text {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(123, 123, 123);
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.filter-by-button-dropdown-wrap .team-list-contain-wrap .top-lebel-wrap a {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-right: 10px;
  color: rgb(75, 200, 0);
}
.filter-by-button-dropdown-wrap .filter-by-check-wrap {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: calc(100% - 5px);
  margin-left: 5px;
  padding: 0px 10px;
  border-radius: 8px;
}
.filter-by-button-dropdown-wrap .filter-by-check-wrap .filter-check-cont {
  position: relative;
  display: inline-block;
  width: 14px;
  min-width: 14px;
  height: 14px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  margin-right: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
  outline: none !important;
}
.filter-by-button-dropdown-wrap
  .filter-by-check-wrap
  .filter-check-cont
  input[type="checkbox"] {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: inherit;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.filter-by-button-dropdown-wrap .team-name-filter-by {
  height: 100%;
  min-width: 0px;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: inherit;
  flex: 1 1 auto;
  overflow: hidden;
}

.filter-by-button-dropdown-wrap .pop-inner-dropdown_container {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-top: 1px solid #ececec;
  line-height: 40px;
  padding: 0 15px;
  margin-right: -1px;
  border-right: 1px solid #ececec;
  border-bottom-right-radius: 8px;
}

.filter-by-button-dropdown-wrap .popdropdown-inner-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.filter-by-button-dropdown-wrap .popdropdown-inner-text:hover .dropdown-menu {
  display: block;
}
.filter-by-button-dropdown-wrap .popdropdown-button {
  margin-right: 6px;
}
.reports-right-item-cont .create-new-tag-btn button {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
  height: 36px;
  cursor: pointer;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: rgb(75, 200, 0);
  padding: 0px 16px 0px 12px;
  border-radius: 8px;
  outline: none;
  border: 0px;
}
.reports-right-item-cont .create-new-tag-btn button:hover {
  background-color: rgb(71, 190, 0);
}
.reports-right-item-cont .create-new-tag-btn button svg {
  margin-right: 5px;
}
.reports-header-contain .find-tag-srch-field-headr {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  width: 200px;
  height: 36px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
  border-image: initial;
  border-radius: 8px;
  margin-left: 24px;
}
.reports-header-contain .find-tag-srch-field-headr svg {
  flex: 0 0 auto;
  margin: 0px 8px;
}
.reports-header-contain .find-tag-srch-field-headr input[type="text"] {
  height: 100%;
  background-color: transparent !important;
  border: 0px;
}
.reports-header-items {
  cursor: pointer;
}
.in-tags-panel-row-first {
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  height: 58px;
  width: 100%;
}
.in-tags-panel-row-first .row-inner-content {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
  line-height: 58px;
  padding-left: 20px;
}
.tags-button-box {
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 32px;
  max-width: 200px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  margin: 8px 0px 8px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(206, 206, 206);
  border-image: initial;
  border-radius: 5px;
  font-weight: 500;
}
.tags-button-box .tag-content-wrap {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  min-width: 0px;
  max-width: 100%;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 13px;
  color: rgb(91, 91, 91);
  flex: 0 1 auto;
}

.tags-button-box .tag-content-wrap span.tag-content {
  max-width: 200px;
  text-overflow: ellipsis;
  visibility: visible;
  white-space: pre;
  padding: 7px;
  overflow: hidden;
}
.tags-button-box .more-option-wrap {
  display: flex;
  width: 6px;
  margin-right: 7px;
}
.tags-button-box .more-option-wrap .more-option-btn {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 8px;
}
.tags-button-box:hover {
  background-color: rgb(243, 243, 243);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
}

.add-New-tag-popup-box-container {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  width: 360px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
}
.add-New-tag-popup-box-container .close-popup-btn {
  box-sizing: content-box;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  padding: 15px;
}
.add-New-tag-popup-box-container .popup-title h3 {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  white-space: pre-wrap;
  margin-bottom: 10px;
}
.add-New-tag-popup-box-container .popup-inner-input-field {
  margin: 10px 0px 30px;
}
.add-New-tag-popup-box-container .popup-inner-input-field input {
  width: 100%;
  height: 36px;
  padding: 10px 12px;
  outline: none;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  transition: color 0.5s;
  background-color: initial;
  box-shadow: none;
}
.add-New-tag-popup-box-container .popup-inner-input-field span {
  display: block;
  padding: 4px 0 0 4px;
  font-size: 10px;
  color: #e20505;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.add-New-tag-popup-box-container .popup-full-btn button {
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: rgb(75, 200, 0);
  box-shadow: none;
  height: 36px;
  width: 100%;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-radius: 8px;
  outline: none;
}
.add-New-tag-popup-box-container .popup-full-btn button:hover {
  background-color: rgb(71, 190, 0);
}
.add-New-tag-popup-box-container .close-popup-btn:hover svg path {
  stroke: rgb(0, 0, 0);
}
.add-New-tag-popup-box-container .close-popup-btn svg path {
  stroke: rgb(123, 123, 123);
}
.close-popup-btn .close {
  font-size: 14px;
  cursor: pointer;
}

.display-block {
  visibility: visible !important;
}
.Insights-content-wrapper {
  top: 150px;
  width: 360px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  position: relative;
  text-align: center;
  margin: 0px auto;
}
.Insights-content-wrapper img {
  height: 150px;
  margin-bottom: 20px;
}
.Insights-content-wrapper h1.main-title {
  color: rgb(0, 0, 0);
  font-size: 24px;
  line-height: 1.64;
  font-weight: 300;
  white-space: pre-wrap;
  margin-bottom: 10px;
}
.Insights-content-wrapper .insight-subtitle {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  /* white-space: pre-wrap; */
  margin-bottom: 10px;
}
.Insights-content-wrapper .insight-upgrade-info {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
  color: rgb(111, 111, 111);
  line-height: 1.5;
}
.Insights-content-wrapper .insight-upgrade-info a {
  color: rgb(76, 198, 0);
  cursor: pointer;
  text-decoration: underline !important;
}
.Insights-content-wrapper button.insights-upgrade-btn-contain {
  display: inline-block;
  position: relative;
  padding: 10px 18px;
  border: none;
  border-radius: 8px;
  background: #4bc800;
  outline: none;
  cursor: pointer;
  transition: all 0.1s;
}
.Insights-content-wrapper button .upgrade-btn-content {
  transition: margin 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin-right: 0;
  display: inline-block;
  color: #fff;
  font-weight: 500;
}
.Insights-content-wrapper button.insights-upgrade-btn-contain:hover,
.Insights-content-wrapper button.insights-upgrade-btn-contain:active {
  background: #47be00;
  color: #fff;
}

.group-inner-box-second-row
  .inner-team-active-info-row
  .group-inner-main-wrap:nth-child(2),
.group-inner-box-second-row.inner-team-first-row
  .group-inner-main-wrap:nth-child(2) {
  width: calc(22% - 0px) !important;
}
.group-inner-box-second-row.inner-team-first-row .group-inner-main-wrap {
  width: calc(24% - 0px) !important;
}
.group-inner-box-second-row .inner-team-active-info-row .group-inner-main-wrap {
  width: calc(24% - 0px) !important;
}
.group-inner-box-second-row
  .inner-team-active-info-row
  .group-inner-main-wrap:nth-child(5) {
  width: calc(2% - 0px) !important;
}
.team-search-contain:focus-within,
.find-tag-srch-field-headr:focus-within {
  background: rgba(177, 177, 177, 0.133) !important;
}
.team-search-contain:focus-within svg path,
.find-tag-srch-field-headr:focus-within svg path {
  fill: rgb(75, 200, 0) !important;
}
.inner-team-first-row .group-inner-main-wrap .group-by-title-wrap {
  font-weight: 600;
  vertical-align: middle;
  color: rgb(126, 138, 150);
}
.most-active-user-detail-wrap {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: 20%;
  max-width: 245px;
  flex-flow: row nowrap;
  padding: 0px 20px 0px 0px;
}
.most-active-user-detail-wrap .short-name-circle-wrap {
  margin-right: 10px;
}
.short-name-circle-wrap .user-short-circle {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  background-size: contain;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
}
.most-active-user-detail-wrap .usernem-and-time-contain {
  min-width: 0px;
  font-size: 14px;
  color: #222;
}
.most-active-user-detail-wrap
  .usernem-and-time-contain
  .most-spending-username {
  position: relative;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}
.most-active-user-detail-wrap
  .usernem-and-time-contain
  span.time-dur-counter-wrap {
  font-size: 12px;
  margin-top: 3px;
}
.group-inner-main-wrap .project-show-more-btn {
  visibility: hidden;
}
.group-by-project-main-container.project-info-panel:hover
  .project-show-more-btn {
  visibility: visible;
}
.project-info-panel .group-inner-main-wrap .group-by-title-wrap {
  color: #000;
}
.bulk-edit-contain .projects-bulk-edit-items {
  font-size: 14px;
  font-weight: normal;
  color: rgb(123, 123, 123);
  padding: 0px 12px;
}
svg.form-icon--eye path,
svg.form-icon--eye circle {
  stroke: #252729;
}
.input-field-width-color-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-field-width-color-wrap .color-picker-circle-btn {
  flex: 1 0 0px;
  height: 30px;
  border-radius: 100%;
  margin: 15px 0px 0px 10px;
}
.input-field-width-color-wrap .color-picker-circle-btn a.color-pick-circle {
  padding: 10px;
  height: 25px;
  width: 25px;
  display: block;
  border-radius: 100%;
  background: red;
  cursor: pointer;
  position: relative;
}
.input-field-width-color-wrap
  .color-picker-circle-btn
  a.color-pick-circle:hover {
  box-shadow: 0px 0px 0px 4px rgba(219, 219, 219, 1);
}
.color-picker-circle-btn .color-option-contain {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;
  padding: 15px;
  outline: none;
  transform: translate3d(-120px, 8px, 0px) !important;
}
.color-picker-circle-btn .color-option-contain ul.pick-color-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  margin: 0;
}
.color-option-contain ul.pick-color-wrapper li.pick-color-item {
  padding: 5px;
  background: red;
  margin: 1px;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}
.color-option-contain ul.pick-color-wrapper li.pick-color-item:hover {
  opacity: 0.8;
}
.popup-select-workspace .project-popup-choose-workspace {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px 12px;
  line-height: normal;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  color: #7b7b7b;
  cursor: pointer;
  max-height: 38px;
  margin-bottom: 10px;
}
.popup-select-workspace .project-popup-choose-workspace span.workspace-info {
  color: #000000;
}
.popup-select-workspace .project-popup-choose-workspace i {
  font-size: 13px;
}
.popup-select-workspace .workispace-porject-list-contain ul {
  padding: 10px;
}
.popup-select-workspace
  .workispace-porject-list-contain
  ul.workspace-select-list
  li,
.workispace-porject-list-contain .none-client-contain {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  background: hsla(0, 0%, 69.4%, 0.135);
  margin-bottom: 10px;
}
.popup-select-workspace
  .workispace-porject-list-contain
  ul.workspace-select-list
  li:hover {
  background: hsla(0, 0%, 69.4%, 0.11);
}
.workispace-porject-list-contain ul.workspace-select-list li.selected-workspace,
.workispace-porject-list-contain .none-client-contain {
  background: rgba(74, 199, 0, 0.08);
}
.workispace-porject-list-contain .none-client-contain {
  margin-left: 15px;
  margin-right: 15px;
}
.workispace-porject-list-contain
  ul.workspace-select-list
  li.selected-workspace:hover,
.workispace-porject-list-contain .none-client-contain:hover {
  background: rgba(74, 199, 0, 0.04);
}
.workispace-porject-list-contain
  ul.workspace-select-list
  li.selected-workspace
  a,
.workispace-porject-list-contain .none-client-contain span {
  color: #4bc800 !important;
}
.workispace-porject-list-contain ul.client-list-in-popup li {
  background: transparent !important;
}
.workispace-porject-list-contain ul.client-list-in-popup li a {
  color: #000;
}
.workispace-porject-list-contain ul.client-list-in-popup li:hover {
  background: hsla(0, 0%, 69.4%, 0.135) !important;
}
.popup-select-workspace .create-project-in-serchbox {
  border-top: 1px solid #ececec;
  text-align: center;
  margin-top: 10px;
  height: 30px;
  line-height: 40px;
  cursor: pointer;
}
.popup-select-workspace .create-project-in-serchbox a > i {
  margin-right: 10px;
  color: #4bc800;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch label {
  cursor: pointer;
}
.toggle-switch label .toggle-track {
  display: block;
  height: 2px;
  width: 30px;
  background: #cecece;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}
.toggle-switch .toggle-track:before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  background: #41af00;
  border-radius: 20px;
  position: absolute;
  top: -7px;
  right: -3px;
  transition: right 0.2s ease-in;
}
.toggle-switch input[type="checkbox"]:checked + label .toggle-track:before {
  background: #7b7b7b;
  right: 21px;
}

span.workspace-info span.info-about-project {
  color: #adadad;
  margin-left: 9px;
  cursor: pointer;
}
span.workspace-info span.info-about-project:hover {
  color: #000;
}
.pick-color-wrapper li.pick-color-item.selected-color:after {
  position: absolute;
  content: "\2713";
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.44);
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 100%;
  line-height: 25px;
}
.group-inner-box-second-row
  .inner-team-active-info-row
  .group-inner-main-wrap:nth-child(1)
  .group-by-title-wrap {
  margin-left: 11px;
}

.custom-checkbox-wrap {
  margin: 9px 10px 0px -3px;
}
.custom-checkboxes {
  width: 14px;
  height: 14px;
  display: inline-block;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  background-size: 100%;
  top: 3px;
  background: url("../images/checkbox.png") no-repeat;
}
.custom-checkboxes:hover {
  background: url("../images/checkbox-hover.png") no-repeat;
  background-size: 100%;
}
.custom-checkboxes.selected {
  background: url("../images/checkbox-selected.png") no-repeat;
  background-size: 100%;
}

.custom-checkboxes input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _nofocusline: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

label.signup-select-location {
  font-weight: 700;
  font-size: 11px;
  color: #444;
}
p.invite-member-info {
  line-height: 1.38;
  font-size: 13px;
  color: rgb(163, 163, 163);
  font-weight: normal;
  margin-bottom: 33px;
}
p.invite-member-info a.invite-upgrade-link {
  color: #4bc800;
  text-decoration: underline !important;
}

/*setting page custom style*/
.setting-top-workspace-set-container .setting-workspace-logo-box {
  flex: 3 0 0;
}
.setting-top-workspace-set-container .right-setting-workspace-info-box {
  flex: 9;
}
.setting-workspace-logo-box .left-logo-inner-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 220px;
  max-height: 220px;
  padding: 57px;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(243, 243, 243);
  background-color: rgb(255, 255, 255);
}
.setting-workspace-logo-box .left-logo-inner-box span {
  margin-top: 13px;
  color: rgb(206, 206, 206);
}
.setting-top-workspace-set-container {
  padding: 20px 40px;
}
.right-setting-workspace-info-box .workspace-inner-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.workspace-inner-info .workspace-free-badge-contain label {
  display: block;
}
.workspace-inner-info .workspace-free-badge-contain label {
  display: block;
}
.right-setting-workspace-info-box .workspace-inner-info label {
  color: rgb(123, 123, 123);
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 10px;
}
.right-setting-workspace-info-box .workspace-free-badge-contain span.free-info {
  line-height: normal;
  font-weight: 400;
  color: rgb(123, 123, 123);
  margin-left: 10px;
  font-size: 12px !important;
  padding-top: 3px;
}
.right-setting-workspace-info-box .workspace-free-badge-contain span > a {
  color: rgb(76, 198, 0);
  cursor: pointer;
  text-decoration: underline !important;
}
.workspace-inner-info .put-workspace-field-contain input[type="text"] {
  background-color: #fff;
  font-family: Roboto, Arial, sans-serif;
  line-height: normal !important;
  color: #222;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  padding: 9px 11px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(227, 228, 228);
  min-width: 270px;
}
.workspace-inner-info .put-workspace-field-contain {
  margin-bottom: 10px;
}
.workspace-free-badge-contain span.free-badge {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: rgb(206, 206, 206);
  padding: 1px 5px;
  border-radius: 6px;
}

/*==========custom radion button style start from here=============*/
.setting-block-info-contain .select-who-can-contain [type="radio"]:checked,
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:checked
  + label,
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:not(:checked)
  + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:checked
  + label:before,
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:checked
  + label:after,
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:not(:checked)
  + label:after {
  content: "";
  width: 11px;
  height: 11px;
  background: #4cc600;
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:not(:checked)
  + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.setting-block-info-contain
  .select-who-can-contain
  [type="radio"]:checked
  + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
/*==========custom radion button style end here=============*/
section.setting-blocks-section-contain {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  border-radius: 8px;
  padding: 0px 20px;
  margin-bottom: 20px;
}
section.setting-blocks-section-contain .setting-block-header-contain {
  justify-content: space-between;
  -webkit-box-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 0px;
  border-bottom: 1px solid rgb(243, 243, 243);
}
.setting-block-header-contain .header-heading span {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  white-space: pre-wrap;
}
.setting-block-header-contain .header-description span {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(123, 123, 123);
  line-height: 1.57;
}
.setting-blocks-section-contain .setting-block-info-contain {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 15px;
  row-gap: 20px;
  padding: 20px 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
}
.setting-blocks-section-contain
  .setting-block-info-contain
  .info-inner-blocks
  .block-title {
  margin-bottom: 10px;
}
.setting-blocks-section-contain
  .setting-block-info-contain
  .info-inner-blocks
  .block-title
  h5 {
  color: rgb(123, 123, 123);
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.setting-block-info-contain .info-inner-blocks .select-who-can-contain p {
  margin: 0px;
}
.setting-sections-wrapper {
  padding: 0px 20px;
}
section.setting-blocks-section-contain .toggle-list-contain {
  display: flex;
  align-items: center;
}
section.setting-blocks-section-contain .toggle-list-contain .toggle-btn-box {
  padding-right: 20px;
  flex: 1;
}
section.setting-blocks-section-contain
  .toggle-list-contain
  .toggle-btn-info-box {
  padding: 20px 0px;
  flex: 25;
  margin-right: 100px;
}
section.setting-blocks-section-contain
  .toggle-list-contain
  .toggle-btn-info-box
  .header-heading {
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  color: rgb(163, 163, 163);
}
.in-setting-user-short-contain span.short-name {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  background-size: contain;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: rgb(160, 26, 165);
  border-radius: 50%;
}
.toggle-btn-box.in-setting-user-short-contain {
  display: flex;
  align-items: center;
  flex: 7;
}
.toggle-btn-box.in-setting-user-short-contain span.user-full-name {
  margin-left: 10px;
  color: #666;
  font-weight: 600;
}
.setting-bottom-hr-section {
  color: rgb(123, 123, 123);
  font-size: 13px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.57;
  margin: 50px 0px 70px 0px;
}
.setting-bottom-hr-section .horizontal-line {
  width: 200px;
  height: 1px;
  background-color: rgb(230, 230, 230);
  margin-bottom: 30px;
}
.setting-bottom-hr-section span.need-help-bottom-content {
  text-align: center;
}
.setting-bottom-hr-section span.need-help-bottom-content span > a {
  font-weight: 500;
  color: #61b500;
}
.alert-new-disable .create-new-tag-btn button {
  color: rgb(206, 206, 206);
  background-color: rgba(177, 177, 177, 0.07);
}
.alert-new-disable .create-new-tag-btn button:hover {
  color: rgb(206, 206, 206);
  background-color: rgba(177, 177, 177, 0.07);
}

.alert-new-disable .create-new-tag-btn button svg path {
  fill: rgb(212, 212, 212);
}

/*integration page custom style form her*/
section.integrations-items-container {
  background-color: #e9e9e9;
  box-shadow: 0 2px 6px 0 #d6d6d6 inset !important;
  padding: 40px;
  margin-bottom: 20px;
  position: relative;
}
section.integrations-items-container .integration-brand-title-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
section.integrations-items-container .integration-brand-title-wrap img {
  margin-right: 20px;
}
section.integrations-items-container
  .integration-brand-title-wrap
  .repo-path-breadcrumbn {
  font-size: 14.4px;
  font-weight: 500;
  margin-right: 20px;
  color: #222;
}
section.integrations-items-container .integration-brand-title-wrap a {
  font-size: 14.4px;
  font-weight: 600;
  color: #2cc1e6;
  text-decoration: underline !important;
}
section.integrations-items-container .integration-config-row {
  padding: 25px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.integrations-items-container
  .integration-config-row
  .inte-info-inner
  p {
  /* display: flex; */
  margin: 0px;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.cancel-btn {
  color: #595959;
  font-weight: 600;
  font-size: 14.4px;
  background-color: #fff;
  line-height: normal;
  /* margin: 0 0 1.25rem; */
  /* margin-right: 15px; */
  padding: 12px 24px;
  border: 0px;
  cursor: pointer;
  outline: 0px;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.cancel-btn
  i {
  font-size: 18px;
  margin-right: 11px;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.cancel-btn:hover,
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.cancel-btn:focus {
  background-color: #e6e6e6;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.enable-btn {
  margin: 0px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 14.4px;
  color: #fff;
  background-color: #4bc800;
  padding: 12px 24px;
  border: 0px;
  outline: 0px;
  cursor: pointger;
  cursor: pointer;
  text-align: center;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.enable-btn
  i {
  font-size: 18px;
  margin-right: 11px;
  vertical-align: middle;
}
section.integrations-items-container
  .integration-config-row
  .integration-enable-action-btn-wrap
  button.enable-btn:hover {
  background: #48bd01;
}

.setting-blocks-section-contain .import-data-top-head {
  padding: 5px 10px;
}
.setting-blocks-section-contain .import-data-top-head .import-inner-title {
  font-family: Roboto, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  line-height: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 5px;
}
.setting-blocks-section-contain .import-data-top-head .import-info-descri {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}
section.setting-blocks-section-contain .import-how-works-info-contain {
  line-height: 20px;
  max-width: 700px;
  padding: 10px;
}
section.setting-blocks-section-contain .import-how-works-info-contain p {
  margin: 0px;
}
section.setting-blocks-section-contain
  .import-how-works-info-contain
  p.how-works-title {
  font-weight: 500;
}
section.setting-blocks-section-contain .import-how-works-info-contain p {
  color: #222;
}
section.setting-blocks-section-contain .import-how-works-info-contain p a {
  color: rgb(76, 198, 0);
  text-decoration: underline !important;
}

.import-file-field-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  cursor: pointer;
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
  border-color: rgb(206, 206, 206);
  margin-top: 20px;
  position: relative;
}
.import-file-field-container input.csv-file-upload-field {
  display: none;
}
.import-file-field-container .custom-file-upload-wrap {
  display: flex;
  align-items: center;
}
.import-file-field-container .custom-file-upload-wrap svg {
  pointer-events: auto;
}
.import-file-field-container .custom-file-upload-wrap .upolad-file-type-drag {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  height: 72px;
  min-width: 200px;
  padding: 0px 20px;
}
.import-file-field-container
  .custom-file-upload-wrap
  .upolad-file-type-drag
  p.select-csv-file {
  color: rgb(123, 123, 123);
  padding-bottom: 5px;
  pointer-events: auto;
  margin: 0;
}
.import-file-field-container
  .custom-file-upload-wrap
  .upolad-file-type-drag
  p.drag-file-here {
  color: rgb(163, 163, 163);
  margin: 0;
}
.import-file-field-container:hover {
  border-color: rgb(123, 123, 123);
}
.import-file-field-container:hover .custom-file-upload-wrap svg path {
  fill: rgb(123, 123, 123);
}

.import-file-field-container:active .custom-file-upload-wrap svg path {
  fill: rgb(75, 200, 0);
}
.import-file-field-container:active {
  border-color: rgb(75, 200, 0);
}
.import-file-field-container:active
  .custom-file-upload-wrap
  .upolad-file-type-drag
  p.select-csv-file {
  color: rgb(75, 200, 0);
}

.left-logo-inner-box.profile-picture-select-box {
  border-radius: 50%;
  padding: 57px 57px;
  height: 250px;
  line-height: normal;
  position: relative;
  cursor: pointer;
}
.left-logo-inner-box.profile-picture-select-box svg.profile-picture-ellipse {
  position: absolute;
  pointer-events: none;
  overflow: visible;
  top: 0;
}

.left-logo-inner-box.profile-picture-select-box
  svg.profile-picture-ellipse
  ellipse {
  stroke-dasharray: 4;
  fill-opacity: 0;
  stroke-width: 1;
  stroke: rgb(206, 206, 206);
}
.left-logo-inner-box.profile-picture-select-box span {
  margin: 3px 0px;
  line-height: 13px;
}
.left-logo-inner-box.profile-picture-select-box:hover
  svg.profile-picture-ellipse
  ellipse {
  stroke: rgb(123, 123, 123);
}
.left-logo-inner-box.profile-picture-select-box:active
  svg.profile-picture-ellipse
  ellipse {
  stroke: rgb(75, 200, 0);
}
.left-logo-inner-box.profile-picture-select-box:hover span:nth-child(1) {
  color: rgb(123, 123, 123);
}
.left-logo-inner-box.profile-picture-select-box:active span:nth-child(1) {
  color: rgb(75, 200, 0);
}
.left-logo-inner-box.profile-picture-select-box:hover svg path {
  fill: rgb(123, 123, 123);
}
.left-logo-inner-box.profile-picture-select-box:active svg path {
  fill: rgb(75, 200, 0);
}
.export-account-data-contain button {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: transparent;
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  width: 200px;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
}
.export-account-data-contain button:hover {
  box-shadow: none;
  background-color: rgb(243, 243, 243);
}
.export-account-data-contain button:active {
  background-color: rgba(157, 197, 132, 0.12);
  color: rgb(65, 175, 0);
}
.in-profile-select-country-contain {
  max-width: 272px;
}
.in-profile-select-country-contain .project-popup-choose-workspace {
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  cursor: default;
  opacity: 1;
  min-height: 36px;
  border-color: rgb(243, 243, 243);
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}
.in-profile-select-country-contain
  .filter-by-button-dropdown-wrap
  .Teamdropdown_container {
  max-height: 240px;
  overflow: hidden;
  overflow-y: scroll;
}

.in-profile-select-country-contain ul.select-country-profile {
  display: block;
  width: 100%;
}
.in-profile-select-country-contain .filter-by-button-dropdown-wrap {
  min-width: 272px;
}
.in-profile-select-country-contain ul.select-country-profile li {
  cursor: pointer;
  padding: 5px 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border-radius: 8px;
  margin: 2px 8px;
}
.in-profile-select-country-contain ul.select-country-profile li:hover,
.in-profile-select-country-contain ul.select-country-profile li:active {
  background-color: rgb(243, 243, 243);
}
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-country,
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-time-zone,
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-durationDisplay,
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-date-format,
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-time-format,
.in-profile-select-country-contain
  ul.select-country-profile
  li.selected-firstDay {
  background-color: rgb(243, 243, 243);
  color: rgb(65, 175, 0) !important;
}
.setting-block-info-contain.profile-info-contain-email-ref {
  grid-template-columns: 1fr;
}
.setting-block-info-contain.profile-info-contain-email-ref
  .select-who-can-contain
  span {
  margin-right: 10px;
  color: #222;
}
.setting-blocks-section-contain
  .setting-block-info-contain.in-profile-time-date-contain {
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  column-gap: 32px;
}
.in-profile-select-country-contain span.workspace-info {
  color: #222;
}
.sign-in-with-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  -webkit-box-pack: start;
  justify-content: start;
  margin: -23px 0px 0px;
}
.sign-in-with-wrapper .in-profile-sign-in-google-contain {
  width: 220px;
  height: 70px;
  position: relative;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 1px 0px,
    rgba(0, 0, 0, 0.13) 0px 0px 1px 0px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 232, 232);
  border-image: initial;
  margin: 5px;
  padding: 10px 15px;
}
.sign-in-with-wrapper .in-profile-sign-in-google-contain .sign-in-content {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  white-space: pre-wrap;
  position: absolute;
  left: 60px;
  top: 15px;
}
.sign-in-with-wrapper .in-profile-sign-in-google-contain .More-option-btn-wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 15px;
  cursor: pointer;
  display: none;
}
.sign-in-with-wrapper
  .in-profile-sign-in-google-contain
  .More-option-btn-wrap
  .more-option-inner {
  height: auto;
  width: 25px;
}
.sign-in-with-wrapper .in-profile-sign-in-google-contain .sign-in-enable-wrap {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.42;
  color: rgb(123, 123, 123);
  position: absolute;
  left: 60px;
  top: 40px;
}
.sign-in-with-wrapper
  .in-profile-sign-in-google-contain
  .sign-in-enable-wrap
  span.mark-sign-in {
  position: relative;
  top: 1px;
  padding: 0px 8px 0px 0px;
  opacity: 0;
}
.in-profile-sign-in-google-contain
  .More-option-btn-wrap
  .more-option-inner:hover
  svg
  path,
.in-profile-sign-in-google-contain
  .More-option-btn-wrap
  .more-option-inner:focus
  svg
  path {
  fill: rgb(123, 123, 123);
}
.sign-in-with-wrapper .in-profile-sign-in-google-contain.active-signIn {
  border-color: rgb(75, 200, 0) !important;
  cursor: pointer;
}
section.beta-tester-section {
  background-color: rgb(248, 205, 106);
  border-radius: 4px;
  margin-bottom: 20px;
}

section.beta-tester-section .yellow-eye-pipe-col-box img {
  object-fit: contain;
  width: 199px;
  margin: 30px;
}

section.beta-tester-section .yellow-eye-pipe-col-box {
  text-align: center;
}

section.beta-tester-section .beta-tester-box-wrap {
  margin-top: 48px;
  margin-bottom: 48px;
  max-width: 443px;
  position: relative;
  background-color: rgb(255, 255, 255);
  /* height: 100%; */
  margin-right: 30px;
  border-radius: 4px;
}

section.beta-tester-section .beta-tester-box-wrap .beta-box-heading {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.64;
  font-weight: 500;
  padding: 20px;
}

section.beta-tester-section .beta-tester-box-wrap .beta-info-box {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 0px 20px;
}

section.beta-tester-section
  .beta-tester-box-wrap
  .beta-info-box
  p.beta-test-description {
  margin: 0px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}
section.beta-tester-section
  .beta-tester-box-wrap
  .beta-tester-action-btns-contain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.beta-tester-box-wrap .beta-tester-action-btns-contain .disable-feautes-btn {
  margin: 30px 30px 20px;
}
.beta-tester-box-wrap
  .beta-tester-action-btns-contain
  .disable-feautes-btn
  button.feaute-btn,
.in-data-compile-filebtn-wrap .compile-file-btn {
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: rgb(75, 200, 0);
  box-shadow: none;
  width: 200px;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
}
.beta-tester-box-wrap
  .beta-tester-action-btns-contain
  .disable-feautes-btn
  button.feaute-btn:hover,
.in-data-compile-filebtn-wrap .compile-file-btn:hover {
  background-color: rgb(67, 178, 0);
}

.beta-tester-box-wrap .beta-tester-action-btns-contain .learn-more-lin a {
  color: rgb(76, 198, 0);
  cursor: pointer;
  text-decoration: underline !important;
  line-height: 33px;
}
.beta-tester-box-wrap .beta-tester-action-btns-contain .learn-more-lin {
  padding-top: 8px;
}
.setting-block-header-contain .reset-link {
  margin-left: auto;
  margin-top: -21px;
}
.setting-block-header-contain .reset-link a {
  color: #4bc800 !important;
  text-decoration: underline !important;
  font-size: 14px;
  font-weight: 500;
}
.profile-api-code-field input[type="text"] {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  box-shadow: none !important;
  border-radius: 8px;
  padding: 9px 11px !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(243, 243, 243) !important;
  border-image: initial !important;
  width: 100%;
  outline: none;
}
.setting-block-info-contain .account-action-btns-wrap {
  display: flex;
  flex-wrap: wrap;
}
.setting-block-info-contain .account-action-btns-wrap .btns {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: transparent;
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  width: 200px;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
  outline: none;
  margin-right: 15px;
}
.setting-block-info-contain .account-action-btns-wrap .btns:active {
  background-color: rgba(157, 197, 132, 0.12);
  color: rgb(65, 175, 0);
}
.setting-block-info-contain .account-action-btns-wrap .btns:hover {
  box-shadow: none;
  background-color: rgb(243, 243, 243);
}
.setting-block-info-contain .account-action-btns-wrap .btns.close-account {
  color: rgb(226, 5, 5);
}

.active-signIn .More-option-btn-wrap {
  display: block !important;
}
.active-signIn .sign-in-enable-wrap span.mark-sign-in {
  opacity: 1 !important;
}
.popup-apireset-descr p {
  color: rgb(0, 0, 0);
  line-height: 1.5;
  margin: 7px 0px 0px;
}
.popup-apireset-descr {
  margin-bottom: 30px;
}
.change-password-form-wrap .change-pass-pop {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.change-password-form-wrap .change-pass-pop input {
  background-color: #fff;
  color: #222;
  display: block;
  font-size: 14px;
  margin: 0;
  padding: 9px 11px;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid rgb(243, 243, 243);
}
.change-password-form-wrap .change-pass-pop input:focus {
  background-color: rgb(250, 250, 250);
  outline: none;
  border-color: rgb(250, 250, 250);
}
.change-password-form-wrap .change-pass-pop span.error-msg-in-ch-ps,
.change-password-form-wrap .change-pass-pop label.error {
  color: rgb(226, 5, 5);
  font-size: 10px;
  font-weight: 400;
  margin: 5px 12px 0px;
}
.left-side-about-user-id {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  cursor: pointer;
  margin: 15px 0px;
  background-color: rgb(73, 73, 73);
}
.left-side-about-user-id .left-sidebar-username {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
}
.left-side-about-user-id .left-sidebar-username span {
  padding: 0px;
  font-size: 12px;
  line-height: 1.3;
  display: block;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.left-side-about-user-id .left-sidebar-username span.user-mail-add {
  color: rgb(159, 159, 159);
}
.left-side-about-user-id:hover .left-sidebar-username span.user-mail-add {
  color: rgb(255, 255, 255);
}

.modal-top-user-id-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.modal-top-user-id-info .about-user-id-name {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 170px;
  flex: 0 0 auto;
}
.modal-top-user-id-info .about-user-id-name span {
  color: #222;
  line-height: 1.4;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.modal-top-user-id-info .about-user-id-name span.user-mail-add {
  color: rgb(123, 123, 123);
  font-size: 12px;
}
.modal-top-user-id-info span.user-shortname-left-sidebar {
  width: 40px;
  height: 40px;
  background: #f30000;
  position: relative;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  border-radius: 50%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-width: 26px;
}
.user-profile-modal .modal-main-body-content {
  display: flex;
  flex-direction: column;
}
.user-profile-modal .modal-main-body-content a {
  line-height: 30px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 0px 10px;
  margin-bottom: 2px;
  border-radius: 8px;
}

.user-profile-modal .modal-main-body-content a:hover,
.user-profile-modal .modal-main-body-content a:active,
.user-profile-modal .in-modal-logout-wrap a:hover,
.user-profile-modal .in-modal-logout-wrap a:active {
  background-color: rgba(177, 177, 177, 0.133);
  outline: 0px;
}
.user-profile-modal .in-modal-logout-wrap a {
  cursor: pointer;
  color: rgb(0, 0, 0);
  display: block;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  font: inherit inherit inherit inherit inherit inherit inherit;
  padding: 5px 10px;
  border-radius: 8px;
}
.user-profile-modal .in-modal-logout-wrap {
  border-top: 1px solid #ececec;
  margin-top: 10px;
  padding-top: 6px;
}
.subscrip-page-heading {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 28px;
  text-align: center;
  padding-top: 50px;
}
.subscr-trial-sub-heading {
  color: rgb(123, 123, 123);
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 19px;
  text-align: center;
}
.subscription-main-wrapper .billing-option-contain {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 25px 0px 25px;
}
.billing-option-contain .monthly-bill-inner {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  width: 100px;
  text-align: center;
  margin: 0px 15px;
}
.billing-option-contain .monthly-bill-inner.active-billing {
  font-weight: 600;
}

.billing-plan-cards-container {
  display: flex;
  justify-content: space-evenly;
  width: 1020px;
  margin: 10px auto 0px;
}

.billing-card-main {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  width: 230px;
  min-height: 483px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.billing-card-main .billing-card-intro-wrap {
  display: flex;
  align-content: space-between;
  min-height: 200px;
  flex-flow: row wrap;
  padding: 20px 0px;
  border-bottom: 1px solid rgb(243, 243, 243);
}

.billing-card-main.enterpse-card {
  border-top: 10px solid rgb(234, 70, 141);
}
.billing-card-main.premium-card {
  border-top: 10px solid rgb(106, 204, 249);
}
.billing-card-main.starter-card {
  border-top: 10px solid rgb(241, 195, 63);
}
.billing-card-main.free-card {
  border-top: 10px solid rgb(206, 206, 206);
}

.billing-card-main .billing-card-intro-wrap .card-title h5 {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  /* white-space: pre-wrap; */
  line-height: 16px;
}

.billing-card-intro-wrap .billing-card-short-intro p {
  margin: 0px;
  font-size: 13px;
  color: rgb(0, 0, 0);
  line-height: 1.5;
}

.billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
  position: relative;
  padding: 10px 18px;
  border: none;
  border-radius: 8px;
  background: #4bc800;
  outline: none;
  cursor: pointer;
  transition: all 0.1s;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  width: 191px;
  display: block;
}
.billing-card-intro-wrap .card-title {
  width: 100%;
  padding: 0px 20px 0px;
}
.billing-card-intro-wrap .billing-card-short-intro {
  padding: 10px 20px 0px;
}
.billing-card-intro-wrap .card-inner-btn {
  padding: 15px 15px 0px;
}
.billing-card-intro-wrap .plan-price {
  padding: 10px 20px 0px;
  display: flex;
  flex-direction: column;
}
.billing-card-intro-wrap .plan-price span.price-text {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 300;
  white-space: pre-wrap;
  line-height: 28px;
}
.billing-card-intro-wrap .plan-price span.price-condition {
  color: rgb(123, 123, 123);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 3px;
  line-height: 14px;
}
.billing-card-intro-wrap .plan-special-offer {
  padding: 10px 20px 0px;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: rgb(75, 200, 0);
  height: 24px;
}
.billing-card-main .billing-card-content-body {
  padding: 20px 0px;
}
.billing-card-main .billing-card-content-body .billing-descript-heading {
  width: 100%;
  padding: 0px 20px 10px;
  color: rgb(123, 123, 123);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.27;
  letter-spacing: 0.3px;
}
.billing-card-main
  .billing-card-content-body
  ul.biiling-plan-features-contain
  li {
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.billing-card-main
  .billing-card-content-body
  ul.biiling-plan-features-contain
  li
  span {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 400;
  margin-left: 11px;
  line-height: 1.5;
}
.billing-card-main
  .billing-card-content-body
  ul.biiling-plan-features-contain
  li:not(:first-child) {
  margin-top: 10px;
}
.card-inner-btn button.billing-card-btn.current-plan {
  color: #7b7b7b;
  background: hsla(0, 0%, 69.4%, 0.07);
  border: 1px solid transparent;
  cursor: default;
}
.starter-card .billing-card-intro-wrap .plan-price {
  margin-bottom: 23.5px;
}
.faq-main-content-wrapper {
  width: 940px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px auto;
}

.faq-main-content-wrapper .faq-inner-content-box {
  flex-shrink: 0;
  margin-top: 20px;
  margin-left: 20px;
  width: 300px;
}

.faq-main-content-wrapper .faq-inner-content-box .faq-heading {
  color: rgb(123, 123, 123);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  line-height: 2;
}

.faq-main-content-wrapper .faq-inner-content-box .faq-description {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 12px;
  line-height: 1.5;
}
.faq-main-content-wrapper .faq-inner-content-box .faq-description span a {
  color: rgb(76, 198, 0);
  font-weight: 500;
  text-decoration: underline !important;
}
.faq-main-content-wrapper .faq-inner-content-box .faq-description span a:hover {
  color: rgb(71, 190, 0);
}
.detailed-header-row {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  height: 50px;
  box-shadow: rgb(232, 232, 232) 0px -1px 0px 0px inset;
}
.detailed-header-row .sort-by-time-entry-leftBox {
  display: flex;
  align-items: center;
}
.detailed-header-row .sort-by-time-entry-leftBox .table-header-text-wrapper,
.detailed-header-row .right-sortable-items-box .table-header-text-wrapper {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 11px !important;
  font-weight: 600;
  vertical-align: middle;
  color: #7e8a96;
  cursor: default;
  text-transform: uppercase;
  font-weight: 500;
}
.detailed-header-row .table-header-text-wrapper .sort-icon-wrap {
  line-height: 0px;
  margin-left: 5px;
}
.detailed-header-row
  .table-header-text-wrapper
  .sort-icon-wrap
  .upper-carret-wrap,
.detailed-header-row
  .table-header-text-wrapper
  .sort-icon-wrap
  .down-carret-wrap {
  font-size: 11px;
}
.detailed-header-row
  .table-header-text-wrapper
  .sort-icon-wrap
  .upper-carret-wrap {
  margin-bottom: -5px;
}
.detailed-header-row
  .table-header-text-wrapper
  .sort-icon-wrap
  .down-carret-wrap {
  margin-bottom: -0px;
}
.sort-by-time-entry-leftBox .custom-checkbox-wrap {
  margin-right: 0px;
  margin-left: 6px;
}
.detailed-header-row .right-sortable-items-box {
  display: flex;
  align-items: center;
}
.detailed-header-row .right-sortable-items-box .report-sortheader {
  padding-right: 79px;
}
.inDetailed-time-info {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  text-align: left !important;
}
.inDetailed-time-info span.show-time {
  color: #222;
  letter-spacing: 0.7px;
  display: block;
}
.inDetailed-time-info span.show-date {
  color: #b5b5b5;
  display: block;
  text-align: left;
}
.detail-data-list-row .right-sortable-items-box .report-sortheader {
  padding-right: 0px;
  flex: 1;
  margin: 0;
}
.detail-data-list-row .detailed-header-row .right-sortable-items-box {
  justify-content: flex-start;
  width: 45.5%;
}

.detail-data-list-row .reportDetailed-info {
  display: flex;
  align-items: center;
}

.reportDetailed-info .descri-workspace-inner-box span.folder-icon {
  padding: 6px 6px 2px;
  border-radius: 6px;
  cursor: pointer;
  visibility: hidden;
}
.reportDetailed-info .descri-workspace-inner-box span.folder-icon:hover,
.reportDetailed-info .descri-workspace-inner-box span.folder-icon:focus {
  background: #efefef;
}
.detailed_report-contain.detail-data-list-row:hover
  .descri-workspace-inner-box
  span.folder-icon,
.detailed_report-contain.detail-data-list-row:hover .hover-show {
  visibility: visible;
  opacity: 1;
}
.reportDetailed-info .table-header-text-wrapper .description-field input {
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  border: 0px;
  color: #000;
  text-indent: 8px;
}
.table-header-text-wrapper .in-detailed-duration-input input {
  height: 35px;
  font-size: 14px;
  width: 121px;
  color: #a2a2a2;
  text-indent: 3px;
  border: 0px;
  border-radius: 6px;
  outline: none;
}
.table-header-text-wrapper .in-detailed-duration-input input:focus {
  background: #eaeaea;
  color: #000;
  padding-left: 10px;
}
.tooltip-content {
  position: absolute;
  background: #fff;
  width: auto;
  top: 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}
.editDetailed-popup .dropdown-content-body {
  padding: 5px 15px;
}
.editDetailed-popup .dropdown-content-body .find-tags-field-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 4px 4px;
  border-radius: 8px;
  padding: 0px 10px;
}
.editDetailed-popup .dropdown-content-body .find-tags-field-item:hover {
  background: #f5f5f5;
}
.select-project-inner-popup-wrkspace {
  display: flex;
  justify-content: space-between;
  padding: 6px 15px 10px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 8px;
}

.select-project-inner-popup-wrkspace .wrkspce-content-field {
  color: #7b7b7b;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
.select-project-inner-popup-wrkspace
  .choose-workspace-options
  .dropdown-toggle {
  width: 210px;
  margin-right: 7px;
  font-weight: 400;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.choose-workspace-options .popdropdown-button span {
  line-height: 22px;
}
.workspace-active-status > span {
  background: #c1c1c1;
  padding: 4px;
  margin-right: 8px;
  font-size: 0px;
  border-radius: 100%;
}
.workspace-active-status {
  margin-top: -3px;
}
.workspace-active-status > span.active-workspace {
  background: #4bc800;
}
.editDetailed-popup .filter-by-check-wrap {
  padding: 0px 15px;
  margin: 3px 15px;
  width: auto;
}
.editDetailed-popup .filter-by-check-wrap:hover,
.editDetailed-popup .filter-by-check-wrap:focus {
  background: #f7f7f7;
}
.popup-select-workspace .workspace-info input {
  border: 0px;
}
.memberRole-rights-drop-wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 9px;
}
.memberRole-rights-drop-wrap .rights-list {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  padding: 0px 10px;
  border-radius: 8px;
  font-size: 13px;
  margin: 0px 10px;
  color: #000;
  text-transform: capitalize;
}
.memberRole-rights-drop-wrap .rights-list:hover,
.memberRole-rights-drop-wrap .rights-list:focus {
  background: #ececec;
}
.project-team-role-btn .member-role-btn {
  display: inline;
  padding: 10px 15px;
  border-radius: 10px;
  text-transform: capitalize;
  cursor: pointer;
}
.add-Member-btn button {
  width: auto;
}
.add-Member-btn button svg path {
  stroke: #333;
  fill: #6b6b6b;
}
.in-weekly-dataList-row .weekly-detailed-first-column {
  width: calc(25% - 20px) !important;
}
.group-box-second-inner.in-weekly-dataList-row .group-inner-main-wrap {
  width: calc(9.35% - 0px);
}
.timer-count-project-filter .meter-hour,
.timer-count-project-filter .meter-sec {
  color: rgb(163, 163, 163);
}
.timer-count-project-filter .meter-minute {
  color: rgb(0, 0, 0);
}
.group-inner-box-second-row.weekly-total-work-row {
  font-weight: 800;
}
.group-inner-box-second-row.weekly-total-work-row
  .weekly-detailed-first-column
  .group-by-title-wrap {
  margin-left: 51px;
}
.weekly-detailed-first-column span.project-disabel:before,
.weekly-detailed-first-column span.project-enable:before {
  position: absolute;
  content: "";
  background: #d6d6d6;
  width: 8px;
  height: 8px;
  top: 2px;
  left: -11px;
  border-radius: 100%;
}
.weekly-detailed-first-column span {
  position: relative;
}
.weekly-detailed-first-column span.project-enable:before {
  background: #00e412;
}
.working-group-area-contain.weekly_work_data_container .weekly_work_data_inner {
  border-radius: 0px;
}
.In_subscription-full-comparision-wrap {
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;
}
.In_subscription-full-comparision-wrap a.subscription-full-compare-link {
  color: rgb(76, 198, 0);
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline !important;
}
.In_subscription-full-comparision-wrap a.subscription-full-compare-link svg {
  margin-left: 6px;
}
.create-new_workspace-container {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(250, 251, 252);
  min-height: 100vh;
  padding-top: 25px;
}
.create-new_workspace-container .new-workspace-top-page-steps {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.new-workspace-top-page-steps .steps-contain {
  display: inline-flex;
}
.new-workspace-top-page-steps .steps-contain .step-item {
  height: 6px;
  width: 6px;
  background-color: rgb(206, 206, 206);
  border-radius: 50%;
}
.new-workspace-top-page-steps .steps-contain .step-item:not(:last-child) {
  margin-right: 20px;
}
.new-workspace-top-page-steps .steps-contain .step-item.active-step {
  background-color: rgb(75, 200, 0);
}
.create-new_workspace-container .create-new-img {
  min-height: 225px;
  margin: 25px 0px 5px;
}
.create-new_workspace-container .In_wrk_create_title {
  color: rgb(0, 0, 0);
  /* white-space: pre-wrap; */
  font-weight: 300;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}
.create-new_workspace-container .create_new_Info_contain {
  font-size: 14px;
  font-weight: normal;
  color: rgb(123, 123, 123);
  text-align: center;
  line-height: 1.57;
}

.create-new_workspace-container .create_new_Info_contain .sub_content {
  width: 300px;
}
.create-new_workspace-container .create_new-wrokspace-form-wrap {
  margin-top: 30px;
  width: 300px;
}
.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  .field-seprator {
  margin-bottom: 20px;
}
.create_new-wrokspace-form-wrap .field-seprator label.create_field_label {
  color: rgb(123, 123, 123);
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  display: block;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}
.create_new-wrokspace-form-wrap .field-seprator .new_workspace_field {
  position: relative;
}
.create_new-wrokspace-form-wrap .field-seprator .new_workspace_field input {
  background-color: #fff;
  color: #222;
  display: block;
  font-size: 14px;
  margin: 0;
  border-radius: 8px;
  width: 100%;
  padding: 9px 11px;
  border: 1px solid rgb(227, 228, 228);
}
.create_new-wrokspace-form-wrap
  .field-seprator
  .new_workspace_field
  input:focus {
  background-color: rgba(177, 177, 177, 0.1);
  border-color: rgb(227, 228, 228) !important;
}
.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  button.create_workspace_btn {
  font-size: 14px;
  line-height: normal;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 36px;
  cursor: pointer;
  font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: rgb(75, 200, 0);
  box-shadow: none;
  margin-top: 20px;
  padding: 0px 12px;
  border-radius: 8px;
  border: 0px;
}
.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  button.create_workspace_btn
  a {
  color: #fff;
}
.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  button.create_workspace_btn:hover,
.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  button.create_workspace_btn:focus {
  background-color: rgb(71, 190, 0);
}
.new-workspace-top-page-steps .back-step-btn {
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  background-color: transparent;
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  width: 80px;
  position: absolute;
  left: 20px;
  padding: 0px 12px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 8px;
}
div.content-wrap#wrapper {
  position: relative;
}
.create-new_workspace-container .new-workspace-top-page-steps .back-step-btn i {
  margin-right: 6px;
  color: #ccc;
}
.new-workspace-top-page-steps .back-step-btn:hover,
.new-workspace-top-page-steps .back-step-btn:active {
  background-color: rgb(243, 243, 243);
}

.create-new_workspace-container
  .create_new-wrokspace-form-wrap
  .skip-step-link
  a,
.create-new_workspace-container
  .create_new-workspace-form-wrap
  .skip-step-link
  button {
  margin-top: 10px;
  color: rgb(65, 175, 0);
  padding: 0px 12px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 36px;
  cursor: pointer;
  border: 0px;
  outline: none;
}
.field-seprator .new_workspace_field label.error {
  color: rgb(226, 5, 5);
  font-size: 10px;
  font-weight: 400;
  margin: 5px 12px 0px;
}
.field-seprator .new_workspace_field input.error {
  border-color: rgb(226, 5, 5);
}
.import-file-field-container input.csv-file-upload-field {
  display: block;
  position: absolute;
  width: 100%;
  left: 50%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.profile-picture-select-box input.selelct-picture {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  vertical-align: middle;
  border-radius: 100%;
  opacity: 0;
}
.Teamdropdown_container .create-project-in-serchbox {
  border-top: 1px solid #ececec;
  text-align: center;
  margin-top: 10px;
  height: 30px;
  line-height: 40px;
  cursor: pointer;
}
.Teamdropdown_container .create-project-in-serchbox i {
  margin-right: 10px;
  color: #4bc800;
}
.in-timer_load-moreBtn-wra {
  min-height: 40px;
  box-sizing: content-box;
  text-align: center;
  margin: 0px 40px;
}
.in-timer_load-moreBtn-wra button.load-more {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
  height: 36px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  box-shadow: rgb(230, 230, 230) 0px 0px 0px 1px inset;
  background-color: rgb(255, 255, 255);
  outline: none;
  padding: 0px 20px;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 8px;
}
.delete-btn-top-header {
  display: flex;
  margin-right: 4px;
  margin-left: 9px;
}

.delete-btn-top-header .delete-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 4px;
  margin-left: 12px;
}
.delete-btn-top-header .delete-btn svg {
  width: 15px;
  height: 16px;
  transform: scale(1.2);
  cursor: pointer;
  opacity: 0.8;
}
.delete-btn-top-header .delete-btn svg:hover {
  opacity: 1;
}
.in-panel-play-btn {
  cursor: pointer;
}

.top-header-calendar .datepicker-inline {
  position: relative !important;
  box-shadow: unset;
  border-radius: 0px;
  border-top: 1px solid #ececec;
  margin-bottom: 0px;
  border-bottom: 0px;
}
.top-header-calendar {
  left: unset !important;
  transform: translate3d(-0px, 40px, 0px) !important;
  transition: opacity 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}
.Indropdown-dateTime-show-wrap {
  display: flex;
  height: 80px;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 25px;
  padding-right: 15px;
}
.Indropdown-dateTime-show-wrap .time-start-box {
  width: 50%;
  position: relative;
}
.Indropdown-dateTime-show-wrap .time-start-box label {
  font-size: 11px;
  text-transform: uppercase;
  color: #444;
  font-weight: 500;
  letter-spacing: 0.8px;
  display: block;
  margin: 0px;
}
.Indropdown-dateTime-show-wrap .time-start-box .startTimeInputField,
.Indropdown-dateTime-show-wrap .time-start-box .endtimeInputField {
  margin: 0px;
  width: 100%;
  text-indent: 0px;
  font-size: 15px;
  display: block;
  text-align: left;
  color: #333;
  height: 35px;
  margin: 3px 0 15px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 10px 12px;
  line-height: 15px;
}
.Indropdown-dateTime-show-wrap input.showSelectedDate {
  position: absolute;
  bottom: 23px;
  right: 18px;
  color: #6f6f6f;
  text-align: right;
  font-size: 14px;
}
.Indropdown-dateTime-show-wrap .time-start-box:not(:last-child) {
  margin-right: 15px;
}

@media (max-width: 320px) {
  .billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
    width: 185px;
  }
}

@media (max-width: 767px) {
  .reports-header-contain .find-tag-srch-field-headr {
    margin-left: 0px;
    width: 175px;
  }
  .working-group-area-contain.weekly_work_data_container {
    overflow-x: scroll;
  }
  .project-group-work-section.report-work-dataList-container {
    flex-wrap: wrap;
  }
  .report-work-dataList-container .working-group-area-contain {
    overflow-x: scroll;
    margin: 0px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  .report-work-dataList-container
    .working-group-area-contain
    .group-main-inner-box {
    width: 500px;
  }

  .working-group-area-contain.weekly_work_data_container
    .weekly_work_data_inner {
    width: 1000px;
    border-radius: 0px;
  }
  .work-hour-meter-container {
    margin-left: 20px;
  }

  .project-group-work-section.team-member-list-wrapper {
    flex-wrap: wrap;
    margin: 0px;
  }
  .project-group-work-section.team-member-list-wrapper
    .working-group-area-contain {
    margin: 0px;
    margin-bottom: 20px;
  }
  .group-inner-box-second-row
    .group-box-second-inner
    .filter-arrow-cont
    .arrow-top-dur {
    margin-bottom: -3px;
  }
  .user-folder-about-inner-content {
    left: 60px;
    bottom: 150px;
  }
  .user-folder-about-inner-content.user-profile-modal {
    left: 60px;
    bottom: 60px;
  }
  .in-profile-select-country-contain .filter-by-button-dropdown-wrap {
    min-width: auto;
  }
  .in-profile-select-country-contain {
    max-width: 100%;
  }
  section.beta-tester-section
    .beta-tester-box-wrap
    .beta-tester-action-btns-contain {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .setting-block-info-contain .account-action-btns-wrap .btns {
    margin-bottom: 10px;
  }
  .setting-block-info-contain .account-action-btns-wrap {
    justify-content: center;
  }
  section.beta-tester-section .beta-tester-box-wrap {
    margin-right: 0px;
  }
  .setting-blocks-section-contain
    .setting-block-info-contain.in-profile-time-date-contain {
    grid-template-columns: 1fr;
    column-gap: 12px;
  }
  .export-account-data-contain button {
    font-size: 12px;
  }
  section.setting-blocks-section-contain .import-how-works-info-contain {
    width: 100%;
  }
  .import-file-field-container .custom-file-upload-wrap .upolad-file-type-drag {
    min-width: 136px;
    padding: 0px 8px;
  }
  .setting-top-workspace-set-container {
    flex-direction: column;
    padding: 20px 15px;
  }
  .toggle-btn-box.in-setting-user-short-contain {
    margin-right: auto;
    margin-top: 10px;
  }
  .toggle-list-contain.workspace-owenrship {
    flex-direction: column;
  }
  section.setting-blocks-section-contain
    .toggle-list-contain
    .toggle-btn-info-box {
    margin-right: 0px;
  }
  section.integrations-items-container {
    padding: 40px 20px;
  }
  section.integrations-items-container .integration-config-row {
    flex-direction: column;
    padding: 20px 20px;
  }
  .integration-enable-action-btn-wrap {
    margin-top: 20px;
    display: flex;
    min-width: 100%;
  }
  section.integrations-items-container
    .integration-config-row
    .integration-enable-action-btn-wrap
    button.enable-btn {
    margin-left: 10px;
    margin-left: auto;
    padding: 12px 15px;
  }

  .setting-sections-wrapper {
    padding: 0px 5px 0px 15px;
  }
  .setting-blocks-section-contain .setting-block-info-contain {
    grid-template-columns: 1fr;
  }
  .setting-top-workspace-set-container .right-setting-workspace-info-box {
    margin-left: 20px;
    margin-top: 20px;
  }
  .setting-header-contain {
    display: flex;
    flex-wrap: wrap;
  }
  .reports-right-item-cont .create-new-tag-btn button span {
    display: none;
  }
  .reports-right-item-cont .create-new-tag-btn button {
    padding: 0px 0px 0px 4px;
    margin-left: 5px;
  }

  /*it may be removed====*/
  .reports-filter-row .report-filter-item-box .report-filter-inner-item span {
    display: none;
  }
  .reports-filter-row .filter-label {
    font-size: 12px;
  }
  .reports-filter-row {
    padding: 0px;
  }
  .project-group-work-section
    .group-main-inner-box
    .first-row-inner-box
    .first-group-btn-wrap
    button.group-by-btn:first-child,
  .filter-by-team-btn button.group-by-btn {
    font-size: 11px;
  }
  .group-main-inner-box.bulk-edit-contain .reports-filter-row {
    padding: 0px 10px;
  }
  .group-inner-box-second-row
    .group-box-second-inner
    .group-inner-main-wrap
    .group-by-title-wrap {
    font-size: 10px;
  }
  .group-inner-box-second-row .group-box-second-inner .filter-arrow-cont {
    margin-left: 3px;
  }
  .bulk-edit-contain .projects-bulk-edit-items {
    font-size: 11px;
  }
  .bulk-edit-contain .projects-bulk-edit-items:nth-child(1) {
    display: none !important;
  }
  .add-New-tag-popup-box-container {
    width: unset;
  }

  .filter-by-button-dropdown-wrap {
    transform: translate(-50%, -50%) !important;
    top: 0;
    left: 50% !important;
    will-change: transform;
    width: 250px;
  }

  .filter-by-button-dropdown-wrap
    .filter-search-field-wrap
    .team-search-contain {
    width: 100%;
  }

  .workispace-porject-list-contain
    ul.workspace-select-list
    li.selected-workspace,
  .workispace-porject-list-contain .none-client-contain,
  .popup-select-workspace .project-popup-choose-workspace span.workspace-info,
  .popup-select-workspace
    .workispace-porject-list-contain
    ul.workspace-select-list
    li,
  .workispace-porject-list-contain .none-client-contain,
  .popup-select-workspace .project-popup-choose-workspace span.workspace-info {
    font-size: 12px;
  }
  .Insights-content-wrapper {
    width: unset;
    padding: 15px;
  }
  .reports-header-contain h3 {
    font-size: 15px;
    margin-right: 8px;
  }
  .reports-header-contain {
    padding: 0px;
  }
  .working-group-area-contain
    .group-inner-box-second-row
    .group-box-second-inner {
    padding: 0px 8px;
  }
  .group-inner-box-second-row
    .inner-team-active-info-row
    .group-inner-main-wrap:nth-child(1)
    .group-by-title-wrap {
    margin-left: 0px;
  }
  .group-inner-box-second-row
    .inner-team-active-info-row
    .group-inner-main-wrap:nth-child(1)
    .group-by-title-wrap
    svg {
    display: none;
  }
  .group-by-client-name .project-client-name {
    margin-left: 0px !important;
  }
  .reports-header-contain a,
  .filter-by-project .duration-inner.timer-count-project-filter {
    font-size: 12px;
  }
  .inner-project-action-contain
    .action_btns.intimer-dot-option-box
    .option-items,
  .tags-button-box .action_btns.intimer-dot-option-box .option-items,
  .inner-team-active-info-row
    .action_btns.intimer-dot-option-box
    .option-items {
    height: 25px;
    line-height: 27px;
    font-size: 12px;
  }
  .project-group-work-section .first-row-inner-box ul.dropdown-menu li,
  .filter-by-team-btn ul.dropdown-menu li {
    font-size: 12px;
    height: 25px;
  }
  .team-group-inner-page
    .group-inner-box-second-row.inner-team-first-row
    .group-inner-main-wrap:last-child {
    width: calc(4% - 0px);
  }

  .team-group-inner-page
    .group-inner-box-second-row.inner-team-first-row
    .group-inner-main-wrap {
    width: calc(48% - 0px) !important;
  }
  .team-group-inner-page
    .group-inner-box-second-row
    .inner-team-active-info-row
    .group-inner-main-wrap {
    width: calc(48% - 0px) !important;
  }

  .team-group-inner-page
    .group-inner-box-second-row
    .inner-team-active-info-row
    .group-inner-main-wrap:last-child {
    width: calc(4% - 0px) !important;
  }
  .most-active-user-detail-wrap
    .usernem-and-time-contain
    span.time-dur-counter-wrap,
  .most-active-user-detail-wrap .usernem-and-time-contain {
    display: flex;
  }
  .faq-main-content-wrapper .faq-inner-content-box {
    flex-shrink: unset;
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .header-top-start-timer-box {
    display: none;
  }
  .faq-main-content-wrapper {
    width: unset;
  }

  .billing-plan-cards-container,
  .billing-card-main {
    width: unset;
  }
  .tags-right-header-item {
    display: block !important;
  }
  .left-side-about-user-id {
    padding: 8px 0;
  }
  .left-side-about-user-id .left-sidebar-username {
    display: none;
  }
  .user-folder-about-inner-content {
    left: 60px;
    bottom: 150px;
  }
  .user-folder-about-inner-content.user-profile-modal {
    left: 60px;
    bottom: 60px;
  }
  nav.navbar.header-navbar .navbar-nav a:after {
    background: #333;
  }
  nav.navbar.header-navbar .navbar-nav a {
    text-align: center;
    margin: 8px auto;
  }
  .form-login-wrap .signup-left-animation,
  .login__form-wrap .signup-left-animation {
    display: none;
  }
  section.form-login-wrap .col-6-sign:nth-child(1),
  section.login__form-wrap .col-6-sign:nth-child(1) {
    display: none;
  }
  nav.navbar.header-navbar div#navbarCollapse {
    background: #fff;
    position: absolute;
    width: 94%;
    left: 10px;
    right: 10px;
    top: 73px;
    z-index: 555;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }

  nav.navbar.header-navbar div#navbarCollapse:before {
    position: absolute;
    top: -3px;
    content: "";
    background: #fff;
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 10px;
    border-radius: 3px;
  }
  .mobile-notification-bel {
    margin-top: 20px;
    display: block;
  }
  ul.user-workspace-container {
    position: fixed;
    bottom: 5px;
    left: 0;
    right: 0;
    /*background:rgb(50, 50, 50);*/
    background: transparent;
  }
  ul.main-sidebar-items {
    /* height: calc(100% - 52%);*/
    height: calc(100% - 35%);
    overflow: scroll;
    overflow-x: hidden;
  }
  .main-wrapper-container section.hero {
    padding-top: 117px;
  }
  .main-wrapper-container {
    padding: 0px;
  }
  .navbar.header-navbar.navbar-light .navbar-toggler {
    border: 0px !important;
  }
  section.form-login-wrap form.form-login,
  section.login__form-wrap form.login__form {
    padding: 30px 20px;
    border-radius: 6px;
  }
  section.signup-free__link .link-banner.link-banner--medium {
    padding-top: 66px;
  }
  .reports-header-contain .reports-right-item-cont {
    display: none;
  }

  .sidebar .nano-content > ul > li.active > a {
    border-radius: 14px;
    margin: 0px 8px;
    line-height: 0px;
  }
  .remove-text {
    display: none;
  }
  .intimer-field-descript-time-dur-cont {
    height: 100%;
    min-width: unset;
    flex: 0 0 auto;
    /*padding: 0px 9px 0px 10px;*/
    padding: 0px 5px 0px 0px;
  }
  .intimer-project-panel-continue-btn-box {
    width: unset;
  }
  .inertimer-row_description-tag {
    min-width: 50px;
  }
  .inertimer-row_description-folder {
    margin-left: 15px;
  }
  .topbar-timer-header-contain .top-header-last-right {
    display: none;
  }
  /*.sidebar-hide .sidebar.sidebar-hide-to-small{
  height:100vh;
}*/
  .sidebar-hide .sidebar.sidebar-hide-to-small {
    position: fixed;
  }
  .user-folder a i.fa-angle-down {
    display: none;
  }
  span.user-shortname-left-sidebar {
    float: left;
    margin-right: 0px;
    margin-top: -3px;
    margin-left: 15px !important;
    font-size: 13px;
  }
  .sidebar i {
    margin-right: 0px;
  }
  nav.navbar.header-navbar .navbar-nav a.menu-download-app-btn {
    background-color: #fff;
    border-color: #f1f4f6;
    border: 3px solid #f1f4f6;
    color: #282a2d;
    line-height: 40px;
    border-radius: 2.4rem;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    height: 44px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 15px;
  }
  nav.navbar.header-navbar .navbar-nav a.menu-download-app-btn:after {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-logo-desktop {
    display: none;
  }
  .create-new_workspace-container .create_new_Info_contain .sub_content,
  .create-new_workspace-container .create_new-wrokspace-form-wrap {
    width: 320px;
  }
  li.label.user-workspace-folder {
    margin-top: 20px;
  }
  .description-field {
    max-width: 40%;
  }
  a.menu-download-app-btn {
    display: none;
  }

  .hero__signup-overlay {
    height: calc(100% + 57.5rem);
  }
  .mobile-notification-bel {
    display: none;
  }
  li.user-folder a {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .hero .page-header-content-wrap h1,
  .hero .hero__content.wrap.wrap--narrow h1 {
    font-size: 52px;
    font-weight: 800;
    color: #fff;
    padding-bottom: 12px;
  }
  nav.navbar.header-navbar {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .intimer-field-descript-time-dur-cont {
    min-width: 165px;
  }
  .sidebar .nano-content > ul > li.active > a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    line-height: normal;
    height: 32px;
    justify-content: left;
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
    flex: 1 1 auto;
    border-radius: 8px;
    overflow: hidden;
    width: 163px;
    margin-left: 8px;
    padding-left: 12px;
    color: #fff;
  }
  li.user-folder a {
    padding-right: 0px !important;
    padding-left: 8px !important;
    color: rgb(206, 206, 206) !important;
  }
  .intimer-field-descript-time-dur-cont {
    min-width: 250px;
  }
  .intimer-field-descript-time-dur-cont .time-dur-inner-contain {
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    flex-flow: row nowrap;
  }
  .intimer-field-descript-time-dur-cont
    .time-dur-inner-contain
    span.time-format-duration {
    flex: 0 0 87px;
    margin-right: -40px;
    margin-left: 24px;
  }
  .time-dur-inner-contain span.start-end-time-dur {
    display: block;
    padding-right: 0px;
    flex: 1 0 auto;
  }
  .topbar-timer-header-contain .what-are-you-woring {
    min-width: 327px;
  }
  .hide-logo-on-mobile {
    display: block;
  }
  .notification-in-sidebar {
    margin-top: 5px;
    display: block;
  }
  li.user-folder {
  }
  .user-folder span.user-name-fld {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc(100% - 53px);
    text-align: left;
    overflow: hidden;
    /*margin-bottom: -9px;*/
  }
  li.user-folder > a {
    display: flex;
    color: rgb(206, 206, 206);
    border-radius: 8px;
    outline: none;
    padding: 0px 8px;
    box-shadow: rgb(123, 123, 123) 0px 0px 0px 1px inset,
      rgb(50, 50, 50) 0px 0px 0px 1px;
    margin: 0px 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
}
/*  5 - media screen and (max-width: 1024px) End
---------------------------------------------------------------------- */
/*  6 - media screen and (max-width: 991px)
---------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 991px) {
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
  .setting-top-workspace-set-container .setting-workspace-logo-box {
    flex: 6 0 0;
  }
}
/*  6 - media screen and (max-width: 991px) End
---------------------------------------------------------------------- */
/*  7 - media screen and (max-width: 767px)
---------------------------------------------------------------------- */
@media (min-width: 680px) and (max-width: 767px) {
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
  .billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
    width: 227px;
  }
}
/*  7 - media screen and (max-width: 767px) End
---------------------------------------------------------------------- */
/*  8 - media screen and (max-width: 680px)
---------------------------------------------------------------------- */
@media (min-width: 480px) and (max-width: 679px) {
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
  .inbox-pagination {
    margin-top: 30px;
    float: left !important;
  }
  .card-badge .label {
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
  }
  .mail-box .sm-side {
    width: 100%;
  }
  .mail-box aside {
    display: inline;
  }
  .billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
    width: 227px;
  }
}
/*  8 - media screen and (max-width: 680px) End
---------------------------------------------------------------------- */
/*  9 - media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media (min-width: 360px) and (max-width: 479px) {
  .billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
    width: 227px;
  }
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
  #project {
    margin-left: 0%;
  }
  .fc-toolbar .fc-right {
    float: left;
    margin-top: 15px;
  }
  .card-badge .label {
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
  }
  .mail-box .sm-side {
    width: 100%;
  }
  .mail-box aside {
    display: inline;
  }
}
/*  9 - media screen and (max-width: 360px) End
---------------------------------------------------------------------- */
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 359px) {
  .billing-card-intro-wrap .card-inner-btn button.billing-card-btn {
    width: 187px;
  }
  .title-margin-right {
    margin-right: 7px !important;
  }
  .title-margin-left {
    margin-left: 7px !important;
  }
  #project {
    margin-left: 0%;
  }
  .fc-toolbar .fc-right {
    float: left;
    margin-top: 15px;
  }
  .br-theme-bars-pill .br-widget a {
    padding: 7px 12px;
  }
  .br-theme-bars-reversed .br-widget .br-current-rating {
    padding: 0px;
  }
  .alert-rating {
    padding-bottom: 40px;
  }
  .card-badge .label {
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px;
  }
  .mail-box .sm-side {
    width: 100%;
  }
  .mail-box aside {
    display: inline;
  }
  .chk-group {
    margin-bottom: 10px;
  }
  .pagination-list {
    float: left !important;
    margin-top: 10px;
  }
  .inner-append {
    position: relative;
  }
  .inner-append .append-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
  .input-text {
    margin-bottom: 12px;
  }
}
/*  10 - media screen and (max-width: 320px)
---------------------------------------------------------------------- */
/*---------------------------------------------------------------*/
/* Retina */
/*---------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .default-logo {
    display: none !important;
  }
  .retina-logo {
    display: inline-block !important;
  }
}
