.main {
  margin-left: 182px;
}
div#popup1 {
  z-index: 1;
}
div#tooltip {
  z-index: 1;
}
.modal-content {
  border: 0;
}

.bulk-edit-contain .projects-bulk-edit-items {
  cursor: pointer;
}
.filter-by-team-btn button.group-by-btn.bulkedit-button-active {
  color: rgb(65, 175, 0);
  background-color: rgba(157, 197, 132, 0.12);
}
.bulk-edit-contain .projects-bulk-edit-items.active-black {
  color: black;
}
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #719961;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st2 {
  fill: #719961;
}
.st3 {
  fill: #ffffff;
}
body .index_fullpage__2JorV {
  background-size: auto;
}

body .index_fullpage__2JorV img,
body .index_fullpage__2JorV h1 {
  display: none;
}
body .index_fullpage__2JorV {
  background-repeat: no-repeat;
}
.sidebar .nano-content > ul > li > a.active {
  background-color: rgb(73, 73, 73);
}
.sidebar .nano-content > ul li > a {
  margin-bottom: 3px;
}

.filter-by-team-btn ul.dropdown-menu li.filter.active {
  background-color: rgba(157, 197, 132, 0.12);
  color: rgb(75, 200, 0);
}
.filter-by-team-btn ul.dropdown-menu li.filter.active a {
  color: rgb(75, 200, 0);
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #719961;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st2 {
  fill: #719961;
}
.st3 {
  fill: #ffffff;
}
.logo a {
  width: 100%;
}
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #231f20;
}
.st1 {
  fill: #231f20;
}
.sto-whitelogo {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st1-whitelogo {
  fill: #6c9c62;
}
.st2-whitelogo {
  fill: #ffffff;
}
.project-enable i {
  opacity: 0.7;
}

.animate__animated.animate__fadeInUp {
  --animate-duration: 2s;
}
.active-condition-number,
.active-condition-digits,
.active-condition-letters,
.active-condition-special {
  color: #88cf8f;
  font-weight: 500;
}

.toggle-switch .toggle-track:before {
  background: #7b7b7b;
  right: 21px;
}
.toggle-switch input[type="checkbox"]:checked + label .toggle-track:before {
  background: #41af00;
  right: -3px;
}
.manual-play-button {
  cursor: pointer;
}
@import url("https://ultimatecaddy.net/timeily/timeily.css");

.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}