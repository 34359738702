/*  font  */

/*  font size */

.f-s-1 {font-size: 1px!important;}
.f-s-2 {font-size: 2px!important;}
.f-s-3 {font-size: 3px!important;}
.f-s-4 {font-size: 4px!important;}
.f-s-5 {font-size: 5px!important;}
.f-s-6 {font-size: 6px!important;}
.f-s-7 {font-size: 7px!important;}
.f-s-8 {font-size: 8px!important;}
.f-s-9 {font-size: 9px!important;}
.f-s-10 {font-size: 10px!important;}
.f-s-11 {font-size: 11px!important;}
.f-s-12 {font-size: 12px!important;}
.f-s-13 {font-size: 13px!important;}
.f-s-14 {font-size: 14px!important;}
.f-s-15 {font-size: 15px!important;}
.f-s-16 {font-size: 16px!important;}
.f-s-17 {font-size: 17px!important;}
.f-s-18 {font-size: 18px!important;}
.f-s-19 {font-size: 19px!important;}
.f-s-20 {font-size: 20px!important;}
.f-s-21 {font-size: 21px!important;}
.f-s-22 {font-size: 22px!important;}
.f-s-23 {font-size: 23px!important;}
.f-s-24 {font-size: 24px!important;}
.f-s-25 {font-size: 25px!important;}
.f-s-26 {font-size: 26px!important;}
.f-s-27 {font-size: 27px!important;}
.f-s-28 {font-size: 28px!important;}
.f-s-29 {font-size: 29px!important;}
.f-s-30 {font-size: 30px!important;}
.f-s-31 {font-size: 31px!important;}
.f-s-32 {font-size: 32px!important;}
.f-s-33 {font-size: 33px!important;}
.f-s-34 {font-size: 34px!important;}
.f-s-35 {font-size: 35px!important;}
.f-s-36 {font-size: 36px!important;}
.f-s-37 {font-size: 37px!important;}
.f-s-38 {font-size: 38px!important;}
.f-s-39 {font-size: 39px!important;}
.f-s-40 {font-size: 40px!important;}
.f-s-41 {font-size: 41px!important;}
.f-s-42 {font-size: 42px!important;}
.f-s-43 {font-size: 43px!important;}
.f-s-44 {font-size: 44px!important;}
.f-s-45 {font-size: 45px!important;}
.f-s-46 {font-size: 46px!important;}
.f-s-47 {font-size: 47px!important;}
.f-s-48 {font-size: 48px!important;}
.f-s-49 {font-size: 49px!important;}
.f-s-50 {font-size: 50px!important;}
.f-s-51 {font-size: 51px!important;}
.f-s-52 {font-size: 52px!important;}
.f-s-53 {font-size: 53px!important;}
.f-s-54 {font-size: 54px!important;}
.f-s-55 {font-size: 55px!important;}
.f-s-56 {font-size: 56px!important;}
.f-s-57 {font-size: 57px!important;}
.f-s-58 {font-size: 58px!important;}
.f-s-59 {font-size: 59px!important;}
.f-s-60 {font-size: 60px!important;}
.f-s-61 {font-size: 61px!important;}
.f-s-62 {font-size: 62px!important;}
.f-s-63 {font-size: 63px!important;}
.f-s-64 {font-size: 64px!important;}
.f-s-65 {font-size: 65px!important;}
.f-s-66 {font-size: 66px!important;}
.f-s-67 {font-size: 67px!important;}
.f-s-68 {font-size: 68px!important;}
.f-s-69 {font-size: 69px!important;}
.f-s-70 {font-size: 70px!important;}
.f-s-71 {font-size: 71px!important;}
.f-s-72 {font-size: 72px!important;}
.f-s-73 {font-size: 73px!important;}
.f-s-74 {font-size: 74px!important;}
.f-s-75 {font-size: 75px!important;}
.f-s-76 {font-size: 76px!important;}
.f-s-77 {font-size: 77px!important;}
.f-s-78 {font-size: 78px!important;}
.f-s-79 {font-size: 79px!important;}
.f-s-80 {font-size: 80px!important;}
.f-s-81 {font-size: 81px!important;}
.f-s-82 {font-size: 82px!important;}
.f-s-83 {font-size: 83px!important;}
.f-s-84 {font-size: 84px!important;}
.f-s-85 {font-size: 85px!important;}
.f-s-86 {font-size: 86px!important;}
.f-s-87 {font-size: 87px!important;}
.f-s-88 {font-size: 88px!important;}
.f-s-89 {font-size: 89px!important;}
.f-s-90 {font-size: 90px!important;}
.f-s-91 {font-size: 91px!important;}
.f-s-92 {font-size: 92px!important;}
.f-s-93 {font-size: 93px!important;}
.f-s-94 {font-size: 94px!important;}
.f-s-95 {font-size: 95px!important;}
.f-s-96 {font-size: 96px!important;}
.f-s-97 {font-size: 97px!important;}
.f-s-98 {font-size: 98px!important;}
.f-s-99 {font-size: 99px!important;}
.f-s-100 {font-size: 100px!important;}

/*   font weight */

.f-w-100 {font-weight: 100}
.f-w-200 {font-weight: 200}
.f-w-300 {font-weight: 300}
.f-w-400 {font-weight: 400}
.f-w-500 {font-weight: 500}
.f-w-600 {font-weight: 600}
.f-w-700 {font-weight: 700}
.f-w-800 {font-weight: 800}
.f-w-900 {font-weight: 900}


/*   margin  */

.m-0 {margin: 0px!important;}

/*   margin top  */

.m-t-0 {margin-top: 0px!important;}
.m-t-1 {margin-top: 1px!important;}
.m-t-2 {margin-top: 2px!important;}
.m-t-3 {margin-top: 3px!important;}
.m-t-4 {margin-top: 4px!important;}
.m-t-5 {margin-top: 5px!important;}
.m-t-6 {margin-top: 6px!important;}
.m-t-7 {margin-top: 7px!important;}
.m-t-8 {margin-top: 8px!important;}
.m-t-9 {margin-top: 9px!important;}
.m-t-10 {margin-top: 10px!important;}
.m-t-11 {margin-top: 11px!important;}
.m-t-12 {margin-top: 12px!important;}
.m-t-13 {margin-top: 13px!important;}
.m-t-14 {margin-top: 14px!important;}
.m-t-15 {margin-top: 15px!important;}
.m-t-16 {margin-top: 16px!important;}
.m-t-17 {margin-top: 17px!important;}
.m-t-18 {margin-top: 18px!important;}
.m-t-19 {margin-top: 19px!important;}
.m-t-20 {margin-top: 20px!important;}
.m-t-21 {margin-top: 21px!important;}
.m-t-22 {margin-top: 22px!important;}
.m-t-23 {margin-top: 23px!important;}
.m-t-24 {margin-top: 24px!important;}
.m-t-25 {margin-top: 25px!important;}
.m-t-26 {margin-top: 26px!important;}
.m-t-27 {margin-top: 27px!important;}
.m-t-28 {margin-top: 28px!important;}
.m-t-29 {margin-top: 29px!important;}
.m-t-30 {margin-top: 30px!important;}
.m-t-31 {margin-top: 31px!important;}
.m-t-32 {margin-top: 32px!important;}
.m-t-33 {margin-top: 33px!important;}
.m-t-34 {margin-top: 34px!important;}
.m-t-35 {margin-top: 35px!important;}
.m-t-36 {margin-top: 36px!important;}
.m-t-37 {margin-top: 37px!important;}
.m-t-38 {margin-top: 38px!important;}
.m-t-39 {margin-top: 39px!important;}
.m-t-40 {margin-top: 40px!important;}
.m-t-41 {margin-top: 41px!important;}
.m-t-42 {margin-top: 42px!important;}
.m-t-43 {margin-top: 43px!important;}
.m-t-44 {margin-top: 44px!important;}
.m-t-45 {margin-top: 45px!important;}
.m-t-46 {margin-top: 46px!important;}
.m-t-47 {margin-top: 47px!important;}
.m-t-48 {margin-top: 48px!important;}
.m-t-49 {margin-top: 49px!important;}
.m-t-50 {margin-top: 50px!important;}
.m-t-51 {margin-top: 51px!important;}
.m-t-52 {margin-top: 52px!important;}
.m-t-53 {margin-top: 53px!important;}
.m-t-54 {margin-top: 54px!important;}
.m-t-55 {margin-top: 55px!important;}
.m-t-56 {margin-top: 56px!important;}
.m-t-57 {margin-top: 57px!important;}
.m-t-58 {margin-top: 58px!important;}
.m-t-59 {margin-top: 59px!important;}
.m-t-60 {margin-top: 60px!important;}
.m-t-61 {margin-top: 61px!important;}
.m-t-62 {margin-top: 62px!important;}
.m-t-63 {margin-top: 63px!important;}
.m-t-64 {margin-top: 64px!important;}
.m-t-65 {margin-top: 65px!important;}
.m-t-66 {margin-top: 66px!important;}
.m-t-67 {margin-top: 67px!important;}
.m-t-68 {margin-top: 68px!important;}
.m-t-69 {margin-top: 69px!important;}
.m-t-70 {margin-top: 70px!important;}
.m-t-71 {margin-top: 71px!important;}
.m-t-72 {margin-top: 72px!important;}
.m-t-73 {margin-top: 73px!important;}
.m-t-74 {margin-top: 74px!important;}
.m-t-75 {margin-top: 75px!important;}
.m-t-76 {margin-top: 76px!important;}
.m-t-77 {margin-top: 77px!important;}
.m-t-78 {margin-top: 78px!important;}
.m-t-79 {margin-top: 79px!important;}
.m-t-80 {margin-top: 80px!important;}
.m-t-81 {margin-top: 81px!important;}
.m-t-82 {margin-top: 82px!important;}
.m-t-83 {margin-top: 83px!important;}
.m-t-84 {margin-top: 84px!important;}
.m-t-85 {margin-top: 85px!important;}
.m-t-86 {margin-top: 86px!important;}
.m-t-87 {margin-top: 87px!important;}
.m-t-88 {margin-top: 88px!important;}
.m-t-89 {margin-top: 89px!important;}
.m-t-90 {margin-top: 90px!important;}
.m-t-91 {margin-top: 91px!important;}
.m-t-92 {margin-top: 92px!important;}
.m-t-93 {margin-top: 93px!important;}
.m-t-94 {margin-top: 94px!important;}
.m-t-95 {margin-top: 95px!important;}
.m-t-96 {margin-top: 96px!important;}
.m-t-97 {margin-top: 97px!important;}
.m-t-98 {margin-top: 98px!important;}
.m-t-99 {margin-top: 99px!important;}
.m-t-100 {margin-top: 100px!important;}
.m-t-101 {margin-top: 101px!important;}
.m-t-102 {margin-top: 102px!important;}
.m-t-103 {margin-top: 103px!important;}
.m-t-104 {margin-top: 104px!important;}
.m-t-105 {margin-top: 105px!important;}
.m-t-106 {margin-top: 106px!important;}
.m-t-107 {margin-top: 107px!important;}
.m-t-108 {margin-top: 108px!important;}
.m-t-109 {margin-top: 109px!important;}
.m-t-110 {margin-top: 110px!important;}
.m-t-111 {margin-top: 111px!important;}
.m-t-112 {margin-top: 112px!important;}
.m-t-113 {margin-top: 113px!important;}
.m-t-114 {margin-top: 114px!important;}
.m-t-115 {margin-top: 115px!important;}
.m-t-116 {margin-top: 116px!important;}
.m-t-117 {margin-top: 117px!important;}
.m-t-118 {margin-top: 118px!important;}
.m-t-119 {margin-top: 119px!important;}
.m-t-120 {margin-top: 120px!important;}
.m-t-121 {margin-top: 121px!important;}
.m-t-122 {margin-top: 122px!important;}
.m-t-123 {margin-top: 123px!important;}
.m-t-124 {margin-top: 124px!important;}
.m-t-125 {margin-top: 125px!important;}
.m-t-126 {margin-top: 126px!important;}
.m-t-127 {margin-top: 127px!important;}
.m-t-128 {margin-top: 128px!important;}
.m-t-129 {margin-top: 129px!important;}
.m-t-130 {margin-top: 130px!important;}
.m-t-131 {margin-top: 131px!important;}
.m-t-132 {margin-top: 132px!important;}
.m-t-133 {margin-top: 133px!important;}
.m-t-134 {margin-top: 134px!important;}
.m-t-135 {margin-top: 135px!important;}
.m-t-136 {margin-top: 136px!important;}
.m-t-137 {margin-top: 137px!important;}
.m-t-138 {margin-top: 138px!important;}
.m-t-139 {margin-top: 139px!important;}
.m-t-140 {margin-top: 140px!important;}
.m-t-141 {margin-top: 141px!important;}
.m-t-142 {margin-top: 142px!important;}
.m-t-143 {margin-top: 143px!important;}
.m-t-144 {margin-top: 144px!important;}
.m-t-145 {margin-top: 145px!important;}
.m-t-146 {margin-top: 146px!important;}
.m-t-147 {margin-top: 147px!important;}
.m-t-148 {margin-top: 148px!important;}
.m-t-149 {margin-top: 149px!important;}
.m-t-150 {margin-top: 150px!important;}


/*   margin right  */

.m-r-0 {margin-right: 0px!important;}
.m-r-1 {margin-right: 1px!important;}
.m-r-2 {margin-right: 2px!important;}
.m-r-3 {margin-right: 3px!important;}
.m-r-4 {margin-right: 4px!important;}
.m-r-5 {margin-right: 5px!important;}
.m-r-6 {margin-right: 6px!important;}
.m-r-7 {margin-right: 7px!important;}
.m-r-8 {margin-right: 8px!important;}
.m-r-9 {margin-right: 9px!important;}
.m-r-10 {margin-right: 10px!important;}
.m-r-11 {margin-right: 11px!important;}
.m-r-12 {margin-right: 12px!important;}
.m-r-13 {margin-right: 13px!important;}
.m-r-14 {margin-right: 14px!important;}
.m-r-15 {margin-right: 15px!important;}
.m-r-16 {margin-right: 16px!important;}
.m-r-17 {margin-right: 17px!important;}
.m-r-18 {margin-right: 18px!important;}
.m-r-19 {margin-right: 19px!important;}
.m-r-20 {margin-right: 20px!important;}
.m-r-21 {margin-right: 21px!important;}
.m-r-22 {margin-right: 22px!important;}
.m-r-23 {margin-right: 23px!important;}
.m-r-24 {margin-right: 24px!important;}
.m-r-25 {margin-right: 25px!important;}
.m-r-26 {margin-right: 26px!important;}
.m-r-27 {margin-right: 27px!important;}
.m-r-28 {margin-right: 28px!important;}
.m-r-29 {margin-right: 29px!important;}
.m-r-30 {margin-right: 30px!important;}
.m-r-31 {margin-right: 31px!important;}
.m-r-32 {margin-right: 32px!important;}
.m-r-33 {margin-right: 33px!important;}
.m-r-34 {margin-right: 34px!important;}
.m-r-35 {margin-right: 35px!important;}
.m-r-36 {margin-right: 36px!important;}
.m-r-37 {margin-right: 37px!important;}
.m-r-38 {margin-right: 38px!important;}
.m-r-39 {margin-right: 39px!important;}
.m-r-40 {margin-right: 40px!important;}
.m-r-41 {margin-right: 4px!important;}
.m-r-42 {margin-right: 42px!important;}
.m-r-43 {margin-right: 43px!important;}
.m-r-44 {margin-right: 44px!important;}
.m-r-45 {margin-right: 45px!important;}
.m-r-46 {margin-right: 46px!important;}
.m-r-47 {margin-right: 47px!important;}
.m-r-48 {margin-right: 48px!important;}
.m-r-49 {margin-right: 49px!important;}
.m-r-50 {margin-right: 50px!important;}
.m-r-51 {margin-right: 51px!important;}
.m-r-52 {margin-right: 52px!important;}
.m-r-53 {margin-right: 53px!important;}
.m-r-54 {margin-right: 54px!important;}
.m-r-55 {margin-right: 55px!important;}
.m-r-56 {margin-right: 56px!important;}
.m-r-57 {margin-right: 57px!important;}
.m-r-58 {margin-right: 58px!important;}
.m-r-59 {margin-right: 59px!important;}
.m-r-60 {margin-right: 60px!important;}
.m-r-61 {margin-right: 61px!important;}
.m-r-62 {margin-right: 62px!important;}
.m-r-63 {margin-right: 63px!important;}
.m-r-64 {margin-right: 64px!important;}
.m-r-65 {margin-right: 65px!important;}
.m-r-66 {margin-right: 66px!important;}
.m-r-67 {margin-right: 67px!important;}
.m-r-68 {margin-right: 68px!important;}
.m-r-69 {margin-right: 69px!important;}
.m-r-70 {margin-right: 70px!important;}
.m-r-71 {margin-right: 71px!important;}
.m-r-72 {margin-right: 72px!important;}
.m-r-73 {margin-right: 73px!important;}
.m-r-74 {margin-right: 74px!important;}
.m-r-75 {margin-right: 75px!important;}
.m-r-76 {margin-right: 76px!important;}
.m-r-77 {margin-right: 77px!important;}
.m-r-78 {margin-right: 78px!important;}
.m-r-79 {margin-right: 79px!important;}
.m-r-80 {margin-right: 80px!important;}
.m-r-81 {margin-right: 81px!important;}
.m-r-82 {margin-right: 82px!important;}
.m-r-83 {margin-right: 83px!important;}
.m-r-84 {margin-right: 84px!important;}
.m-r-85 {margin-right: 85px!important;}
.m-r-86 {margin-right: 86px!important;}
.m-r-87 {margin-right: 87px!important;}
.m-r-88 {margin-right: 88px!important;}
.m-r-89 {margin-right: 89px!important;}
.m-r-90 {margin-right: 90px!important;}
.m-r-91 {margin-right: 91px!important;}
.m-r-92 {margin-right: 92px!important;}
.m-r-93 {margin-right: 93px!important;}
.m-r-94 {margin-right: 94px!important;}
.m-r-95 {margin-right: 95px!important;}
.m-r-96 {margin-right: 96px!important;}
.m-r-97 {margin-right: 97px!important;}
.m-r-98 {margin-right: 98px!important;}
.m-r-99 {margin-right: 99px!important;}
.m-r-100 {margin-right: 100px!important;}
.m-r-101 {margin-right: 101px!important;}
.m-r-102 {margin-right: 102px!important;}
.m-r-103 {margin-right: 103px!important;}
.m-r-104 {margin-right: 104px!important;}
.m-r-105 {margin-right: 105px!important;}
.m-r-106 {margin-right: 106px!important;}
.m-r-107 {margin-right: 107px!important;}
.m-r-108 {margin-right: 108px!important;}
.m-r-109 {margin-right: 109px!important;}
.m-r-110 {margin-right: 110px!important;}
.m-r-111 {margin-right: 111px!important;}
.m-r-112 {margin-right: 112px!important;}
.m-r-113 {margin-right: 113px!important;}
.m-r-114 {margin-right: 114px!important;}
.m-r-115 {margin-right: 115px!important;}
.m-r-116 {margin-right: 116px!important;}
.m-r-117 {margin-right: 117px!important;}
.m-r-118 {margin-right: 118px!important;}
.m-r-119 {margin-right: 119px!important;}
.m-r-120 {margin-right: 120px!important;}
.m-r-121 {margin-right: 121px!important;}
.m-r-122 {margin-right: 122px!important;}
.m-r-123 {margin-right: 123px!important;}
.m-r-124 {margin-right: 124px!important;}
.m-r-125 {margin-right: 125px!important;}
.m-r-126 {margin-right: 126px!important;}
.m-r-127 {margin-right: 127px!important;}
.m-r-128 {margin-right: 128px!important;}
.m-r-129 {margin-right: 129px!important;}
.m-r-130 {margin-right: 130px!important;}
.m-r-131 {margin-right: 131px!important;}
.m-r-132 {margin-right: 132px!important;}
.m-r-133 {margin-right: 133px!important;}
.m-r-134 {margin-right: 134px!important;}
.m-r-135 {margin-right: 135px!important;}
.m-r-136 {margin-right: 136px!important;}
.m-r-137 {margin-right: 137px!important;}
.m-r-138 {margin-right: 138px!important;}
.m-r-139 {margin-right: 139px!important;}
.m-r-140 {margin-right: 140px!important;}
.m-r-141 {margin-right: 141px!important;}
.m-r-142 {margin-right: 142px!important;}
.m-r-143 {margin-right: 143px!important;}
.m-r-144 {margin-right: 144px!important;}
.m-r-145 {margin-right: 145px!important;}
.m-r-146 {margin-right: 146px!important;}
.m-r-147 {margin-right: 147px!important;}
.m-r-148 {margin-right: 148px!important;}
.m-r-149 {margin-right: 149px!important;}
.m-r-150 {margin-right: 150px!important;}


/*   margin bottom  */

.m-b-0 {margin-bottom: 0px!important;}
.m-b-1 {margin-bottom: 1px!important;}
.m-b-2 {margin-bottom: 2px!important;}
.m-b-3 {margin-bottom: 3px!important;}
.m-b-4 {margin-bottom: 4px!important;}
.m-b-5 {margin-bottom: 5px!important;}
.m-b-6 {margin-bottom: 6px!important;}
.m-b-7 {margin-bottom: 7px!important;}
.m-b-8 {margin-bottom: 8px!important;}
.m-b-9 {margin-bottom: 9px!important;}
.m-b-10 {margin-bottom: 10px!important;}
.m-b-11 {margin-bottom: 11px!important;}
.m-b-12 {margin-bottom: 12px!important;}
.m-b-13 {margin-bottom: 13px!important;}
.m-b-14 {margin-bottom: 14px!important;}
.m-b-15 {margin-bottom: 15px!important;}
.m-b-16 {margin-bottom: 16px!important;}
.m-b-17 {margin-bottom: 17px!important;}
.m-b-18 {margin-bottom: 18px!important;}
.m-b-19 {margin-bottom: 19px!important;}
.m-b-20 {margin-bottom: 20px!important;}
.m-b-21 {margin-bottom: 21px!important;}
.m-b-22 {margin-bottom: 22px!important;}
.m-b-23 {margin-bottom: 23px!important;}
.m-b-24 {margin-bottom: 24px!important;}
.m-b-25 {margin-bottom: 25px!important;}
.m-b-26 {margin-bottom: 26px!important;}
.m-b-27 {margin-bottom: 27px!important;}
.m-b-28 {margin-bottom: 28px!important;}
.m-b-29 {margin-bottom: 29px!important;}
.m-b-30 {margin-bottom: 30px!important;}
.m-b-31 {margin-bottom: 31px!important;}
.m-b-32 {margin-bottom: 32px!important;}
.m-b-33 {margin-bottom: 33px!important;}
.m-b-34 {margin-bottom: 34px!important;}
.m-b-35 {margin-bottom: 35px!important;}
.m-b-36 {margin-bottom: 36px!important;}
.m-b-37 {margin-bottom: 37px!important;}
.m-b-38 {margin-bottom: 38px!important;}
.m-b-39 {margin-bottom: 39px!important;}
.m-b-40 {margin-bottom: 40px!important;}
.m-b-41 {margin-bottom: 4px!important;}
.m-b-42 {margin-bottom: 42px!important;}
.m-b-43 {margin-bottom: 43px!important;}
.m-b-44 {margin-bottom: 44px!important;}
.m-b-45 {margin-bottom: 45px!important;}
.m-b-46 {margin-bottom: 46px!important;}
.m-b-47 {margin-bottom: 47px!important;}
.m-b-48 {margin-bottom: 48px!important;}
.m-b-49 {margin-bottom: 49px!important;}
.m-b-50 {margin-bottom: 50px!important;}
.m-b-51 {margin-bottom: 51px!important;}
.m-b-52 {margin-bottom: 52px!important;}
.m-b-53 {margin-bottom: 53px!important;}
.m-b-54 {margin-bottom: 54px!important;}
.m-b-55 {margin-bottom: 55px!important;}
.m-b-56 {margin-bottom: 56px!important;}
.m-b-57 {margin-bottom: 57px!important;}
.m-b-58 {margin-bottom: 58px!important;}
.m-b-59 {margin-bottom: 59px!important;}
.m-b-60 {margin-bottom: 60px!important;}
.m-b-61 {margin-bottom: 61px!important;}
.m-b-62 {margin-bottom: 62px!important;}
.m-b-63 {margin-bottom: 63px!important;}
.m-b-64 {margin-bottom: 64px!important;}
.m-b-65 {margin-bottom: 65px!important;}
.m-b-66 {margin-bottom: 66px!important;}
.m-b-67 {margin-bottom: 67px!important;}
.m-b-68 {margin-bottom: 68px!important;}
.m-b-69 {margin-bottom: 69px!important;}
.m-b-70 {margin-bottom: 70px!important;}
.m-b-71 {margin-bottom: 71px!important;}
.m-b-72 {margin-bottom: 72px!important;}
.m-b-73 {margin-bottom: 73px!important;}
.m-b-74 {margin-bottom: 74px!important;}
.m-b-75 {margin-bottom: 75px!important;}
.m-b-76 {margin-bottom: 76px!important;}
.m-b-77 {margin-bottom: 77px!important;}
.m-b-78 {margin-bottom: 78px!important;}
.m-b-79 {margin-bottom: 79px!important;}
.m-b-80 {margin-bottom: 80px!important;}
.m-b-81 {margin-bottom: 81px!important;}
.m-b-82 {margin-bottom: 82px!important;}
.m-b-83 {margin-bottom: 83px!important;}
.m-b-84 {margin-bottom: 84px!important;}
.m-b-85 {margin-bottom: 85px!important;}
.m-b-86 {margin-bottom: 86px!important;}
.m-b-87 {margin-bottom: 87px!important;}
.m-b-88 {margin-bottom: 88px!important;}
.m-b-89 {margin-bottom: 89px!important;}
.m-b-90 {margin-bottom: 90px!important;}
.m-b-91 {margin-bottom: 91px!important;}
.m-b-92 {margin-bottom: 92px!important;}
.m-b-93 {margin-bottom: 93px!important;}
.m-b-94 {margin-bottom: 94px!important;}
.m-b-95 {margin-bottom: 95px!important;}
.m-b-96 {margin-bottom: 96px!important;}
.m-b-97 {margin-bottom: 97px!important;}
.m-b-98 {margin-bottom: 98px!important;}
.m-b-99 {margin-bottom: 99px!important;}
.m-b-100 {margin-bottom: 100px!important;}
.m-b-101 {margin-bottom: 101px!important;}
.m-b-102 {margin-bottom: 102px!important;}
.m-b-103 {margin-bottom: 103px!important;}
.m-b-104 {margin-bottom: 104px!important;}
.m-b-105 {margin-bottom: 105px!important;}
.m-b-106 {margin-bottom: 106px!important;}
.m-b-107 {margin-bottom: 107px!important;}
.m-b-108 {margin-bottom: 108px!important;}
.m-b-109 {margin-bottom: 109px!important;}
.m-b-110 {margin-bottom: 110px!important;}
.m-b-111 {margin-bottom: 111px!important;}
.m-b-112 {margin-bottom: 112px!important;}
.m-b-113 {margin-bottom: 113px!important;}
.m-b-114 {margin-bottom: 114px!important;}
.m-b-115 {margin-bottom: 115px!important;}
.m-b-116 {margin-bottom: 116px!important;}
.m-b-117 {margin-bottom: 117px!important;}
.m-b-118 {margin-bottom: 118px!important;}
.m-b-119 {margin-bottom: 119px!important;}
.m-b-120 {margin-bottom: 120px!important;}
.m-b-121 {margin-bottom: 121px!important;}
.m-b-122 {margin-bottom: 122px!important;}
.m-b-123 {margin-bottom: 123px!important;}
.m-b-124 {margin-bottom: 124px!important;}
.m-b-125 {margin-bottom: 125px!important;}
.m-b-126 {margin-bottom: 126px!important;}
.m-b-127 {margin-bottom: 127px!important;}
.m-b-128 {margin-bottom: 128px!important;}
.m-b-129 {margin-bottom: 129px!important;}
.m-b-130 {margin-bottom: 130px!important;}
.m-b-131 {margin-bottom: 131px!important;}
.m-b-132 {margin-bottom: 132px!important;}
.m-b-133 {margin-bottom: 133px!important;}
.m-b-134 {margin-bottom: 134px!important;}
.m-b-135 {margin-bottom: 135px!important;}
.m-b-136 {margin-bottom: 136px!important;}
.m-b-137 {margin-bottom: 137px!important;}
.m-b-138 {margin-bottom: 138px!important;}
.m-b-139 {margin-bottom: 139px!important;}
.m-b-140 {margin-bottom: 140px!important;}
.m-b-141 {margin-bottom: 141px!important;}
.m-b-142 {margin-bottom: 142px!important;}
.m-b-143 {margin-bottom: 143px!important;}
.m-b-144 {margin-bottom: 144px!important;}
.m-b-145 {margin-bottom: 145px!important;}
.m-b-146 {margin-bottom: 146px!important;}
.m-b-147 {margin-bottom: 147px!important;}
.m-b-148 {margin-bottom: 148px!important;}
.m-b-149 {margin-bottom: 149px!important;}
.m-b-150 {margin-bottom: 150px!important;}


/*   margin left  */

.m-l-0 {margin-left: 0px!important;}
.m-l-1 {margin-left: 1px!important;}
.m-l-2 {margin-left: 2px!important;}
.m-l-3 {margin-left: 3px!important;}
.m-l-4 {margin-left: 4px!important;}
.m-l-5 {margin-left: 5px!important;}
.m-l-6 {margin-left: 6px!important;}
.m-l-7 {margin-left: 7px!important;}
.m-l-8 {margin-left: 8px!important;}
.m-l-9 {margin-left: 9px!important;}
.m-l-10 {margin-left: 10px!important;}
.m-l-11 {margin-left: 11px!important;}
.m-l-12 {margin-left: 12px!important;}
.m-l-13 {margin-left: 13px!important;}
.m-l-14 {margin-left: 14px!important;}
.m-l-15 {margin-left: 15px!important;}
.m-l-16 {margin-left: 16px!important;}
.m-l-17 {margin-left: 17px!important;}
.m-l-18 {margin-left: 18px!important;}
.m-l-19 {margin-left: 19px!important;}
.m-l-20 {margin-left: 20px!important;}
.m-l-21 {margin-left: 21px!important;}
.m-l-22 {margin-left: 22px!important;}
.m-l-23 {margin-left: 23px!important;}
.m-l-24 {margin-left: 24px!important;}
.m-l-25 {margin-left: 25px!important;}
.m-l-26 {margin-left: 26px!important;}
.m-l-27 {margin-left: 27px!important;}
.m-l-28 {margin-left: 28px!important;}
.m-l-29 {margin-left: 29px!important;}
.m-l-30 {margin-left: 30px!important;}
.m-l-31 {margin-left: 31px!important;}
.m-l-32 {margin-left: 32px!important;}
.m-l-33 {margin-left: 33px!important;}
.m-l-34 {margin-left: 34px!important;}
.m-l-35 {margin-left: 35px!important;}
.m-l-36 {margin-left: 36px!important;}
.m-l-37 {margin-left: 37px!important;}
.m-l-38 {margin-left: 38px!important;}
.m-l-39 {margin-left: 39px!important;}
.m-l-40 {margin-left: 40px!important;}
.m-l-41 {margin-left: 4px!important;}
.m-l-42 {margin-left: 42px!important;}
.m-l-43 {margin-left: 43px!important;}
.m-l-44 {margin-left: 44px!important;}
.m-l-45 {margin-left: 45px!important;}
.m-l-46 {margin-left: 46px!important;}
.m-l-47 {margin-left: 47px!important;}
.m-l-48 {margin-left: 48px!important;}
.m-l-49 {margin-left: 49px!important;}
.m-l-50 {margin-left: 50px!important;}
.m-l-51 {margin-left: 51px!important;}
.m-l-52 {margin-left: 52px!important;}
.m-l-53 {margin-left: 53px!important;}
.m-l-54 {margin-left: 54px!important;}
.m-l-55 {margin-left: 55px!important;}
.m-l-56 {margin-left: 56px!important;}
.m-l-57 {margin-left: 57px!important;}
.m-l-58 {margin-left: 58px!important;}
.m-l-59 {margin-left: 59px!important;}
.m-l-60 {margin-left: 60px!important;}
.m-l-61 {margin-left: 61px!important;}
.m-l-62 {margin-left: 62px!important;}
.m-l-63 {margin-left: 63px!important;}
.m-l-64 {margin-left: 64px!important;}
.m-l-65 {margin-left: 65px!important;}
.m-l-66 {margin-left: 66px!important;}
.m-l-67 {margin-left: 67px!important;}
.m-l-68 {margin-left: 68px!important;}
.m-l-69 {margin-left: 69px!important;}
.m-l-70 {margin-left: 70px!important;}
.m-l-71 {margin-left: 71px!important;}
.m-l-72 {margin-left: 72px!important;}
.m-l-73 {margin-left: 73px!important;}
.m-l-74 {margin-left: 74px!important;}
.m-l-75 {margin-left: 75px!important;}
.m-l-76 {margin-left: 76px!important;}
.m-l-77 {margin-left: 77px!important;}
.m-l-78 {margin-left: 78px!important;}
.m-l-79 {margin-left: 79px!important;}
.m-l-80 {margin-left: 80px!important;}
.m-l-81 {margin-left: 81px!important;}
.m-l-82 {margin-left: 82px!important;}
.m-l-83 {margin-left: 83px!important;}
.m-l-84 {margin-left: 84px!important;}
.m-l-85 {margin-left: 85px!important;}
.m-l-86 {margin-left: 86px!important;}
.m-l-87 {margin-left: 87px!important;}
.m-l-88 {margin-left: 88px!important;}
.m-l-89 {margin-left: 89px!important;}
.m-l-90 {margin-left: 90px!important;}
.m-l-91 {margin-left: 91px!important;}
.m-l-92 {margin-left: 92px!important;}
.m-l-93 {margin-left: 93px!important;}
.m-l-94 {margin-left: 94px!important;}
.m-l-95 {margin-left: 95px!important;}
.m-l-96 {margin-left: 96px!important;}
.m-l-97 {margin-left: 97px!important;}
.m-l-98 {margin-left: 98px!important;}
.m-l-99 {margin-left: 99px!important;}
.m-l-100 {margin-left: 100px!important;}
.m-l-101 {margin-left: 101px!important;}
.m-l-102 {margin-left: 102px!important;}
.m-l-103 {margin-left: 103px!important;}
.m-l-104 {margin-left: 104px!important;}
.m-l-105 {margin-left: 105px!important;}
.m-l-106 {margin-left: 106px!important;}
.m-l-107 {margin-left: 107px!important;}
.m-l-108 {margin-left: 108px!important;}
.m-l-109 {margin-left: 109px!important;}
.m-l-110 {margin-left: 110px!important;}
.m-l-111 {margin-left: 111px!important;}
.m-l-112 {margin-left: 112px!important;}
.m-l-113 {margin-left: 113px!important;}
.m-l-114 {margin-left: 114px!important;}
.m-l-115 {margin-left: 115px!important;}
.m-l-116 {margin-left: 116px!important;}
.m-l-117 {margin-left: 117px!important;}
.m-l-118 {margin-left: 118px!important;}
.m-l-119 {margin-left: 119px!important;}
.m-l-120 {margin-left: 120px!important;}
.m-l-121 {margin-left: 121px!important;}
.m-l-122 {margin-left: 122px!important;}
.m-l-123 {margin-left: 123px!important;}
.m-l-124 {margin-left: 124px!important;}
.m-l-125 {margin-left: 125px!important;}
.m-l-126 {margin-left: 126px!important;}
.m-l-127 {margin-left: 127px!important;}
.m-l-128 {margin-left: 128px!important;}
.m-l-129 {margin-left: 129px!important;}
.m-l-130 {margin-left: 130px!important;}
.m-l-131 {margin-left: 131px!important;}
.m-l-132 {margin-left: 132px!important;}
.m-l-133 {margin-left: 133px!important;}
.m-l-134 {margin-left: 134px!important;}
.m-l-135 {margin-left: 135px!important;}
.m-l-136 {margin-left: 136px!important;}
.m-l-137 {margin-left: 137px!important;}
.m-l-138 {margin-left: 138px!important;}
.m-l-139 {margin-left: 139px!important;}
.m-l-140 {margin-left: 140px!important;}
.m-l-141 {margin-left: 141px!important;}
.m-l-142 {margin-left: 142px!important;}
.m-l-143 {margin-left: 143px!important;}
.m-l-144 {margin-left: 144px!important;}
.m-l-145 {margin-left: 145px!important;}
.m-l-146 {margin-left: 146px!important;}
.m-l-147 {margin-left: 147px!important;}
.m-l-148 {margin-left: 148px!important;}
.m-l-149 {margin-left: 149px!important;}
.m-l-150 {margin-left: 150px!important;}



/*   padding  */

.p-0 {padding: 0px!important; }
.p-5 {padding: 5px!important; }
.p-15 {padding: 15px!important; }
.p-20{padding: 20px!important; }
.p-22{padding: 22px!important; }
.p-17 {padding: 17px!important; }
.p-18 {padding: 18px!important; }
.p-30 {padding: 30px!important; }
.p-48 {padding: 48px!important; }


/*   padding top */

.p-t-0 {padding-top: 0px!important;}
.p-t-1 {padding-top: 1px!important;}
.p-t-2 {padding-top: 2px!important;}
.p-t-3 {padding-top: 3px!important;}
.p-t-4 {padding-top: 4px!important;}
.p-t-5 {padding-top: 5px!important;}
.p-t-6 {padding-top: 6px!important;}
.p-t-7 {padding-top: 7px!important;}
.p-t-8 {padding-top: 8px!important;}
.p-t-9 {padding-top: 9px!important;}
.p-t-10 {padding-top: 10px!important;}
.p-t-11 {padding-top: 11px!important;}
.p-t-12 {padding-top: 12px!important;}
.p-t-13 {padding-top: 13px!important;}
.p-t-14 {padding-top: 14px!important;}
.p-t-15 {padding-top: 15px!important;}
.p-t-16 {padding-top: 16px!important;}
.p-t-17 {padding-top: 17px!important;}
.p-t-18 {padding-top: 18px!important;}
.p-t-19 {padding-top: 19px!important;}
.p-t-20 {padding-top: 20px!important;}
.p-t-21 {padding-top: 21px!important;}
.p-t-22 {padding-top: 22px!important;}
.p-t-23 {padding-top: 23px!important;}
.p-t-24 {padding-top: 24px!important;}
.p-t-25 {padding-top: 25px!important;}
.p-t-26 {padding-top: 26px!important;}
.p-t-27 {padding-top: 27px!important;}
.p-t-28 {padding-top: 28px!important;}
.p-t-29 {padding-top: 29px!important;}
.p-t-30 {padding-top: 30px!important;}
.p-t-31 {padding-top: 31px!important;}
.p-t-32 {padding-top: 32px!important;}
.p-t-33 {padding-top: 33px!important;}
.p-t-34 {padding-top: 34px!important;}
.p-t-35 {padding-top: 35px!important;}
.p-t-36 {padding-top: 36px!important;}
.p-t-37 {padding-top: 37px!important;}
.p-t-38 {padding-top: 38px!important;}
.p-t-39 {padding-top: 39px!important;}
.p-t-40 {padding-top: 40px!important;}
.p-t-41 {padding-top: 4px!important;}
.p-t-42 {padding-top: 42px!important;}
.p-t-43 {padding-top: 43px!important;}
.p-t-44 {padding-top: 44px!important;}
.p-t-45 {padding-top: 45px!important;}
.p-t-46 {padding-top: 46px!important;}
.p-t-47 {padding-top: 47px!important;}
.p-t-48 {padding-top: 48px!important;}
.p-t-49 {padding-top: 49px!important;}
.p-t-50 {padding-top: 50px!important;}
.p-t-51 {padding-top: 51px!important;}
.p-t-52 {padding-top: 52px!important;}
.p-t-53 {padding-top: 53px!important;}
.p-t-54 {padding-top: 54px!important;}
.p-t-55 {padding-top: 55px!important;}
.p-t-56 {padding-top: 56px!important;}
.p-t-57 {padding-top: 57px!important;}
.p-t-58 {padding-top: 58px!important;}
.p-t-59 {padding-top: 59px!important;}
.p-t-60 {padding-top: 60px!important;}
.p-t-61 {padding-top: 61px!important;}
.p-t-62 {padding-top: 62px!important;}
.p-t-63 {padding-top: 63px!important;}
.p-t-64 {padding-top: 64px!important;}
.p-t-65 {padding-top: 65px!important;}
.p-t-66 {padding-top: 66px!important;}
.p-t-67 {padding-top: 67px!important;}
.p-t-68 {padding-top: 68px!important;}
.p-t-69 {padding-top: 69px!important;}
.p-t-70 {padding-top: 70px!important;}
.p-t-71 {padding-top: 71px!important;}
.p-t-72 {padding-top: 72px!important;}
.p-t-73 {padding-top: 73px!important;}
.p-t-74 {padding-top: 74px!important;}
.p-t-75 {padding-top: 75px!important;}
.p-t-76 {padding-top: 76px!important;}
.p-t-77 {padding-top: 77px!important;}
.p-t-78 {padding-top: 78px!important;}
.p-t-79 {padding-top: 79px!important;}
.p-t-80 {padding-top: 80px!important;}
.p-t-81 {padding-top: 81px!important;}
.p-t-82 {padding-top: 82px!important;}
.p-t-83 {padding-top: 83px!important;}
.p-t-84 {padding-top: 84px!important;}
.p-t-85 {padding-top: 85px!important;}
.p-t-86 {padding-top: 86px!important;}
.p-t-87 {padding-top: 87px!important;}
.p-t-88 {padding-top: 88px!important;}
.p-t-89 {padding-top: 89px!important;}
.p-t-90 {padding-top: 90px!important;}
.p-t-91 {padding-top: 91px!important;}
.p-t-92 {padding-top: 92px!important;}
.p-t-93 {padding-top: 93px!important;}
.p-t-94 {padding-top: 94px!important;}
.p-t-95 {padding-top: 95px!important;}
.p-t-96 {padding-top: 96px!important;}
.p-t-97 {padding-top: 97px!important;}
.p-t-98 {padding-top: 98px!important;}
.p-t-99 {padding-top: 99px!important;}
.p-t-100 {padding-top: 100px!important;}
.p-t-101 {padding-top: 101px!important;}
.p-t-102 {padding-top: 102px!important;}
.p-t-103 {padding-top: 103px!important;}
.p-t-104 {padding-top: 104px!important;}
.p-t-105 {padding-top: 105px!important;}
.p-t-106 {padding-top: 106px!important;}
.p-t-107 {padding-top: 107px!important;}
.p-t-108 {padding-top: 108px!important;}
.p-t-109 {padding-top: 109px!important;}
.p-t-110 {padding-top: 110px!important;}
.p-t-111 {padding-top: 111px!important;}
.p-t-112 {padding-top: 112px!important;}
.p-t-113 {padding-top: 113px!important;}
.p-t-114 {padding-top: 114px!important;}
.p-t-115 {padding-top: 115px!important;}
.p-t-116 {padding-top: 116px!important;}
.p-t-117 {padding-top: 117px!important;}
.p-t-118 {padding-top: 118px!important;}
.p-t-119 {padding-top: 119px!important;}
.p-t-120 {padding-top: 120px!important;}
.p-t-121 {padding-top: 121px!important;}
.p-t-122 {padding-top: 122px!important;}
.p-t-123 {padding-top: 123px!important;}
.p-t-124 {padding-top: 124px!important;}
.p-t-125 {padding-top: 125px!important;}
.p-t-126 {padding-top: 126px!important;}
.p-t-127 {padding-top: 127px!important;}
.p-t-128 {padding-top: 128px!important;}
.p-t-129 {padding-top: 129px!important;}
.p-t-130 {padding-top: 130px!important;}
.p-t-131 {padding-top: 131px!important;}
.p-t-132 {padding-top: 132px!important;}
.p-t-133 {padding-top: 133px!important;}
.p-t-134 {padding-top: 134px!important;}
.p-t-135 {padding-top: 135px!important;}
.p-t-136 {padding-top: 136px!important;}
.p-t-137 {padding-top: 137px!important;}
.p-t-138 {padding-top: 138px!important;}
.p-t-139 {padding-top: 139px!important;}
.p-t-140 {padding-top: 140px!important;}
.p-t-141 {padding-top: 141px!important;}
.p-t-142 {padding-top: 142px!important;}
.p-t-143 {padding-top: 143px!important;}
.p-t-144 {padding-top: 144px!important;}
.p-t-145 {padding-top: 145px!important;}
.p-t-146 {padding-top: 146px!important;}
.p-t-147 {padding-top: 147px!important;}
.p-t-148 {padding-top: 148px!important;}
.p-t-149 {padding-top: 149px!important;}
.p-t-150 {padding-top: 150px!important;}


/*   padding right */

.p-r-0 {padding-right: 0px!important;}
.p-r-1 {padding-right: 1px!important;}
.p-r-2 {padding-right: 2px!important;}
.p-r-3 {padding-right: 3px!important;}
.p-r-4 {padding-right: 4px!important;}
.p-r-5 {padding-right: 5px!important;}
.p-r-6 {padding-right: 6px!important;}
.p-r-7 {padding-right: 7px!important;}
.p-r-8 {padding-right: 8px!important;}
.p-r-9 {padding-right: 9px!important;}
.p-r-10 {padding-right: 10px!important;}
.p-r-11 {padding-right: 11px!important;}
.p-r-12 {padding-right: 12px!important;}
.p-r-13 {padding-right: 13px!important;}
.p-r-14 {padding-right: 14px!important;}
.p-r-15 {padding-right: 15px!important;}
.p-r-16 {padding-right: 16px!important;}
.p-r-17 {padding-right: 17px!important;}
.p-r-18 {padding-right: 18px!important;}
.p-r-19 {padding-right: 19px!important;}
.p-r-20 {padding-right: 20px!important;}
.p-r-21 {padding-right: 21px!important;}
.p-r-22 {padding-right: 22px!important;}
.p-r-23 {padding-right: 23px!important;}
.p-r-24 {padding-right: 24px!important;}
.p-r-25 {padding-right: 25px!important;}
.p-r-26 {padding-right: 26px!important;}
.p-r-27 {padding-right: 27px!important;}
.p-r-28 {padding-right: 28px!important;}
.p-r-29 {padding-right: 29px!important;}
.p-r-30 {padding-right: 30px!important;}
.p-r-31 {padding-right: 31px!important;}
.p-r-32 {padding-right: 32px!important;}
.p-r-33 {padding-right: 33px!important;}
.p-r-34 {padding-right: 34px!important;}
.p-r-35 {padding-right: 35px!important;}
.p-r-36 {padding-right: 36px!important;}
.p-r-37 {padding-right: 37px!important;}
.p-r-38 {padding-right: 38px!important;}
.p-r-39 {padding-right: 39px!important;}
.p-r-40 {padding-right: 40px!important;}
.p-r-41 {padding-right: 4px!important;}
.p-r-42 {padding-right: 42px!important;}
.p-r-43 {padding-right: 43px!important;}
.p-r-44 {padding-right: 44px!important;}
.p-r-45 {padding-right: 45px!important;}
.p-r-46 {padding-right: 46px!important;}
.p-r-47 {padding-right: 47px!important;}
.p-r-48 {padding-right: 48px!important;}
.p-r-49 {padding-right: 49px!important;}
.p-r-50 {padding-right: 50px!important;}
.p-r-51 {padding-right: 51px!important;}
.p-r-52 {padding-right: 52px!important;}
.p-r-53 {padding-right: 53px!important;}
.p-r-54 {padding-right: 54px!important;}
.p-r-55 {padding-right: 55px!important;}
.p-r-56 {padding-right: 56px!important;}
.p-r-57 {padding-right: 57px!important;}
.p-r-58 {padding-right: 58px!important;}
.p-r-59 {padding-right: 59px!important;}
.p-r-60 {padding-right: 60px!important;}
.p-r-61 {padding-right: 61px!important;}
.p-r-62 {padding-right: 62px!important;}
.p-r-63 {padding-right: 63px!important;}
.p-r-64 {padding-right: 64px!important;}
.p-r-65 {padding-right: 65px!important;}
.p-r-66 {padding-right: 66px!important;}
.p-r-67 {padding-right: 67px!important;}
.p-r-68 {padding-right: 68px!important;}
.p-r-69 {padding-right: 69px!important;}
.p-r-70 {padding-right: 70px!important;}
.p-r-71 {padding-right: 71px!important;}
.p-r-72 {padding-right: 72px!important;}
.p-r-73 {padding-right: 73px!important;}
.p-r-74 {padding-right: 74px!important;}
.p-r-75 {padding-right: 75px!important;}
.p-r-76 {padding-right: 76px!important;}
.p-r-77 {padding-right: 77px!important;}
.p-r-78 {padding-right: 78px!important;}
.p-r-79 {padding-right: 79px!important;}
.p-r-80 {padding-right: 80px!important;}
.p-r-81 {padding-right: 81px!important;}
.p-r-82 {padding-right: 82px!important;}
.p-r-83 {padding-right: 83px!important;}
.p-r-84 {padding-right: 84px!important;}
.p-r-85 {padding-right: 85px!important;}
.p-r-86 {padding-right: 86px!important;}
.p-r-87 {padding-right: 87px!important;}
.p-r-88 {padding-right: 88px!important;}
.p-r-89 {padding-right: 89px!important;}
.p-r-90 {padding-right: 90px!important;}
.p-r-91 {padding-right: 91px!important;}
.p-r-92 {padding-right: 92px!important;}
.p-r-93 {padding-right: 93px!important;}
.p-r-94 {padding-right: 94px!important;}
.p-r-95 {padding-right: 95px!important;}
.p-r-96 {padding-right: 96px!important;}
.p-r-97 {padding-right: 97px!important;}
.p-r-98 {padding-right: 98px!important;}
.p-r-99 {padding-right: 99px!important;}
.p-r-100 {padding-right: 100px!important;}
.p-r-101 {padding-right: 101px!important;}
.p-r-102 {padding-right: 102px!important;}
.p-r-103 {padding-right: 103px!important;}
.p-r-104 {padding-right: 104px!important;}
.p-r-105 {padding-right: 105px!important;}
.p-r-106 {padding-right: 106px!important;}
.p-r-107 {padding-right: 107px!important;}
.p-r-108 {padding-right: 108px!important;}
.p-r-109 {padding-right: 109px!important;}
.p-r-110 {padding-right: 110px!important;}
.p-r-111 {padding-right: 111px!important;}
.p-r-112 {padding-right: 112px!important;}
.p-r-113 {padding-right: 113px!important;}
.p-r-114 {padding-right: 114px!important;}
.p-r-115 {padding-right: 115px!important;}
.p-r-116 {padding-right: 116px!important;}
.p-r-117 {padding-right: 117px!important;}
.p-r-118 {padding-right: 118px!important;}
.p-r-119 {padding-right: 119px!important;}
.p-r-120 {padding-right: 120px!important;}
.p-r-121 {padding-right: 121px!important;}
.p-r-122 {padding-right: 122px!important;}
.p-r-123 {padding-right: 123px!important;}
.p-r-124 {padding-right: 124px!important;}
.p-r-125 {padding-right: 125px!important;}
.p-r-126 {padding-right: 126px!important;}
.p-r-127 {padding-right: 127px!important;}
.p-r-128 {padding-right: 128px!important;}
.p-r-129 {padding-right: 129px!important;}
.p-r-130 {padding-right: 130px!important;}
.p-r-131 {padding-right: 131px!important;}
.p-r-132 {padding-right: 132px!important;}
.p-r-133 {padding-right: 133px!important;}
.p-r-134 {padding-right: 134px!important;}
.p-r-135 {padding-right: 135px!important;}
.p-r-136 {padding-right: 136px!important;}
.p-r-137 {padding-right: 137px!important;}
.p-r-138 {padding-right: 138px!important;}
.p-r-139 {padding-right: 139px!important;}
.p-r-140 {padding-right: 140px!important;}
.p-r-141 {padding-right: 141px!important;}
.p-r-142 {padding-right: 142px!important;}
.p-r-143 {padding-right: 143px!important;}
.p-r-144 {padding-right: 144px!important;}
.p-r-145 {padding-right: 145px!important;}
.p-r-146 {padding-right: 146px!important;}
.p-r-147 {padding-right: 147px!important;}
.p-r-148 {padding-right: 148px!important;}
.p-r-149 {padding-right: 149px!important;}
.p-r-150 {padding-right: 150px!important;}


/*   padding bottom */

.p-b-0 {padding-bottom: 0px!important;}
.p-b-1 {padding-bottom: 1px!important;}
.p-b-2 {padding-bottom: 2px!important;}
.p-b-3 {padding-bottom: 3px!important;}
.p-b-4 {padding-bottom: 4px!important;}
.p-b-5 {padding-bottom: 5px!important;}
.p-b-6 {padding-bottom: 6px!important;}
.p-b-7 {padding-bottom: 7px!important;}
.p-b-8 {padding-bottom: 8px!important;}
.p-b-9 {padding-bottom: 9px!important;}
.p-b-10 {padding-bottom: 10px!important;}
.p-b-11 {padding-bottom: 11px!important;}
.p-b-12 {padding-bottom: 12px!important;}
.p-b-13 {padding-bottom: 13px!important;}
.p-b-14 {padding-bottom: 14px!important;}
.p-b-15 {padding-bottom: 15px!important;}
.p-b-16 {padding-bottom: 16px!important;}
.p-b-17 {padding-bottom: 17px!important;}
.p-b-18 {padding-bottom: 18px!important;}
.p-b-19 {padding-bottom: 19px!important;}
.p-b-20 {padding-bottom: 20px!important;}
.p-b-21 {padding-bottom: 21px!important;}
.p-b-22 {padding-bottom: 22px!important;}
.p-b-23 {padding-bottom: 23px!important;}
.p-b-24 {padding-bottom: 24px!important;}
.p-b-25 {padding-bottom: 25px!important;}
.p-b-26 {padding-bottom: 26px!important;}
.p-b-27 {padding-bottom: 27px!important;}
.p-b-28 {padding-bottom: 28px!important;}
.p-b-29 {padding-bottom: 29px!important;}
.p-b-30 {padding-bottom: 30px!important;}
.p-b-31 {padding-bottom: 31px!important;}
.p-b-32 {padding-bottom: 32px!important;}
.p-b-33 {padding-bottom: 33px!important;}
.p-b-34 {padding-bottom: 34px!important;}
.p-b-35 {padding-bottom: 35px!important;}
.p-b-36 {padding-bottom: 36px!important;}
.p-b-37 {padding-bottom: 37px!important;}
.p-b-38 {padding-bottom: 38px!important;}
.p-b-39 {padding-bottom: 39px!important;}
.p-b-40 {padding-bottom: 40px!important;}
.p-b-41 {padding-bottom: 4px!important;}
.p-b-42 {padding-bottom: 42px!important;}
.p-b-43 {padding-bottom: 43px!important;}
.p-b-44 {padding-bottom: 44px!important;}
.p-b-45 {padding-bottom: 45px!important;}
.p-b-46 {padding-bottom: 46px!important;}
.p-b-47 {padding-bottom: 47px!important;}
.p-b-48 {padding-bottom: 48px!important;}
.p-b-49 {padding-bottom: 49px!important;}
.p-b-50 {padding-bottom: 50px!important;}
.p-b-51 {padding-bottom: 51px!important;}
.p-b-52 {padding-bottom: 52px!important;}
.p-b-53 {padding-bottom: 53px!important;}
.p-b-54 {padding-bottom: 54px!important;}
.p-b-55 {padding-bottom: 55px!important;}
.p-b-56 {padding-bottom: 56px!important;}
.p-b-57 {padding-bottom: 57px!important;}
.p-b-58 {padding-bottom: 58px!important;}
.p-b-59 {padding-bottom: 59px!important;}
.p-b-60 {padding-bottom: 60px!important;}
.p-b-61 {padding-bottom: 61px!important;}
.p-b-62 {padding-bottom: 62px!important;}
.p-b-63 {padding-bottom: 63px!important;}
.p-b-64 {padding-bottom: 64px!important;}
.p-b-65 {padding-bottom: 65px!important;}
.p-b-66 {padding-bottom: 66px!important;}
.p-b-67 {padding-bottom: 67px!important;}
.p-b-68 {padding-bottom: 68px!important;}
.p-b-69 {padding-bottom: 69px!important;}
.p-b-70 {padding-bottom: 70px!important;}
.p-b-71 {padding-bottom: 71px!important;}
.p-b-72 {padding-bottom: 72px!important;}
.p-b-73 {padding-bottom: 73px!important;}
.p-b-74 {padding-bottom: 74px!important;}
.p-b-75 {padding-bottom: 75px!important;}
.p-b-76 {padding-bottom: 76px!important;}
.p-b-77 {padding-bottom: 77px!important;}
.p-b-78 {padding-bottom: 78px!important;}
.p-b-79 {padding-bottom: 79px!important;}
.p-b-80 {padding-bottom: 80px!important;}
.p-b-81 {padding-bottom: 81px!important;}
.p-b-82 {padding-bottom: 82px!important;}
.p-b-83 {padding-bottom: 83px!important;}
.p-b-84 {padding-bottom: 84px!important;}
.p-b-85 {padding-bottom: 85px!important;}
.p-b-86 {padding-bottom: 86px!important;}
.p-b-87 {padding-bottom: 87px!important;}
.p-b-88 {padding-bottom: 88px!important;}
.p-b-89 {padding-bottom: 89px!important;}
.p-b-90 {padding-bottom: 90px!important;}
.p-b-91 {padding-bottom: 91px!important;}
.p-b-92 {padding-bottom: 92px!important;}
.p-b-93 {padding-bottom: 93px!important;}
.p-b-94 {padding-bottom: 94px!important;}
.p-b-95 {padding-bottom: 95px!important;}
.p-b-96 {padding-bottom: 96px!important;}
.p-b-97 {padding-bottom: 97px!important;}
.p-b-98 {padding-bottom: 98px!important;}
.p-b-99 {padding-bottom: 99px!important;}
.p-b-100 {padding-bottom: 100px!important;}
.p-b-101 {padding-bottom: 101px!important;}
.p-b-102 {padding-bottom: 102px!important;}
.p-b-103 {padding-bottom: 103px!important;}
.p-b-104 {padding-bottom: 104px!important;}
.p-b-105 {padding-bottom: 105px!important;}
.p-b-106 {padding-bottom: 106px!important;}
.p-b-107 {padding-bottom: 107px!important;}
.p-b-108 {padding-bottom: 108px!important;}
.p-b-109 {padding-bottom: 109px!important;}
.p-b-110 {padding-bottom: 110px!important;}
.p-b-111 {padding-bottom: 111px!important;}
.p-b-112 {padding-bottom: 112px!important;}
.p-b-113 {padding-bottom: 113px!important;}
.p-b-114 {padding-bottom: 114px!important;}
.p-b-115 {padding-bottom: 115px!important;}
.p-b-116 {padding-bottom: 116px!important;}
.p-b-117 {padding-bottom: 117px!important;}
.p-b-118 {padding-bottom: 118px!important;}
.p-b-119 {padding-bottom: 119px!important;}
.p-b-120 {padding-bottom: 120px!important;}
.p-b-121 {padding-bottom: 121px!important;}
.p-b-122 {padding-bottom: 122px!important;}
.p-b-123 {padding-bottom: 123px!important;}
.p-b-124 {padding-bottom: 124px!important;}
.p-b-125 {padding-bottom: 125px!important;}
.p-b-126 {padding-bottom: 126px!important;}
.p-b-127 {padding-bottom: 127px!important;}
.p-b-128 {padding-bottom: 128px!important;}
.p-b-129 {padding-bottom: 129px!important;}
.p-b-130 {padding-bottom: 130px!important;}
.p-b-131 {padding-bottom: 131px!important;}
.p-b-132 {padding-bottom: 132px!important;}
.p-b-133 {padding-bottom: 133px!important;}
.p-b-134 {padding-bottom: 134px!important;}
.p-b-135 {padding-bottom: 135px!important;}
.p-b-136 {padding-bottom: 136px!important;}
.p-b-137 {padding-bottom: 137px!important;}
.p-b-138 {padding-bottom: 138px!important;}
.p-b-139 {padding-bottom: 139px!important;}
.p-b-140 {padding-bottom: 140px!important;}
.p-b-141 {padding-bottom: 141px!important;}
.p-b-142 {padding-bottom: 142px!important;}
.p-b-143 {padding-bottom: 143px!important;}
.p-b-144 {padding-bottom: 144px!important;}
.p-b-145 {padding-bottom: 145px!important;}
.p-b-146 {padding-bottom: 146px!important;}
.p-b-147 {padding-bottom: 147px!important;}
.p-b-148 {padding-bottom: 148px!important;}
.p-b-149 {padding-bottom: 149px!important;}
.p-b-150 {padding-bottom: 150px!important;}



/*   padding left */

.p-l-0 {padding-left: 0px!important;}
.p-l-1 {padding-left: 1px!important;}
.p-l-2 {padding-left: 2px!important;}
.p-l-3 {padding-left: 3px!important;}
.p-l-4 {padding-left: 4px!important;}
.p-l-5 {padding-left: 5px!important;}
.p-l-6 {padding-left: 6px!important;}
.p-l-7 {padding-left: 7px!important;}
.p-l-8 {padding-left: 8px!important;}
.p-l-9 {padding-left: 9px!important;}
.p-l-10 {padding-left: 10px!important;}
.p-l-11 {padding-left: 11px!important;}
.p-l-12 {padding-left: 12px!important;}
.p-l-13 {padding-left: 13px!important;}
.p-l-14 {padding-left: 14px!important;}
.p-l-15 {padding-left: 15px!important;}
.p-l-16 {padding-left: 16px!important;}
.p-l-17 {padding-left: 17px!important;}
.p-l-18 {padding-left: 18px!important;}
.p-l-19 {padding-left: 19px!important;}
.p-l-20 {padding-left: 20px!important;}
.p-l-21 {padding-left: 21px!important;}
.p-l-22 {padding-left: 22px!important;}
.p-l-23 {padding-left: 23px!important;}
.p-l-24 {padding-left: 24px!important;}
.p-l-25 {padding-left: 25px!important;}
.p-l-26 {padding-left: 26px!important;}
.p-l-27 {padding-left: 27px!important;}
.p-l-28 {padding-left: 28px!important;}
.p-l-29 {padding-left: 29px!important;}
.p-l-30 {padding-left: 30px!important;}
.p-l-31 {padding-left: 31px!important;}
.p-l-32 {padding-left: 32px!important;}
.p-l-33 {padding-left: 33px!important;}
.p-l-34 {padding-left: 34px!important;}
.p-l-35 {padding-left: 35px!important;}
.p-l-36 {padding-left: 36px!important;}
.p-l-37 {padding-left: 37px!important;}
.p-l-38 {padding-left: 38px!important;}
.p-l-39 {padding-left: 39px!important;}
.p-l-40 {padding-left: 40px!important;}
.p-l-41 {padding-left: 4px!important;}
.p-l-42 {padding-left: 42px!important;}
.p-l-43 {padding-left: 43px!important;}
.p-l-44 {padding-left: 44px!important;}
.p-l-45 {padding-left: 45px!important;}
.p-l-46 {padding-left: 46px!important;}
.p-l-47 {padding-left: 47px!important;}
.p-l-48 {padding-left: 48px!important;}
.p-l-49 {padding-left: 49px!important;}
.p-l-50 {padding-left: 50px!important;}
.p-l-51 {padding-left: 51px!important;}
.p-l-52 {padding-left: 52px!important;}
.p-l-53 {padding-left: 53px!important;}
.p-l-54 {padding-left: 54px!important;}
.p-l-55 {padding-left: 55px!important;}
.p-l-56 {padding-left: 56px!important;}
.p-l-57 {padding-left: 57px!important;}
.p-l-58 {padding-left: 58px!important;}
.p-l-59 {padding-left: 59px!important;}
.p-l-60 {padding-left: 60px!important;}
.p-l-61 {padding-left: 61px!important;}
.p-l-62 {padding-left: 62px!important;}
.p-l-63 {padding-left: 63px!important;}
.p-l-64 {padding-left: 64px!important;}
.p-l-65 {padding-left: 65px!important;}
.p-l-66 {padding-left: 66px!important;}
.p-l-67 {padding-left: 67px!important;}
.p-l-68 {padding-left: 68px!important;}
.p-l-69 {padding-left: 69px!important;}
.p-l-70 {padding-left: 70px!important;}
.p-l-71 {padding-left: 71px!important;}
.p-l-72 {padding-left: 72px!important;}
.p-l-73 {padding-left: 73px!important;}
.p-l-74 {padding-left: 74px!important;}
.p-l-75 {padding-left: 75px!important;}
.p-l-76 {padding-left: 76px!important;}
.p-l-77 {padding-left: 77px!important;}
.p-l-78 {padding-left: 78px!important;}
.p-l-79 {padding-left: 79px!important;}
.p-l-80 {padding-left: 80px!important;}
.p-l-81 {padding-left: 81px!important;}
.p-l-82 {padding-left: 82px!important;}
.p-l-83 {padding-left: 83px!important;}
.p-l-84 {padding-left: 84px!important;}
.p-l-85 {padding-left: 85px!important;}
.p-l-86 {padding-left: 86px!important;}
.p-l-87 {padding-left: 87px!important;}
.p-l-88 {padding-left: 88px!important;}
.p-l-89 {padding-left: 89px!important;}
.p-l-90 {padding-left: 90px!important;}
.p-l-91 {padding-left: 91px!important;}
.p-l-92 {padding-left: 92px!important;}
.p-l-93 {padding-left: 93px!important;}
.p-l-94 {padding-left: 94px!important;}
.p-l-95 {padding-left: 95px!important;}
.p-l-96 {padding-left: 96px!important;}
.p-l-97 {padding-left: 97px!important;}
.p-l-98 {padding-left: 98px!important;}
.p-l-99 {padding-left: 99px!important;}
.p-l-100 {padding-left: 100px!important;}
.p-l-101 {padding-left: 101px!important;}
.p-l-102 {padding-left: 102px!important;}
.p-l-103 {padding-left: 103px!important;}
.p-l-104 {padding-left: 104px!important;}
.p-l-105 {padding-left: 105px!important;}
.p-l-106 {padding-left: 106px!important;}
.p-l-107 {padding-left: 107px!important;}
.p-l-108 {padding-left: 108px!important;}
.p-l-109 {padding-left: 109px!important;}
.p-l-110 {padding-left: 110px!important;}
.p-l-111 {padding-left: 111px!important;}
.p-l-112 {padding-left: 112px!important;}
.p-l-113 {padding-left: 113px!important;}
.p-l-114 {padding-left: 114px!important;}
.p-l-115 {padding-left: 115px!important;}
.p-l-116 {padding-left: 116px!important;}
.p-l-117 {padding-left: 117px!important;}
.p-l-118 {padding-left: 118px!important;}
.p-l-119 {padding-left: 119px!important;}
.p-l-120 {padding-left: 120px!important;}
.p-l-121 {padding-left: 121px!important;}
.p-l-122 {padding-left: 122px!important;}
.p-l-123 {padding-left: 123px!important;}
.p-l-124 {padding-left: 124px!important;}
.p-l-125 {padding-left: 125px!important;}
.p-l-126 {padding-left: 126px!important;}
.p-l-127 {padding-left: 127px!important;}
.p-l-128 {padding-left: 128px!important;}
.p-l-129 {padding-left: 129px!important;}
.p-l-130 {padding-left: 130px!important;}
.p-l-131 {padding-left: 131px!important;}
.p-l-132 {padding-left: 132px!important;}
.p-l-133 {padding-left: 133px!important;}
.p-l-134 {padding-left: 134px!important;}
.p-l-135 {padding-left: 135px!important;}
.p-l-136 {padding-left: 136px!important;}
.p-l-137 {padding-left: 137px!important;}
.p-l-138 {padding-left: 138px!important;}
.p-l-139 {padding-left: 139px!important;}
.p-l-140 {padding-left: 140px!important;}
.p-l-141 {padding-left: 141px!important;}
.p-l-142 {padding-left: 142px!important;}
.p-l-143 {padding-left: 143px!important;}
.p-l-144 {padding-left: 144px!important;}
.p-l-145 {padding-left: 145px!important;}
.p-l-146 {padding-left: 146px!important;}
.p-l-147 {padding-left: 147px!important;}
.p-l-148 {padding-left: 148px!important;}
.p-l-149 {padding-left: 149px!important;}
.p-l-150 {padding-left: 150px!important;}


/* Width percentage*/




.w-5{
    width:5%!important;
}
.w-10{
    width:10%!important;
}
.w-15{
    width:15%!important;
}
.w-20{
    width:20%!important;
}
.w-25{
    width:25%!important;
}
.w-30{
    width:30%!important;
}
.w-35{
    width:35%!important;
}
.w-40{
    width:40%!important;
}
.w-45{
    width:45%!important;
}
.w-50{
    width:50%!important;
}
.w-55{
    width:55%!important;
}
.w-60{
    width:60%!important;
}
.w-65{
    width:65%!important;
}
.w-70{
    width:70%!important;
}
.w-75{
    width:75%!important;
}
.w-80{
    width:80%!important;
}
.w-85{
    width:85%!important;
}
.w-90{
    width:90%!important;
}
.w-95{
    width:95%!important;
}
.w-100{
    width:100%!important;
}

















































