body {
  font-family: "Raleway", sans-serif;
  background-color: #f4f7f9;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e3e8eb inset !important;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  margin-top: 20px;
  font-size: 13px;
  word-break: break-all;
  color: #000 !important;
}
.form-group label a {
  color: #000;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  background-color: #e3e8eb;
  border: 0.2rem solid #e3e8eb;
  border-radius: 0.4rem;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  padding-left: 0;
  position: relative;
  transition: border-color 0.3s;
  min-width: 35px;
  margin-right: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 12px;
  width: 11px;
  height: 20px;
  border: solid #0d0d0d;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 3px;
  transition: 0.3s;
}
